import { ModelOptions } from '../../Sorting';

import { CollectionAssociation } from '@/api/services/bitAi/v2-new/sorting';
import EyeIcon from '@/assets/icons/EyeIcon';
import FluentOpenIcon from '@/assets/icons/FluentOpenIcon';
import PencilEditIcon from '@/assets/icons/PencilEditIcon';
import { BBBPrimarySwitch, BBBTooltip } from '@/components/ui';
import { HeadersTypes } from '@/components/ui/BBBTableV2/BBBTableV2.type';
import { Link } from '@/components/ui/Link';
import useUpdateOos from '@/hooks/bitAi/sorting/useUpdateOos';
import useUpdateSortingModel from '@/hooks/bitAi/sorting/useUpdateSortingModel';
import useGetDomainStore from '@/hooks/bitAi/useGetDomainStore';
import useGetStoreName from '@/hooks/bitAi/useGetStoreName';
import useWithCRMPricing from '@/hooks/pricing/useWithCRMPricing';
import { formatDate2 } from '@/utils/common/date';
import { getRawShopifyNodeId } from '@/utils/common/url';

export default function useColumn(): HeadersTypes<CollectionAssociation> {
  const storeName = useGetStoreName();

  const domainStore = useGetDomainStore();
  const { mutate: updateOOS } = useUpdateOos();
  const { mutate: updateSortingModel } = useUpdateSortingModel();

  const { isReachingTreshold } = useWithCRMPricing(
    'BITAI',
    'managed_collections'
  );

  return [
    {
      accesor: 'title',
      renderHeader: () => 'Title',
      isSortable: true,
      sortOrder: 'asc',
      columnWidth: 200,
      render: (row, value) =>
        row.sorting?.model?.type === 'CUSTOM' ? (
          <Link
            to={{
              pathname: `/bitai/sorting/${encodeURIComponent(row.id)}`,
            }}
            className="text-primary-main group-hover:underline"
          >
            {value}
          </Link>
        ) : (
          <p className="text-primary-main">{value}</p>
        ),
    },
    {
      accesor: 'sorting.model',
      renderHeader: () => 'Model',
      columnWidth: 220,
      render: (row) => (
        <ModelOptions
          value={
            row.sorting?.model
              ? //@ts-ignore
                { ...row.sorting.model, parentValue: row.sorting.model.type }
              : null
          }
          onValueChange={(value) =>
            updateSortingModel({
              data: {
                collectionId: row.id,
                modelId: value?.id || null,
              },
            })
          }
          disabled={!row.sorting?.model && isReachingTreshold}
        />
      ),
    },
    {
      accesor: 'collectionProducts',
      renderHeader: () => 'Products',
      columnWidth: 100,
      render: (row) => (
        <p className="text-primary-main">{row.collectionProducts.length}</p>
      ),
    },
    {
      accesor: 'sorting.oos',
      renderHeader: () => <p className="break-keep">OOS</p>,
      isHasTooltip: 'Out-Of-Stock',
      columnWidth: 90,
      render: (row) => (
        <div
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <BBBPrimarySwitch
            checked={row.sorting?.oos}
            onChange={(checked) =>
              updateOOS({
                data: {
                  collectionId: row.id,
                  oos: !!checked,
                },
              })
            }
            className="justify-start"
          />
        </div>
      ),
    },
    {
      accesor: 'updatedAt',
      renderHeader: () => 'Last updated',
      columnWidth: 120,
      isSortable: true,
      render: (row, value) => (
        <p className="text-primary-main">{formatDate2(value)}</p>
      ),
    },
    {
      renderHeader: () => 'Action',
      render: (row) => (
        <div className="flex items-center justify-center gap-2">
          {row.sorting?.model && (
            <WrapperAction content="Manage">
              <Link
                to={{
                  pathname: `/bitai/sorting/${encodeURIComponent(row.id)}`,
                  state: {
                    data: row.id,
                  },
                }}
              >
                <PencilEditIcon />
              </Link>
            </WrapperAction>
          )}
          <WrapperAction content="Preview">
            <a
              href={`https://${domainStore}/collections/${row.handle}`}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              <EyeIcon />
            </a>
          </WrapperAction>
          <WrapperAction
            content={`https://admin.shopify.com/store/${storeName}/collections/${getRawShopifyNodeId(
              row.id
            )}`}
          >
            <a
              href={`https://admin.shopify.com/store/${storeName}/collections/${getRawShopifyNodeId(
                row.id
              )}`}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              <FluentOpenIcon />
            </a>
          </WrapperAction>
        </div>
      ),
      align: 'center',
      isAdditionalColumn: true,
    },
  ];
}

const WrapperAction = ({
  content,
  children,
}: {
  content: string;
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <BBBTooltip show content={content}>
      {children}
    </BBBTooltip>
  );
};
