/* eslint-disable @typescript-eslint/ban-ts-comment */
import dayjs from 'dayjs';
import linkifyHtml from 'linkify-html';

import { mapSourceToRoutes } from '@/constants/whatsApp';
import { ChannelSource } from '@/constants/whatsApp';
import { CampaignTemplate } from '@/types/bitCRM/template';
import { FileType } from '@/types/utils/file';
import { Nullable } from '@/types/utils/nullable';
import { WACloudTemplate } from '@/types/whatsApp/settings';
import { MentionMessage } from '@/types/whatsApp/v3';
import { formatDate2 } from '@/utils/common/date';
import { fileNameFromUrl, getUrlExtension } from '@/utils/common/file';

function is_aplhanumeric(c: string) {
  // @ts-ignore
  const x = c.charCodeAt();
  return (x >= 65 && x <= 90) || (x >= 97 && x <= 122) || (x >= 48 && x <= 57)
    ? true
    : false;
}

function whatsappStyles(
  format: string,
  wildcard: string,
  opTag: string,
  clTag: string
) {
  const indices = [];
  for (let i = 0; i < format.length; i++) {
    if (format[i] === wildcard) {
      if (indices.length % 2)
        format[i - 1] == ' '
          ? null
          : typeof format[i + 1] == 'undefined'
          ? indices.push(i)
          : is_aplhanumeric(format[i + 1])
          ? null
          : indices.push(i);
      else
        typeof format[i + 1] == 'undefined'
          ? null
          : format[i + 1] == ' '
          ? null
          : typeof format[i - 1] == 'undefined'
          ? indices.push(i)
          : is_aplhanumeric(format[i - 1])
          ? null
          : indices.push(i);
    } else {
      // @ts-ignore
      format[i].charCodeAt() == 10 && indices.length % 2 ? indices.pop() : null;
    }
  }
  indices.length % 2 ? indices.pop() : null;
  let e = 0;
  indices.forEach(function (v, i) {
    const t = i % 2 ? clTag : opTag;
    v += e;
    format = format.substr(0, v) + t + format.substr(v + 1);
    e += t.length - 1;
  });
  return format;
}

export const formatMention = (
  textP: string,
  mentionedContacts: MentionMessage[]
) => {
  const mappedContacts = Object.fromEntries(
    (mentionedContacts || [])?.map((contact) => [
      contact.clientName,
      {
        clientNumber: contact.clientNumber,
        source: contact.sources,
      },
    ])
  );

  let i = 0;

  const text = textP.split('');
  const finalWords = [];

  while (i < text.length) {
    if (text[i] === '@') {
      let j = i + 1;
      let counter = 1;
      let currWords = text[i];
      let type = 'markup';

      while (j < text.length) {
        currWords += text[j];

        j += 1;
        counter += 1;

        if (text[j] === '@') {
          type = 'markup';
          break;
        } else if (mappedContacts[currWords.slice(1)]) {
          type = 'mention';
          break;
        }
      }

      finalWords.push({
        type,
        data:
          type === 'mention'
            ? JSON.stringify({
                label: currWords,
                number: mappedContacts[currWords.slice(1)].clientNumber,
                source: mappedContacts[currWords.slice(1)].source,
              })
            : currWords,
      });

      i += counter;
    } else {
      let currWords = text[i];

      let j = i;
      let counter = 1;

      while (j < text.length - 1) {
        if (text[j + 1] === '@') {
          break;
        }

        currWords += text[j + 1];

        j += 1;
        counter += 1;
      }

      finalWords.push({ type: 'markup', data: currWords });
      i += counter;
    }
  }

  return mergeConsecutiveMarkup(finalWords);
};

function mergeConsecutiveMarkup(
  input: {
    type: string;
    data: string;
  }[]
) {
  const mergedOutput = [];

  let currentMarkup: typeof input[number] | null = null;
  for (const obj of input) {
    if (obj.type === 'markup') {
      if (currentMarkup) {
        currentMarkup.data += '' + obj.data;
      } else {
        currentMarkup = { ...obj };
      }
    } else {
      if (currentMarkup) {
        mergedOutput.push(currentMarkup);
        currentMarkup = null;
      }
      mergedOutput.push(obj);
    }
  }

  if (currentMarkup) {
    mergedOutput.push(currentMarkup);
  }

  return mergedOutput;
}

export const toWhatsAppFormat = (
  text?: string,
  emphasizedLink = true,
  parameters?: string[]
) => {
  if (!text) return '';

  if (typeof parameters !== 'undefined') {
    const paramsText = text.match(/{{[0-9]}}/g);

    if (paramsText) {
      paramsText.forEach((param, index) => {
        // make it bold
        text = text?.replace(
          param,
          parameters[index] ? `<b>${parameters[index]}</b>` : ''
        );
      });
    }
  }

  let finalText = whatsappStyles(text, '_', '<i>', '</i>');
  finalText = whatsappStyles(finalText, '*', '<b>', '</b>');
  finalText = whatsappStyles(finalText, '~', '<s>', '</s>');
  finalText = whatsappStyles(finalText, '`', '<code>', '</code>');

  finalText = finalText.replace(/\r\n|\r|\n/g, '<br>');

  finalText = replaceLinksWithAnchorTags(finalText, emphasizedLink);

  return finalText;
};

function replaceLinksWithAnchorTags(
  htmlString: string,
  emphasizedLink: boolean
): string {
  return linkifyHtml(htmlString, {
    target: '_blank',
    className: emphasizedLink ? 'link' : '',
  });
}

/**
 * Used for time formatting in chat related
 */
export const formatWhatsAppTime = (
  time: Parameters<typeof formatDate2>[0] | undefined
) => (time ? formatDate2(time, undefined, 'chat') : undefined);

/**
 * Used for time formatting in messages related
 */
export const formatWhatsAppTime2 = (
  time: Parameters<typeof formatDate2>[0] | undefined
) => (time ? formatDate2(time, '12-hour', 'chat') : undefined);

export const formatWhatsappTextFormatToHTML = (text: string) =>
  text
    .replace(/\r\n|\r|\n/g, '<br />')
    .replaceAll(/\*([^*]*)\*/g, '<strong>$1</strong>')
    .replaceAll(/_(.*?)_/g, '<i>$1</i>');

export function convertRemoteUrlToFileType(url: string): FileType;
export function convertRemoteUrlToFileType(url?: string | null): null;
export function convertRemoteUrlToFileType(
  url?: string | null
): Nullable<FileType> {
  return !url
    ? null
    : {
        remoteUrl: url,
        fileData: {
          type: getUrlExtension(url),
          name: fileNameFromUrl(url),
        },
      };
}

export const isTemplateNoAction = (row: CampaignTemplate) =>
  (row.type === 'CAMPAIGN_WHATSAPP_CLOUD_API' ||
    row.type === 'AUTOMATION_WHATSAPP_CLOUD_API') &&
  row.status === 'SUBMITTED';

export const getTemplateUrl = (_type: CampaignTemplate['type'], id: string) => {
  const separatorIdx = _type.indexOf('_');
  const type = _type.substring(0, separatorIdx);

  return {
    pathname: `/misc/templates/${id}`,
    search: `section=${type.toLowerCase()}`,
  };
};
