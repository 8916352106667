import { useEffect, useState } from 'react';
import { twMerge as cx } from 'tailwind-merge';
import Wrapper from '../components/Wrapper';
import UpsellCard from '../UpsellCard';
import SocialLoginButtons from './components/SocialLoginButtons';

import { RedirectionList } from '@/api/services/bitLogin/appearance';
import { BBBAlert, BBBCard, BBBSelect } from '@/components/ui';
import useRedirectionPage from '@/hooks/bitLogin/useRedirectionPage';
import useSettings from '@/hooks/bitLogin/useSettings';
import useSocialAppearance from '@/hooks/bitLogin/useSocialAppearance';
import useUpsertSocialAppearance from '@/hooks/bitLogin/useUpsertSocialAppearance';

function SocialLogin() {
  const { status: statusEnabled, data } = useSettings();

  const isDisabled = statusEnabled !== 'success' || !data?.verified;

  return (
    <Wrapper
      hasHeading
      pageTitle="Social Login"
      pageDescription="Add social login option to your site and reach wider users"
      module="social-login"
    >
      <div className={cx(isDisabled && 'opacity-50 pointer-events-none')}>
        <BBBAlert
          message={
            <>
              Shopify has recently turned on reCAPTCHA by default, which can
              interfere with the functionality of bitLogin. To ensure that
              bitLogin works properly, disable your reCAPTCHA options.{' '}
              <a
                href="https://www.bitbybit.studio/knowledge-base/how-to-turn-off-recaptcha-on-shopify"
                target="_blank"
                className="font-semibold underline"
                rel="noreferrer"
              >
                Click here to see how
              </a>
            </>
          }
          type="secondary"
          className="leading-6 my-5"
          iconSize={30}
        />
        <_SocialLogin />
      </div>
    </Wrapper>
  );
}

function _SocialLogin() {
  const [redirectionData, setRedirectionData] = useState<RedirectionList>();

  const { data: redirectionList } = useRedirectionPage();
  const { data: socialAppearance } = useSocialAppearance();

  const { mutate: upsertRedirectionPage } = useUpsertSocialAppearance();

  useEffect(() => {
    const data =
      (socialAppearance?.redirectLink.split('/').length ?? 0) > 2
        ? redirectionList
            ?.find((opt) => opt.title === 'Collections')
            ?.data?.find((opt) => opt.link === socialAppearance?.redirectLink)
        : redirectionList?.find(
            (opt) => opt.link === socialAppearance?.redirectLink
          );

    setRedirectionData(
      data
        ? 'name' in data
          ? {
              title: 'Collections',
              link: data.link,
            }
          : data
        : undefined
    );
  }, [redirectionList, socialAppearance?.redirectLink]);

  return (
    <>
      <UpsellCard />
      <BBBCard title="Enable login button" className="mb-cardBottom">
        <SocialLoginButtons type="web" withSwitchLabel />
      </BBBCard>
      <BBBCard
        title="Redirection page"
        desc="After your customer logged in, they will redirect to this page"
        className="mb-cardBottom"
      >
        <BBBSelect
          value={redirectionData}
          onValueChange={(opt) => {
            if (opt?.title === 'Collections') {
              setRedirectionData(opt);
            } else {
              upsertRedirectionPage({
                redirectLink: opt!.link,
              });
            }
          }}
          optionLabel="title"
          optionValue="link"
          options={redirectionList}
          placeholder="Choose redirection"
        />
        {redirectionData?.title === 'Collections' && (
          <BBBSelect
            placeholder="Choose collections"
            containerClassName="mt-5"
            value={redirectionList
              ?.find((opt) => opt.title === 'Collections')
              ?.data?.find(
                (opt) => opt.link === socialAppearance?.redirectLink
              )}
            onValueChange={(opt) =>
              upsertRedirectionPage({
                redirectLink: opt!.link,
              })
            }
            optionLabel="name"
            optionValue="link"
            options={
              redirectionList?.find((opt) => opt.title === 'Collections')?.data
            }
          />
        )}
      </BBBCard>
    </>
  );
}

export default SocialLogin;
