import { useEffect, useMemo, useState } from 'react';
import { Info as InfoIcon, Share2 } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import { shallowEqual } from 'react-redux';
import { motion } from 'framer-motion';
import { startCase } from 'lodash-es';
import { twMerge as cx } from 'tailwind-merge';
import HandleTicket from '../../HandleTicket';

import CopyIcon from '@/assets/icons/CopyIcon';
import LinkOutIcon from '@/assets/icons/LinkOutIcon';
import SearchIcon from '@/assets/icons/SearchIcon';
import SentimentNegativeIcon from '@/assets/icons/SentimentNegativeIcon';
import SentimentNeutralIcon from '@/assets/icons/SentimentNeutralIcon';
import SentimentPositiveIcon from '@/assets/icons/SentimentPositiveIcon';
import InfoList from '@/components/InfoList/InfoList';
import {
  BBBButton,
  BBBCard,
  BBBCheckbox,
  BBBDebounceInput,
  BBBSelect,
  BBBTooltip,
  SingleOnValueChangeParam,
} from '@/components/ui';
import BBBBadge from '@/components/ui/BBBBadge/BBBBadge';
import { BBBTextAreaInput } from '@/components/ui/BBBTextInput';
import { Link } from '@/components/ui/Link';
import {
  aiOptions,
  EVERYONE_AGENT_ID,
  generalAiAgentOption,
  staticAgents,
} from '@/constants/bitChat/agent';
import { dateOptions } from '@/constants/bitChat/ticket';
import colors from '@/constants/common/colors';
import useCompanyUsers from '@/hooks/auth/useCompanyUsers';
import useIsModuleRestricted from '@/hooks/auth/useIsModuleRestricted';
import useUserCompanyDetail from '@/hooks/auth/useUserCompanyDetail';
import useSettings from '@/hooks/bitChat/settings/useSettings';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { useAppSelector } from '@/hooks/rtk/store';
import { selectActiveChatTruthy, useUpdateChat } from '@/hooks/whatsApp/chat';
import useIsGroupChat from '@/hooks/whatsApp/useIsGroupChat';
import useTicketByChat from '@/hooks/whatsApp/useTicketByChat';
import useTicketHistory from '@/hooks/whatsApp/useTicketHistory';
import useUpdateTicket from '@/hooks/whatsApp/useUpdateTicket';
import AgentOptions, {
  AgentOption,
} from '@/pages/BitChat/components/AgentOptions';
import AITagging from '@/pages/BitChat/Ticket/TicketModal/AITagging';
import TicketModal from '@/pages/BitChat/Ticket/TicketModal/TicketModal';
import TicketTags from '@/pages/BitChat/Ticket/TicketTags';
import {
  ChatTicketHumanAgent,
  ChatTicketStaticAgent,
} from '@/types/whatsApp/chat';
import { Ticket } from '@/types/whatsApp/ticket';
import { formatUserDisplayName } from '@/utils/auth';
import { formatDate2 } from '@/utils/common/date';
import { dateFilter } from '@/utils/ticket';

const TICKET_TABS = [
  {
    key: 'active',
    label: 'Active ticket',
  },
  {
    key: 'history',
    label: 'Ticket history',
  },
] as const;

const defaultFilter = [
  {
    label: 'All',
    value: 'all',
    parentValue: 'ticket-tag',
  },
  {
    label: 'All',
    value: 'all',
    parentValue: 'agent',
  },
];

type Filter = {
  label: string | JSX.Element | React.ReactNode;
  value: string | number;
  parentValue?: string | undefined;
  isNestedDropdown?: boolean | undefined;
  hasMore?: boolean | undefined;
  fetchNext?: (() => void) | undefined;
};

export default function TicketDetail() {
  return <_TicketDetailOnboarded />;
}

function _TicketDetailOnboarded() {
  const isGroup = useIsGroupChat();
  const [activeTab, setActiveTab] = useState('active');
  const [search, setSearch] = useState('');
  const [date, setDate] = useState<typeof dateOptions[number] | undefined>();
  const [filter, setFilter] = useState<Filter[]>(defaultFilter);

  const ticketDateFilter = useMemo(
    () => (date?.value ? dateFilter(date.value) : undefined),
    [date?.value]
  );

  const tagFilters = filter.filter((opt) => opt.parentValue === 'ticket-tag');
  const agentFilters = filter.filter((opt) => opt.parentValue === 'agent');

  const ticketHistoryQuery = useTicketHistory(
    {
      search,
      tags: tagFilters.some((opt) => opt.value === 'all')
        ? undefined
        : tagFilters.map((opt) => opt.value).join(','),
      agents: agentFilters.some((opt) => opt.value === 'all')
        ? undefined
        : agentFilters.map((opt) => opt.value).join(','),
      ...(ticketDateFilter && {
        startDate: ticketDateFilter.startDate,
        endDate: ticketDateFilter.endDate,
      }),
    },
    activeTab === 'history'
  );

  return (
    <>
      <Header
        activeTab={activeTab}
        onTabChange={(tab) => setActiveTab(tab)}
        search={search}
        onSearchChange={(value) => setSearch(value)}
        isGroup={isGroup}
        totalTicket={ticketHistoryQuery.data?.pageable.totalElements}
        date={date}
        setDate={setDate}
        filter={filter}
        onFilterChange={setFilter}
      />

      {isGroup ? (
        <Info
          message={
            <p className="text-center">
              Tickets data are not <br />
              available for this channel
            </p>
          }
        />
      ) : activeTab === 'active' ? (
        <TicketActive />
      ) : (
        <TicketHistory
          tickets={ticketHistoryQuery.data?.data}
          loadingTicket={ticketHistoryQuery.isLoading}
          errorTicket={ticketHistoryQuery.isError}
        />
      )}
    </>
  );
}

function TicketHistory({
  tickets,
  loadingTicket,
  errorTicket,
}: {
  tickets: Ticket[] | undefined;
  loadingTicket: boolean;
  errorTicket: boolean;
}) {
  if (loadingTicket) {
    return (
      <>
        {Array.from({ length: 6 }).map((_, index) => (
          <BBBCard key={index}>
            <div className="flex items-center gap-3 mb-3">
              <Skeleton width={72} />
              <Skeleton width="100%" containerClassName="grow" />
            </div>
          </BBBCard>
        ))}
      </>
    );
  }

  if (errorTicket) return null;

  if (!tickets?.length) {
    return <Info message="No tickets available" />;
  }

  return (
    <>
      {tickets?.map((ticket) => (
        <_TicketDetail
          key={ticket.id}
          id={ticket.id}
          sentiment={ticket.sentiment}
          agents={ticket.agents}
          createdAt={ticket.createdAt}
          aiTags={ticket.aiTags}
          notes={ticket.notes}
          isTicketActive={false}
          loadingSaveNotes={false}
          status={ticket.status}
          tags={ticket.tags}
          sources={ticket.sources}
          // @ts-ignore
          userCompanyData={ticket.agents?.length ? ticket.agents[0] : undefined}
        />
      ))}
    </>
  );
}

function TicketActive() {
  const confirm = useConfirmationModal();

  const {
    data,
    isInitialLoading: loadingTicket,
    error: errorTicket,
  } = useTicketByChat();

  const agent = data?.agents?.length ? data.agents[0] : undefined;

  const agentId = agent?.userId;

  const { data: agentData } = useUserCompanyDetail(
    agentId && !staticAgents.includes(agentId) ? agentId?.toString() : undefined
  );

  const { mutate: updateChat } = useUpdateChat();

  const userCompanyDataFormatted = useMemo(
    () =>
      agentId
        ? !staticAgents.includes(agentId)
          ? agentData
            ? {
                ...agentData,
                formattedName: formatUserDisplayName(agentData?.user),
              }
            : undefined
          : agentId === EVERYONE_AGENT_ID
          ? undefined
          : generalAiAgentOption
        : undefined,
    [agentData, agentId]
  );

  const [notes, setNotes] = useState<string>();

  const { mutate: updateTicket, isLoading: loadingUpdateTicket } =
    useUpdateTicket();

  useEffect(() => {
    setNotes(data?.notes ?? '');
  }, [data?.notes]);

  const { data: isRestricted } = useIsModuleRestricted(
    'BITCHAT_EDIT_TICKET_LIVECHAT'
  );

  if (loadingTicket) {
    return (
      <>
        {Array.from({ length: 6 }).map((_, index) => (
          <div className="flex items-center gap-3 mb-3" key={index}>
            <Skeleton width={72} />
            <Skeleton width="100%" containerClassName="grow" />
          </div>
        ))}
      </>
    );
  }

  if (errorTicket) return null;

  if (!data) {
    return <Info message="No tickets available" />;
  }

  return (
    <_TicketDetail
      id={data.id}
      sentiment={data.sentiment}
      agents={data.agents}
      isRestricted={isRestricted}
      createdAt={data.createdAt}
      // @ts-ignore
      userCompanyData={userCompanyDataFormatted}
      onAgentChange={(opt) =>
        confirm({
          title: `Assign ticket to ${opt!.formattedName}`,
          description: `Are you sure to assign this ticket to ${
            opt!.formattedName
          }? ${opt!.formattedName} will receive notification for this action`,
          submitText: `Assign to ${opt!.formattedName}`,
          onAccept: (hide) => {
            updateChat(
              {
                status: 'transfer',
                assignedUser: {
                  userId: opt!.userId,
                  userAccent: opt!.user.accent,
                  userDisplayName: formatUserDisplayName(opt!.user),
                  userProfilePicture: opt!.user.profile?.profilePicture || null,
                  userEmail: opt!.user.email,
                },
              },
              {
                onSuccess: () => {
                  hide();
                },
              }
            );
          },
        })
      }
      aiTags={data.aiTags}
      notes={notes}
      onNotesChange={(value) => setNotes(value)}
      isTicketActive={true}
      ignoreAutoResolve={data.ignoreAutoResolve}
      onIgnoreAutoResolveChange={(val) =>
        updateTicket({
          ignoreAutoResolve: val,
          source: data.sources,
          ticketId: data.id,
        })
      }
      onSaveNotes={() =>
        updateTicket({
          notes,
          source: data.sources,
          ticketId: data.id,
        })
      }
      loadingSaveNotes={loadingUpdateTicket}
      status={data.status}
    />
  );
}

type _TicketDetailProps = {
  id: Ticket['id'];
  agents: Ticket['agents'];
  sentiment: Ticket['sentiment'];
  isRestricted: boolean | undefined;
  createdAt: Ticket['createdAt'];
  onAgentChange?: (opt: SingleOnValueChangeParam<AgentOption>) => void;
  aiTags: Ticket['aiTags'];
  notes: string | undefined | null;
  sources: Ticket['sources'];
  onNotesChange?: (value: string) => void;
  isTicketActive: boolean;
  ignoreAutoResolve?: boolean | undefined;
  onIgnoreAutoResolveChange?: (value: boolean) => void;
  onSaveNotes?: () => void;
  loadingSaveNotes?: boolean;
  status: Ticket['status'];
  userCompanyData:
    | (AgentOption & {
        agentName: string;
      })
    | undefined;
  tags: Ticket['tags'];
};

function _TicketDetail({
  id,
  sentiment,
  agents,
  isRestricted,
  createdAt,
  onAgentChange,
  aiTags,
  notes,
  onNotesChange,
  isTicketActive,
  ignoreAutoResolve,
  onIgnoreAutoResolveChange,
  loadingSaveNotes,
  status,
  onSaveNotes,
  userCompanyData,
  sources,
  tags,
}: _TicketDetailProps) {
  const [selectedTicket, setSelectedTicket] =
    useState<Pick<Ticket, 'id' | 'sources'>>();

  const _agents = agents?.filter((agent) => agent.userId !== EVERYONE_AGENT_ID);
  const agent = _agents?.length ? agents[0] : undefined;

  return (
    <>
      {selectedTicket && (
        <TicketModal
          ticket={selectedTicket}
          onHide={() => setSelectedTicket(undefined)}
        />
      )}
      <BBBCard
        title={
          isTicketActive ? (
            <>
              Ticket #{id} <Sentiment sentiment={sentiment} /> <ShareTicket />
            </>
          ) : (
            <TitleWithIcon
              show
              className="flex items-center"
              onClick={() => setSelectedTicket({ id, sources })}
              iconChildren={
                <>
                  <Sentiment sentiment={sentiment} /> <ShareTicket />
                </>
              }
            >
              <div className="flex gap-2 items-center">Ticket #{id}</div>
            </TitleWithIcon>
          )
        }
        withCollapse
        collapse={!isTicketActive}
      >
        <div
          className={cx(
            'flex flex-col gap-4',
            isRestricted && 'pointer-events-none'
          )}
        >
          <InfoList label="Started at">
            <div className="text-neutral-60">{formatDate2(createdAt)}</div>
          </InfoList>

          <InfoList label="Assignee">
            {isTicketActive ? (
              <AgentOptions
                containerClassName="grow"
                value={userCompanyData}
                onValueChange={(opt) => onAgentChange?.(opt)}
              />
            ) : (
              <div className="text-neutral-60">
                {userCompanyData?.agentName}
              </div>
            )}
          </InfoList>
          <InfoList label="Category">
            {isTicketActive ? (
              <TicketTags type="chat" />
            ) : tags?.length ? (
              <BBBBadge text={tags?.[0].tagsName} type={tags?.[0].Tags.color} />
            ) : (
              <div className="text-neutral-60">-</div>
            )}
          </InfoList>
          <AITagging tags={aiTags} />
          <BBBTextAreaInput
            label="Ticket notes"
            className="text-neutral-60"
            value={notes || ''}
            rows={4}
            onChange={({ target: { value } }) => onNotesChange?.(value)}
            placeholder="Add notes for this ticket"
          />
          {isTicketActive && (
            <>
              <BBBCheckbox
                className="my-4"
                onValueChange={(val) => onIgnoreAutoResolveChange?.(val)}
                checked={ignoreAutoResolve}
                label={
                  <>
                    <div className="flex items-center gap-1 text-neutral-60">
                      Ignore auto-resolve
                      <BBBTooltip
                        content="This ticket won’t resolved automatically"
                        position="top"
                        show
                      >
                        <InfoIcon size={14} color="#9E9E9E" />
                      </BBBTooltip>
                    </div>
                  </>
                }
              />
              {(notes ?? '') !== notes ? (
                <BBBButton
                  text="Save notes"
                  onClick={onSaveNotes}
                  loadingState={loadingSaveNotes}
                  className={cx('w-full')}
                />
              ) : (
                <HandleTicket
                  className={cx('w-full')}
                  status={status}
                  ticket={
                    agent
                      ? staticAgents.includes(agent.userId)
                        ? ({
                            userId: agent.userId,
                          } as ChatTicketStaticAgent)
                        : ({
                            userId: agent.userId,
                            agentEmail: agent.agentEmail,
                            agentName: agent.agentName,
                            agentAccent: agent.accent,
                            agentProfilePicture: agent.profilePicture,
                          } as ChatTicketHumanAgent)
                      : null
                  }
                />
              )}
            </>
          )}
        </div>
      </BBBCard>
    </>
  );
}

function ShareTicket() {
  const [hovering, setHovering] = useState(false);
  const [shared, setShared] = useState(false);

  const selectedChat = useAppSelector(selectActiveChatTruthy, shallowEqual);
  const activeCompany = useActiveCompany();

  useEffect(() => {
    if (shared) {
      navigator.clipboard.writeText(
        window.location.href +
          `?sources=${selectedChat.sources}&chatId=${selectedChat.clientNumber}&companyId=${activeCompany}`
      );
      setTimeout(() => {
        setShared(false);
      }, 2000);
    }
  }, [activeCompany, selectedChat.clientNumber, selectedChat.sources, shared]);

  return (
    <BBBTooltip
      content={shared ? 'Link copied' : 'Copy link to this ticket'}
      show
      position="bottom"
      offset={{ top: 25, left: -50 }}
      tooltipClassName="w-auto"
    >
      <div
        className="relative w-4 h-4"
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => {
          setHovering(false);
        }}
      >
        <Share2
          size={16}
          className={cx(
            ' absolute inset-0 text-neutral-50 transition-opacity',
            !hovering || shared
              ? 'opacity-100 pointer-events-auto'
              : 'opacity-0 pointer-events-none'
          )}
        />
        <CopyIcon
          color={colors.neutral['50']}
          onClick={() => setShared(true)}
          className={cx(
            'absolute inset-0 transition-opacity',
            !hovering || shared
              ? 'opacity-0 pointer-events-none'
              : 'opacity-100 pointer-events-auto'
          )}
        />
      </div>
    </BBBTooltip>
  );
}

function Sentiment({
  sentiment,
  size,
  className,
  label,
}: {
  sentiment: string | undefined;
  size?: number;
  className?: string;
  label?: string;
}) {
  return (
    <BBBTooltip
      content={`Sentiment: ${startCase(sentiment)}`}
      show
      offset={{ top: 50, left: -40 }}
      tooltipClassName="w-auto"
    >
      <div className={cx('flex gap-2 items-center', className)}>
        {sentiment === 'positive' ? (
          <SentimentPositiveIcon size={size} />
        ) : sentiment === 'neutral' ? (
          <SentimentNeutralIcon size={size} />
        ) : (
          <SentimentNegativeIcon size={size} />
        )}
        {label && <p className="text-primary-main">{label}</p>}
      </div>
    </BBBTooltip>
  );
}

function Info({ message }: { message: string | React.ReactNode }) {
  return (
    <BBBCard>
      <div className="flex flex-col gap-2 py-2.5 items-center justify-center">
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="50" height="50" rx="25" fill="#DDDDDD" />
          <path
            d="M22.0647 16.9168C21.6579 17.3236 21.6798 17.9819 21.9207 18.5056C22.1725 19.0535 22.2504 19.6654 22.144 20.2589C22.0376 20.8525 21.752 21.3992 21.3256 21.8256C20.8992 22.252 20.3525 22.5376 19.7589 22.644C19.1654 22.7504 18.5535 22.6725 18.0056 22.4207C17.4819 22.1808 16.8236 22.1579 16.4168 22.5647L15.8054 23.176C15.6099 23.3717 15.5 23.637 15.5 23.9136C15.5 24.1902 15.6099 24.4555 15.8054 24.6511L26.8489 35.6946C27.0445 35.8901 27.3098 36 27.5864 36C27.863 36 28.1283 35.8901 28.324 35.6946L28.9353 35.0832C29.3421 34.6764 29.3202 34.0181 29.0793 33.4944C28.8275 32.9465 28.7496 32.3346 28.856 31.7411C28.9624 31.1475 29.248 30.6008 29.6744 30.1744C30.1008 29.748 30.6475 29.4624 31.2411 29.356C31.8346 29.2496 32.4465 29.3275 32.9944 29.5793C33.5181 29.8192 34.1764 29.8421 34.5832 29.4353L35.1946 28.824C35.3901 28.6283 35.5 28.363 35.5 28.0864C35.5 27.8098 35.3901 27.5445 35.1946 27.3489L24.1511 16.3054C23.9555 16.1099 23.6902 16 23.4136 16C23.137 16 22.8717 16.1099 22.676 16.3054L22.0647 16.9168Z"
            stroke="#757575"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <p className="text-neutral-50">{message}</p>
      </div>
    </BBBCard>
  );
}

function Header({
  activeTab,
  onTabChange,
  search,
  onSearchChange,
  isGroup,
  totalTicket,
  date,
  setDate,
  filter,
  onFilterChange,
}: {
  activeTab: string;
  onTabChange: (tab: string) => void;
  search: string;
  onSearchChange: (search: string) => void;
  isGroup?: boolean;
  totalTicket: number | undefined;
  date:
    | {
        label: string;
        value: string;
      }
    | undefined;
  setDate: React.Dispatch<
    React.SetStateAction<
      | {
          label: string;
          value: string;
        }
      | undefined
    >
  >;
  filter: Filter[];
  onFilterChange: React.Dispatch<React.SetStateAction<Filter[]>>;
}) {
  const companyUsersQuery = useCompanyUsers({
    limit: 5,
  });

  const { data: userCompanies, hasNextPage, fetchNextPage } = companyUsersQuery;
  const { data: settingsData } = useSettings();

  const userCompaniesData = useMemo(
    () =>
      userCompanies?.pages.flatMap((page) =>
        page.data.map((uC) => ({
          label: formatUserDisplayName(uC.user) ?? `-`,
          value: uC.userId,
          parentValue: 'agent',
        }))
      ),
    [userCompanies]
  );

  const ticketTagsData = useMemo(
    () =>
      settingsData?.ticketTags?.map((tag) => ({
        label: tag.label,
        value: tag.id,
        parentValue: 'ticket-tag',
      })),
    [settingsData]
  );

  const filterOptions = useMemo<Filter[]>(
    () => [
      {
        label: 'Ticket tag',
        value: 'ticket-tag',
      },
      {
        label: 'Agent',
        value: 'agent',
      },
      ...defaultFilter,
      ...(ticketTagsData ?? []),
      ...aiOptions,
      ...(userCompaniesData ?? []),
    ],
    [ticketTagsData, userCompaniesData]
  );

  return (
    <BBBCard
      title={'Tickets'}
      rightTitleButton={
        !isGroup && (
          <Link
            to="/bitchat/ticket"
            className="text-sm text-neutral-50 underline"
          >
            See all tickets
          </Link>
        )
      }
    >
      {!isGroup && (
        <>
          <div className="flex items-center gap-2.5">
            {TICKET_TABS.map((tab) => (
              <div
                key={tab.key}
                className={cx(
                  'px-4 py-2 rounded-lg bg-primary-main text-neutral-10 transition-all cursor-pointer whitespace-nowrap',
                  activeTab === tab.key
                    ? 'bg-primary-main font-bold text-neutral-10'
                    : 'bg-transparent text-neutral-60'
                )}
                onClick={() => onTabChange?.(tab.key)}
              >
                {tab.key === 'history' ? (
                  <span className="flex items-center gap-1">
                    <span>{tab.label}</span>
                    {activeTab === 'history' && !!totalTicket && (
                      <span className="flex-none bg-neutral-10 text-[0.625rem] text-primary-main rounded-full w-6 h-6 flex items-center justify-center">
                        {totalTicket}
                      </span>
                    )}
                  </span>
                ) : (
                  tab.label
                )}
              </div>
            ))}
          </div>
          {activeTab === 'history' && (
            <div className="flex flex-col gap-2 mt-4">
              <BBBDebounceInput
                placeholder="Search tickets"
                isFixed
                value={search}
                onValueChange={(value) => onSearchChange(value)}
                fixedLabel={
                  <div className="flex items-center justify-center pl-4">
                    <SearchIcon />
                  </div>
                }
              />
              {search && (
                <div className="flex items-center gap-2">
                  <BBBSelect
                    placeholder="Filter"
                    options={filterOptions}
                    optionLabel="label"
                    optionValue="value"
                    value={filter}
                    onValueChange={(opt, selected) => {
                      if (selected!.value !== 'all') {
                        onFilterChange(
                          opt!.filter((_opt) =>
                            _opt.parentValue !== selected!.parentValue
                              ? true
                              : _opt.parentValue === selected!.parentValue &&
                                _opt.value !== 'all'
                          )
                        );
                      } else {
                        const currentFilterValue = filter.filter(
                          (f) => f.parentValue === selected!.parentValue
                        );
                        if (currentFilterValue.some((f) => f.value !== 'all')) {
                          onFilterChange(
                            opt!.filter((_opt) =>
                              _opt.parentValue !== selected!.parentValue
                                ? true
                                : _opt.parentValue === selected!.parentValue &&
                                  _opt.value === 'all'
                            )
                          );
                        }
                      }
                    }}
                    containerClassName="w-52"
                    isGrouped
                    optionGroupKey="parentValue"
                    isMulti
                    renderCustomSelectedValues={(values) =>
                      !values?.filter((val) => val.value !== 'all').length ? (
                        <div className="text-gray-400">Filter</div>
                      ) : (
                        <>
                          {values?.filter((val) => val.value !== 'all').length}{' '}
                          Filters
                        </>
                      )
                    }
                    isPaginated
                    hasMore={!!hasNextPage}
                    fetchNext={fetchNextPage}
                    isClearable={
                      filter.length
                        ? !filter.every((opt) => opt.value === 'all')
                        : false
                    }
                    enableToggleOption
                    persistShowOptionsOnClick
                  />
                  <BBBSelect
                    options={dateOptions}
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Today"
                    containerClassName="grow"
                    value={date}
                    onValueChange={(val) => setDate(val!)}
                  />
                </div>
              )}
            </div>
          )}
        </>
      )}
    </BBBCard>
  );
}

function TitleWithIcon({
  show: _show,
  onClick,
  ...props
}: {
  show: boolean;
  children: React.ReactNode;
  iconChildren?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}) {
  const [show, setShow] = useState<boolean | undefined>(false);
  return (
    <div
      className="flex gap-1 items-center w-full"
      onMouseLeave={() => setShow(false)}
    >
      <p className="flex gap-1 items-center" onClick={onClick}>
        <div
          className={cx(
            'hover:underline transition-all cursor-pointer',
            props.className
          )}
          onMouseEnter={() => setShow(_show)}
        >
          <div className="flex items-center gap-2">
            {props.children}
            {show && (
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.3 }}
              >
                <LinkOutIcon size={11} color={colors.neutral['40']} />
              </motion.div>
            )}
            {props.iconChildren}
          </div>
        </div>
      </p>
    </div>
  );
}
