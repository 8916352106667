import { useCallback, useEffect, useMemo, useState } from 'react';
import { Control, FormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { timeFormatOptions, timezoneOptions } from 'constants/settings';
import { CountryOptions } from 'constants/systemLogin/countries';
import industriesOptions from 'constants/systemLogin/industries';
import useUpdateCompany from 'hooks/auth/useUpdateCompany';
import useUpdateUserProfile from 'hooks/auth/useUpdateUserProfile';
import useConfirmationBanner from 'hooks/common/useConfirmationBanner';
import useCustomForm from 'hooks/common/useCustomForm';
import useDefaultCountryCode from 'hooks/common/useDefaultCountryCode';
import { useCompany, useUser } from 'hooks/rtk/selector';
import isEqual from 'lodash-es/isEqual';
import * as yup from 'yup';
import { phoneValidator } from 'utils/common/phone';
import AccountSettings from './components/Account';
import APIKey from './components/AdvanceSettings/APIKey';
import CompanySettings from './components/Company';
import PlanManagement from './components/PlanManagement';
import Timezone from './components/Timezone/Timezone';
import SettingsUserAuthority from './components/UsersAuthority';

import { BBBContainer, BBBSpinner, BBBTelInputValue } from '@/components/ui';
import { BackComponent } from '@/components/ui/BBBContainer/BBBContainer';
import BBBNavigationCard from '@/components/ui/BBBNavigationCard/BBBNavigationCard';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import useResponsive from '@/hooks/common/useResponsive';
import type { Country as CountryType } from '@/types/systemLogin';
import { Nullable } from '@/types/utils/nullable';
import { formatPhonePayload } from '@/utils/common/phone';

export type SettingsFormSchema = {
  profileFirstName: Nullable<string>;
  profileLastName: Nullable<string>;
  profileEmail: Nullable<string>;
  profilePhoneNumber: BBBTelInputValue;
  profileDP: Nullable<string>;
  companyName: Nullable<string>;
  companyCountry: Nullable<{ label: CountryType; value: CountryType }>;
  companyIndustry: Nullable<{ label: string; value: string }>;
  timezone: Nullable<typeof timezoneOptions[number]>;
  timeFormat: Nullable<typeof timeFormatOptions[number]>;
};

const schema = yup.object().shape({
  profileFirstName: yup.string(),
  profileLastName: yup.string(),
  profileEmail: yup.string(),
  profilePhoneNumber: phoneValidator(true),
  profileDP: yup.string().nullable(),
  companyName: yup.string(),
  companyCountry: yup.mixed<{ label: string; value: string }>(),
  companyIndustry: yup.mixed<{ label: string; value: string }>(),
  timezone: yup.mixed<{ label: string; value: string }>(),
  timeFormat: yup.mixed<{ label: string; value: string }>(),
});

const settingsNavigation = [
  { label: 'Account', value: 'account' },
  { label: 'Company', value: 'company' },
  { label: 'User & Authority', value: 'user-authority' },
  { label: 'Time zone', value: 'timezone' },
  { label: 'Advance settings', value: 'advance-settings' },
  { label: 'Plan management', value: 'plan-management' },
];

export const subSettingsNavigation = [
  {
    label: 'AI Token Usage History',
    value: 'ai-token-usage-history',
    parent: 'plan-management',
  },
];

export default function Settings() {
  const isMobile = useResponsive('sm');
  const [loadingTransfer, setLoadingTransfer] = useState(false);
  const userData = useUser();

  const queryParams = useQuerySearchParams();

  const defaultSelected = isMobile ? undefined : 'account';
  const selected = queryParams.get('section') || defaultSelected;
  const sub_section = queryParams.get('sub_section');

  const phoneDefaultValue = useDefaultCountryCode(userData.userPhoneNumber);

  const activeCompanyData = useCompany();

  const { mutate: editProfile, isLoading: isLoadingEditProfile } =
    useUpdateUserProfile();
  const { mutate: editCompany, isLoading: isLoadingEditCompany } =
    useUpdateCompany();

  const dataFromApi = useMemo(
    () => ({
      profileFirstName: userData.profile?.firstName || '',
      profileLastName: userData.profile?.lastName || '',
      profileEmail: userData.email || '',
      profilePhoneNumber: phoneDefaultValue,
      profileDP: userData.profile?.profilePicture,
      companyName: activeCompanyData.name || '',
      companyIndustry:
        industriesOptions.find(
          (opt) => opt.label === activeCompanyData.industry
        ) || null,
      companyCountry:
        CountryOptions.find(
          (opt) => opt.value === (activeCompanyData.country as CountryType)
        ) || null,
      timezone: activeCompanyData.timezoneOffset
        ? timezoneOptions.find(
            (option) => option.offset === activeCompanyData.timezoneOffset
          )
        : null,
      timeFormat: activeCompanyData.timeFormat
        ? timeFormatOptions.find(
            (option) => option.value === activeCompanyData.timeFormat
          )
        : null,
    }),
    [
      activeCompanyData.country,
      activeCompanyData.industry,
      activeCompanyData.name,
      activeCompanyData.timeFormat,
      activeCompanyData.timezoneOffset,
      phoneDefaultValue,
      userData.email,
      userData.profile?.firstName,
      userData.profile?.lastName,
      userData.profile?.profilePicture,
    ]
  );

  const {
    formState: { errors },
    reset,
    control,
    watch,
    handleSubmit,
  } = useCustomForm<SettingsFormSchema>({
    resolver: yupResolver(schema),
    defaultValues: dataFromApi,
  });

  useEffect(() => {
    reset(dataFromApi);
  }, [dataFromApi, reset]);

  const onSubmit = useCallback(
    ({
      profileDP,
      profileEmail,
      profileFirstName,
      profileLastName,
      profilePhoneNumber,
      companyCountry,
      companyIndustry,
      companyName,
      timeFormat,
      timezone,
    }: SettingsFormSchema) => {
      editProfile({
        lastName: profileLastName || '',
        firstName: profileFirstName || '',
        email: profileEmail || '',
        profilePicture: profileDP || null,
        phoneNumber: formatPhonePayload(profilePhoneNumber) || null,
      });
      editCompany({
        country: companyCountry?.value,
        industry: companyIndustry?.value,
        name: companyName || undefined,
        timeFormat: timeFormat?.value,
        timezoneOffset: timezone?.offset,
      });
    },
    [editCompany, editProfile]
  );

  const { toggle } = useConfirmationBanner();

  useEffect(() => {
    const isFormEqual = isEqual(watch(), dataFromApi);
    toggle('settings-diff', {
      text:
        isLoadingEditCompany || isLoadingEditProfile ? (
          <BBBSpinner color="white" />
        ) : (
          'Unsaved changes'
        ),
      variant: 'actionable',
      show: !isFormEqual,
      isCancelable: true,
      onAccept: () => handleSubmit(onSubmit)(),
      onCancel: () => reset(dataFromApi),
    });
  }, [
    handleSubmit,
    isLoadingEditCompany,
    isLoadingEditProfile,
    watch(),
    dataFromApi,
    onSubmit,
    reset,
    toggle,
  ]);

  const selectedTitle = settingsNavigation.find(
    (setting) => setting.value === selected
  )?.label;

  if (loadingTransfer) {
    return (
      <div className="h-full flex items-center flex-col justify-center">
        <div>
          <BBBSpinner />
        </div>
        <div className="mt-4">Transferring super admin role..</div>
      </div>
    );
  }

  const label = sub_section
    ? subSettingsNavigation.find((nav) => nav.value === sub_section)!.label
    : selected === 'account'
    ? 'Account settings'
    : selected === 'company'
    ? 'Company settings'
    : selectedTitle;

  return isMobile ? (
    <SettingsMobile
      selected={selected}
      subSection={sub_section || ''}
      control={control}
      errors={errors}
      label={label}
      onTransfer={(status) => setLoadingTransfer(status === 'loading')}
    />
  ) : (
    <SettingsDefault
      selected={selected}
      subSection={sub_section || ''}
      control={control}
      errors={errors}
      label={label}
      onTransfer={(status) => setLoadingTransfer(status === 'loading')}
    />
  );
}

type SettingsDefaultProps = {
  selected: string | undefined;
  subSection: string;
  control: Control<SettingsFormSchema>;
  errors: FormState<SettingsFormSchema>['errors'];
  label: string | undefined;
  onTransfer: (status: 'loading' | 'success') => void;
};

function SettingsMobile({
  selected,
  control,
  errors,
  onTransfer,
}: SettingsDefaultProps) {
  return selected === 'account' ? (
    <BBBContainer
      hasHeading
      hasBack
      pageTitle="Account Settings"
      backUrl="/settings"
    >
      <AccountSettings control={control} errors={errors} />
    </BBBContainer>
  ) : selected === 'company' ? (
    <BBBContainer
      hasHeading
      hasBack
      pageTitle="Company Settings"
      backUrl="/settings"
    >
      <CompanySettings
        control={control}
        onTransfer={(status) => onTransfer(status)}
      />
    </BBBContainer>
  ) : selected === 'user-authority' ? (
    <BBBContainer
      hasHeading
      hasBack
      pageTitle="User & Authority Settings"
      backUrl="/settings"
    >
      <SettingsUserAuthority />
    </BBBContainer>
  ) : selected === 'timezone' ? (
    <BBBContainer
      hasHeading
      hasBack
      pageTitle="Timezone Settings"
      backUrl="/settings"
    >
      <Timezone control={control} />
    </BBBContainer>
  ) : selected === 'advance-settings' ? (
    <BBBContainer
      hasHeading
      hasBack
      pageTitle="Advance Settings"
      backUrl="/settings"
    >
      <APIKey />
    </BBBContainer>
  ) : selected === 'plan-management' ? (
    <BBBContainer
      hasHeading
      hasBack
      pageTitle="Plan Management Settings"
      backUrl="/settings"
    >
      <PlanManagement />
    </BBBContainer>
  ) : (
    <BBBContainer hasHeading pageTitle="Settings">
      <BBBNavigationCard
        selected={selected}
        options={settingsNavigation}
        link={(val) => `/settings?section=${val}`}
      />
    </BBBContainer>
  );
}

function SettingsDefault({
  selected,
  subSection,
  control,
  errors,
  label,
  onTransfer,
}: SettingsDefaultProps) {
  return (
    <BBBContainer className="max-w-none lg:max-w-none">
      <div className="flex gap-6 items-start">
        <BBBNavigationCard
          selected={selected}
          options={settingsNavigation}
          title="Settings"
          link={(val) => `/settings?section=${val}`}
        />
        <div className="grow">
          {subSection && (
            <BackComponent
              url={`/settings?section=${
                subSettingsNavigation.find((nav) => nav.value === subSection)!
                  .parent
              }`}
              className="mb-5"
            />
          )}
          <div className="mt-2 mb-5 text-2xl font-medium">{label}</div>
          {selected === 'account' && (
            <AccountSettings control={control} errors={errors} />
          )}
          {selected === 'company' && (
            <CompanySettings
              control={control}
              onTransfer={(status) => onTransfer(status)}
            />
          )}
          {selected === 'user-authority' && <SettingsUserAuthority />}
          {selected === 'timezone' && <Timezone control={control} />}
          {selected === 'advance-settings' && <APIKey />}
          {selected === 'plan-management' && <PlanManagement />}
        </div>
      </div>
    </BBBContainer>
  );
}
