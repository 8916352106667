import { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { LogOut, Settings } from 'react-feather';
import { AnimatePresence, motion } from 'framer-motion';
import useHistory from 'hooks/common/useHistory';
import useOutsideAlerterv2 from 'hooks/common/useOutsideAlerterv2';
import { useUser } from 'hooks/rtk/selector';
import { useAppDispatch, useAppSelector } from 'hooks/rtk/store';
import { logout } from 'stores/auth';
import { twMerge as cx } from 'tailwind-merge';
import CompanyList from './CompanyList';

import UserProfilePicture from '@/components/Auth/UserProfilePicture';
import { BBBModal, BBBTooltip } from '@/components/ui';
import Link from '@/components/ui/Link/Link';
import { setExpandedSubNav } from '@/stores/common';
import { formatUserDisplayName } from '@/utils/auth';

type Props = {
  setActiveSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function UserZone({ setActiveSidebar }: Props) {
  const data = useUser();

  const [activeProfileDropdown, setActiveProfileDropdown] = useState(false);
  const [showChangeCompany, setShowChangeCompany] = useState(false);

  const profileDropDownRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const companyName = useAppSelector((s) => s.auth.company!.name);

  useOutsideAlerterv2(
    profileDropDownRef,
    () => {
      setActiveProfileDropdown(false);
    },
    true
  );

  const sidebarRootRef = document.getElementById('sidebar-root');

  return (
    <>
      {sidebarRootRef &&
        createPortal(
          <AnimatePresence>
            {activeProfileDropdown && (
              <motion.div
                className={cx(
                  'shadow-sm absolute w-[212px] mx-3.5 z-[301] border border-neutral-30 bottom-[4.75rem] rounded-[8px] '
                )}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                ref={profileDropDownRef}
              >
                <div className="rounded-xl bg-white p-2">
                  <div className="mb-2 text-neutral-500">Company</div>
                  <div
                    className="hover:bg-neutral-300 transition-colors rounded-lg flex items-center gap-2 p-2 cursor-pointer"
                    onClick={() => {
                      setShowChangeCompany(true);
                    }}
                  >
                    <div className="flex-none w-6 h-6 rounded-lg flex items-center justify-center bg-neutral-500 text-white">
                      {companyName.slice(0, 1)}
                    </div>
                    <div className="grow truncate">{companyName}</div>
                    <ArrowIcon />
                  </div>
                  <div className="my-2 border-t mx-2"></div>
                  <Link
                    to="/settings"
                    onClick={() => {
                      setActiveSidebar(false);
                      dispatch(setExpandedSubNav(false));
                    }}
                    className="hover:bg-neutral-300 transition-colors rounded-lg px-2.5 py-3 gap-2 flex items-center"
                  >
                    <Settings size="16px" />
                    Settings
                  </Link>
                  <div className="my-2 border-t mx-2"></div>
                  <div className="mb-2">
                    <div className="mb-2 text-neutral-500">Account</div>
                    <Link
                      to="/settings"
                      className="hover:bg-neutral-300 transition-colors rounded-lg flex items-center gap-2 p-2"
                    >
                      <UserProfilePicture {...data} size={24} />
                      <span className="truncate">
                        {formatUserDisplayName(data)}
                      </span>
                    </Link>
                  </div>
                  <div
                    className="px-2 pt-2 pb-3 hover:bg-neutral-300 transition-colors rounded-lg cursor-pointer text-danger-main flex items-center gap-2"
                    onClick={() => {
                      dispatch(logout());
                      history.push('/login');
                    }}
                  >
                    <LogOut size={'16px'} />
                    Log out
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>,
          sidebarRootRef
        )}
      <BBBTooltip content={companyName} position="right" show>
        <div
          className="cursor-pointer  mx-2.5 my-4"
          onClick={() => {
            setActiveProfileDropdown((prev) => !prev);
          }}
        >
          <div className="text-2xl w-[3.25rem] h-[3.25rem] rounded-2xl flex items-center justify-center bg-neutral-500 text-white">
            {companyName.slice(0, 1).toUpperCase()}
          </div>
        </div>
      </BBBTooltip>
      <BBBModal
        show={showChangeCompany}
        title="Change company"
        onHide={() => {
          setShowChangeCompany(false);
        }}
      >
        <CompanyList onCreateSuccess={() => setShowChangeCompany(false)} />
      </BBBModal>
    </>
  );
}

function ArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#727272"
        fillRule="evenodd"
        d="M4.82 6.558a.447.447 0 01-.045-.629l2.553-2.954a.887.887 0 011.344 0l2.553 2.954c.16.186.14.468-.045.63a.443.443 0 01-.627-.046L8 3.56 5.447 6.513a.444.444 0 01-.627.045zM11.18 9.443a.447.447 0 01.045.628l-2.553 2.955a.887.887 0 01-1.344 0l-2.553-2.955a.447.447 0 01.045-.628.444.444 0 01.627.044L8 12.442l2.553-2.955a.443.443 0 01.627-.044z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
