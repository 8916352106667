import { useState } from 'react';
import CampaignCreateButton from '../components/CreateButton';
import useColumn from './hooks/useColumn';
import useColumnMobile from './hooks/useColumnMobile';
import useColumnTablet from './hooks/useColumnTablet';

import { BBBButton, BBBContainer, BBBSelect } from '@/components/ui';
import BBBTableV2 from '@/components/ui/BBBTableV2/BBBTableV2';
import { statusOptions, typeOptions } from '@/constants/bitCRM/campaign';
import { mapSourceToRoutes } from '@/constants/whatsApp';
import useBlastCampaignRealtime from '@/hooks/bitApp/campaign/useBlastCampaignRealtime';
import useCampaigns from '@/hooks/bitCRM/campaign/useCampaigns';
import useCampaignsBulkAction from '@/hooks/bitCRM/campaign/useCampaignsBulkAction';
import useExportCampaign from '@/hooks/bitCRM/campaign/useExportCampaign';
import useResponsive from '@/hooks/common/useResponsive';
import useWhatsAppBannerPrompt from '@/hooks/whatsApp/useWhatsappBannerPrompt';
import type { Campaign as CampaignType } from '@/types/bitCRM/campaign';

function Campaign() {
  return <_Campaign />;
}

function _Campaign() {
  useWhatsAppBannerPrompt();

  useBlastCampaignRealtime();

  return <__Campaign />;
}

function __Campaign() {
  const isMobile = useResponsive('sm');
  const isTablet = useResponsive('lg');

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [search, setSearch] = useState('');
  const [filterStatus, setFilterStatus] = useState<
    typeof statusOptions[number] | null
  >();
  const [filterType, setFilterType] = useState<
    typeof typeOptions[number] | null
  >();
  const [selected, setSelected] = useState<CampaignType[]>([]);
  const [sort, setSort] = useState<string | undefined>('updatedAt:desc');

  const { isInitialLoading: loadingCampaign, data: campaignData } =
    useCampaigns({
      page: pageIndex,
      size: pageSize,
      search,
      status: filterStatus?.value,
      type: filterType?.value,
      sort,
    });

  const bulkAction = useCampaignsBulkAction();
  const { exportCampaign } = useExportCampaign();

  const newColumns = useColumn();
  const columnTablet = useColumnTablet();
  const columnMobile = useColumnMobile();

  return (
    <>
      <BBBContainer
        hasHeading
        pageTitle="Campaign"
        pageDescription="Create campaign to reach out to your customers"
        rightComponent={<CampaignCreateButton type="campaign" />}
      >
        <BBBTableV2
          data={campaignData?.content || []}
          dataId="id"
          loadingBody={loadingCampaign}
          headers={
            isMobile ? columnMobile : isTablet ? columnTablet : newColumns
          }
          searchPlaceholder="Search on campaign"
          isPaginate
          isSearchable
          pagination={{
            page: pageIndex + 1,
            limit: pageSize,
            onChange: (page) => setPageIndex(page - 1),
            total: campaignData?.totalElements || 0,
            maxShown: isMobile ? 1 : 3,
          }}
          isSelectable={!isMobile}
          withoutActionOption
          onChangeSelectable={setSelected}
          selected={selected}
          isShowLimit
          limitValue={pageSize}
          onLimitChange={(page) => {
            setPageSize(page!);
            setPageIndex(0);
          }}
          containerProps={{
            id: 'campaign-table',
          }}
          renderEmptyMessage={() => 'No campaign found'}
          isColumnHeightFixed={isMobile || isTablet ? false : true}
          isFilterable
          searchValue={search}
          onChangeSearch={setSearch}
          renderFilterSection={() =>
            selected.length ? (
              <>
                <BBBButton
                  variant="danger-outline"
                  text={`Delete (${selected.length})`}
                  onClick={() =>
                    bulkAction(
                      'delete',
                      selected.map((row) => row.id),
                      {
                        onSuccess: () => {
                          setSelected([]);
                        },
                      }
                    )
                  }
                />
                <BBBButton
                  variant="secondary"
                  text={`Export data (${selected.length})`}
                  onClick={() => exportCampaign(selected.map((row) => row.id))}
                />
              </>
            ) : (
              <>
                <BBBSelect
                  options={typeOptions}
                  optionLabel="label"
                  optionValue="value"
                  value={filterType}
                  onValueChange={(opt) =>
                    setFilterType(opt?.value === 'all' ? null : opt)
                  }
                  placeholder="Type"
                  containerClassName={isMobile ? 'col-span-2' : undefined}
                />
                <BBBSelect
                  options={statusOptions}
                  optionLabel="label"
                  optionValue="value"
                  value={filterStatus}
                  onValueChange={(opt) =>
                    setFilterStatus(opt?.value === 'all' ? null : opt)
                  }
                  placeholder="Status"
                  containerClassName={isMobile ? 'col-span-2' : undefined}
                />
              </>
            )
          }
          isAsyncSort
          onClickSortHeader={(key, sort) => {
            setSort(`${key}:${sort}`);
          }}
          linkDestination={(row) => {
            if (['DRAFT', 'SCHEDULED'].includes(row.status)) {
              return `/bitcrm/campaign/${mapSourceToRoutes[row.type]}/${
                row.id
              }`;
            }

            if (row.status === 'SENT') {
              return `/bitcrm/campaign/${mapSourceToRoutes[row.type]}/${
                row.id
              }/log`;
            }
          }}
        />
      </BBBContainer>
    </>
  );
}

export default Campaign;
