import { createSlice } from '@reduxjs/toolkit';
import {
  CartColorTypes,
  CartImageTypes,
  CategoryTypes,
  initialStateModal,
  ModalState,
  PlaceholderPositionTypes,
  PopupState,
} from './types';

import { EmptyCartSettings } from '@/types/bitApp/CartSettings';

export type ActiveDesign = 'layout' | 'navigation' | 'branding';
export interface BitAppStore {
  modal: ModalState;
  selected: number | null;
  activePreview: string;
  draggingDroppable: string | null;
  draggingDraggable: string | null;
  cartImage: CartImageTypes;
  cartColor: CartColorTypes;
  cartMessage: string;
  cartSettings: Partial<EmptyCartSettings> | null;
  activeDesign: ActiveDesign;
  placeholderPosition: Partial<PlaceholderPositionTypes> | null;
  popup: Partial<PopupState> | null;
  popupError: number | null;
  changedPopup: boolean;
  category: CategoryTypes | null;
  loadingPreview: boolean;
}

const initialState: BitAppStore = {
  modal: initialStateModal,
  selected: null,
  cartColor: {
    isPrimaryDefault: false,
    color: '#000000',
  },
  cartMessage: '',
  draggingDroppable: null,
  cartImage: undefined,
  cartSettings: null,
  draggingDraggable: null,
  activePreview: 'home',
  activeDesign: 'layout',
  placeholderPosition: null,
  popup: null,
  popupError: null,
  changedPopup: false,
  category: null,
  loadingPreview: false,
};

export const bitAppSlice = createSlice({
  name: 'bitApp',
  initialState,
  reducers: {
    show: (
      state,
      { payload: { key } }: { payload: { key: keyof typeof state.modal } }
    ) => {
      state.modal[key] = true;
    },
    hide: (
      state,
      { payload: { key } }: { payload: { key: keyof typeof state.modal } }
    ) => {
      state.modal[key] = false;
    },
    closeAllModal: (state) => {
      for (let i = 0; i < Object.keys(state.modal).length; i += 1) {
        state.modal[Object.keys(state.modal)[i] as keyof typeof state.modal] =
          false;
      }
    },
    setImageTemplate: (
      state: BitAppStore,
      { payload }: { payload: CartImageTypes }
    ) => {
      state.cartImage = payload;
    },
    setCartColor: (
      state: BitAppStore,
      { payload }: { payload: CartColorTypes }
    ) => {
      state.cartColor = payload;
    },
    setCartSettings: (
      state: BitAppStore,
      { payload }: { payload: Partial<EmptyCartSettings> | null }
    ) => {
      let valid = true;
      if (
        !payload ||
        !Object.values(payload).filter((value) => value || String(value).length)
          .length
      )
        valid = false;
      state.cartSettings = !valid
        ? null
        : { ...state.cartSettings, ...(payload || {}) };
    },
    setCartMessage: (state: BitAppStore, { payload }: { payload: string }) => {
      state.cartMessage = payload;
    },
    addItem: (
      state,
      {
        payload: { type: key, id },
      }: { payload: { type: keyof typeof state.modal; id: number } }
    ) => {
      state.selected = id;
      state.popup = { type: key, id, hasError: false };
    },
    setSelected: (state, action: any) => {
      state.selected = action.payload;
    },
    setDraggingDroppable: (state, action: any) => {
      state.draggingDroppable = action.payload;
    },
    setDraggingDraggable: (state, action: any) => {
      state.draggingDraggable = action.payload;
    },
    setActivePreview: (state, action: any) => {
      state.activePreview = action.payload;
    },
    setActiveDesign: (
      state,
      action: { payload: BitAppStore['activeDesign'] }
    ) => {
      state.activeDesign = action.payload;
    },
    setPlaceholderPosition: (
      state,
      action: { payload: Partial<PlaceholderPositionTypes> | null }
    ) => {
      state.placeholderPosition = action.payload;
    },
    setPopup: (state, action: { payload: BitAppStore['popup'] }) => {
      state.popup = action.payload;
    },
    setChangedPopup: (state, action: { payload: boolean }) => {
      state.changedPopup = action.payload;
    },
    setPopupError: (state, action: { payload: number | null }) => {
      state.popupError = action.payload;
    },
    setCategory: (state, action: { payload: BitAppStore['category'] }) => {
      state.category = action.payload;
    },
    setLoadingPreview: (
      state,
      action: { payload: BitAppStore['loadingPreview'] }
    ) => {
      state.loadingPreview = action.payload;
    },
  },
});

export const {
  addItem,
  setActivePreview,
  closeAllModal,
  setActiveDesign,
  setDraggingDraggable,
  setCartMessage,
  setSelected,
  setCartColor,
  show,
  setCartSettings,
  hide,
  setImageTemplate,
  setDraggingDroppable,
  setPlaceholderPosition,
  setPopup,
  setChangedPopup,
  setPopupError,
  setCategory,
  setLoadingPreview,
} = bitAppSlice.actions;

export default bitAppSlice.reducer;
