import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import services from '@/api/services';
import { useAppSelector } from '@/hooks/rtk/store';
import { SectionAssociation } from '@/types/bitApp/association';

const useSection = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  return useInfiniteQuery(
    ['sections', activeCompany],
    ({ pageParam = 0 }) =>
      services.bitApp.section.getSectionByCompanyId(activeCompany!, {
        offset: pageParam,
      }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.meta.hasMore) {
          return lastPage.meta.offset;
        }
        return undefined;
      },
    }
  );
};

const useSectionDetail = (sectionId: number | null) => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  return useQuery<SectionAssociation>(
    ['section', sectionId],
    () => services.bitApp.section.getSectionDetail(sectionId!, activeCompany!),
    {
      enabled: !!sectionId,
    }
  );
};

export default useSection;
export { useSectionDetail };
