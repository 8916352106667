import React, { useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import DraggableIcon from 'assets/icons/BBBDraggableIcon';
import { AnimatePresence, motion } from 'framer-motion';
import {
  useCategory,
  useRearrangeCategory,
  useUpdateCategory,
} from 'hooks/bitApp/category';
import { useAppSelector } from 'hooks/rtk/store';
import { chunk, cloneDeep } from 'lodash-es';
import { setCategory as setCategoryStore } from 'stores/bitApp';
import { twMerge as cx } from 'tailwind-merge';
import AddCategory from './Category/AddCategory';
import Category from './Category';

import { BBBCard, BBBPrimarySwitch, BBBSpinner } from '@/components/ui';

function LayoutCategoryPage() {
  const dispatch = useDispatch();
  const categoryIdStore = useAppSelector((state) => state.bitApp.category?.id);

  const [category, setCategory] = useState<number | null>(null);

  const {
    isInitialLoading: loadingCategory,
    data: dataCategory,
    error: errorCategory,
    fetchNextPage,
    hasNextPage,
  } = useCategory();
  const { mutate: rearrangeCategory } = useRearrangeCategory();

  const flattenCategory = dataCategory?.pages.flatMap((page) => page.data);

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const pagesData =
      cloneDeep(dataCategory?.pages || []).flatMap((page) => page.data) || [];
    const [reorderedItem] = pagesData.splice(result.source.index, 1) || [];
    pagesData.splice(result.destination.index, 0, reorderedItem);

    const chunkedPages = chunk(pagesData, Infinity);

    rearrangeCategory({
      data: {
        pageParams: dataCategory?.pageParams as unknown[],
        pages: chunkedPages.map((page, index) => {
          return {
            data: page,
            meta: dataCategory?.pages[index]?.meta as {
              hasMore: boolean;
              offset: number;
              total: number;
            },
          };
        }),
      },
    });
  };

  if (loadingCategory) {
    return <BBBSpinner />;
  }

  if (errorCategory) return null;

  return (
    <div className="relative min-h-0 pb-20">
      <AddCategory />
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="bitapp-categories">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              id="bitapp-dropable-categories"
              className="min-h-0"
            >
              <InfiniteScroll
                next={() => fetchNextPage()}
                hasMore={hasNextPage || false}
                scrollableTarget={'bitapp-categories-container'}
                dataLength={flattenCategory?.length || 0}
                loader={undefined}
                className="!overflow-hidden pb-24"
              >
                <AnimatePresence mode="popLayout">
                  {categoryIdStore === 0 && (
                    <Category
                      categoryId={categoryIdStore}
                      onClose={() => dispatch(setCategoryStore(null))}
                    />
                  )}
                  {flattenCategory?.map((item, index) => (
                    <React.Fragment key={item.title}>
                      {item.id === category ? (
                        <Category
                          categoryId={item.id}
                          onClose={() => setCategory(null)}
                        />
                      ) : (
                        <motion.div
                          transition={{
                            type: 'spring',
                            damping: 20,
                            stiffness: 150,
                          }}
                          layout
                        >
                          <Draggable
                            draggableId={`${item.id}-${index}`}
                            index={index}
                          >
                            {(providedDraggable) => (
                              <BBBCard
                                ref={providedDraggable?.innerRef}
                                {...providedDraggable?.draggableProps}
                                className={cx(
                                  'flex gap-4 items-center mb-3 hover:bg-neutral-20 hover:border-secondary-border transition-all duration-300 ease-in-out md:p-6'
                                )}
                                onClick={(e) => {
                                  if (
                                    !(e.target as Element).id.includes(
                                      'headlessui-switch'
                                    ) &&
                                    !(e.target as Element).id
                                  ) {
                                    setCategory(item.id);
                                  }
                                }}
                                style={{
                                  ...providedDraggable?.draggableProps.style,
                                  cursor: 'pointer',
                                  height:
                                    item.id === category ? '110px' : '84px',
                                }}
                              >
                                <div
                                  className={cx(
                                    'grow overflow-clip',
                                    item?.subCategories?.length
                                      ? 'flex flex-col'
                                      : ''
                                  )}
                                >
                                  <p className="text-primary-main font-medium">
                                    {item.title}
                                  </p>
                                  {!!item?.subCategories?.length && (
                                    <p className="text-neutral-50 text-sm">
                                      +{item?.subCategories?.length}{' '}
                                      sub-category(s)
                                    </p>
                                  )}
                                </div>
                                <ToggleCategory
                                  categoryId={item.id}
                                  active={item.active}
                                />

                                <div {...providedDraggable?.dragHandleProps}>
                                  <DraggableIcon />
                                </div>
                              </BBBCard>
                            )}
                          </Draggable>
                        </motion.div>
                      )}
                    </React.Fragment>
                  ))}
                </AnimatePresence>
              </InfiniteScroll>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

function ToggleCategory({
  categoryId,
  active,
}: {
  categoryId: number;
  active: boolean | null;
}) {
  const { mutate: toggleCategory } = useUpdateCategory(categoryId);

  return (
    <BBBPrimarySwitch
      checked={active || false}
      onChange={() => toggleCategory({ active: !active })}
    />
  );
}

export default LayoutCategoryPage;
