import api from '@/config/api';
import { useAppSelector } from '@/hooks/rtk/store';
import { downloadXlsxFile } from '@/utils/common/file';

const useExportCampaign = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  const exportCampaign = (campaignIds: string[], action?: () => void) =>
    api.bitCRM.v2
      .get(`/log/campaign/export`, {
        params: {
          companyId: Number(activeCompany),
          campaignIds: campaignIds.join(','),
        },
        responseType: 'blob',
      })
      .then(({ data }) => {
        downloadXlsxFile(data, 'Campaign', action);
      });

  return {
    exportCampaign,
  };
};

export default useExportCampaign;
