import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditorState } from 'draft-js';
import { twMerge as cx } from 'tailwind-merge';
import * as yup from 'yup';

import {
  BBBCard,
  BBBFooterAction,
  BBBPrimarySwitch,
  BBBRichTextEditor,
} from '@/components/ui';
import useLinklistAddMutation, {
  useLinklistDeleteMutation,
  useLinklistEditMutation,
} from '@/hooks/bitLink/links/useLinklistMutation';
import useCustomForm from '@/hooks/common/useCustomForm';
import useResponsive from '@/hooks/common/useResponsive';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { setForm } from '@/stores/bitLink';
import {
  convertEditorStateToHtml,
  convertHtmlToEditorState,
  emptyEditor,
} from '@/utils/common/rich';

const schema = yup.object().shape({
  title: yup.mixed<EditorState>().test('title', 'Text required', (value) => {
    return value ? value.getCurrentContent().hasText() : false;
  }),
  active: yup.bool().required('Active is required'),
});

type FormTextInput = {
  title: EditorState;
  active: boolean;
};

function FormText() {
  const {
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors },
    watch,
  } = useCustomForm<FormTextInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      title: emptyEditor,
      active: true,
    },
  });

  const dispatch = useAppDispatch();
  const isMobile = useResponsive('sm');

  const formText = useAppSelector((state) => state.bitLink.form);

  useEffect(() => {
    setValue('title', convertHtmlToEditorState(formText?.textPrimary || ''));
    setValue('active', formText?.active ?? true);
  }, [formText, setValue]);

  const { isLoading, mutate: addLinklist } = useLinklistAddMutation();

  const { isLoading: isLoadingEdit, mutate: editLinklist } =
    useLinklistEditMutation();

  const deleteLinklist = useLinklistDeleteMutation();

  const onSubmit = (data: FormTextInput) => {
    const requestData = {
      type: 'text' as const,
      textPrimary: convertEditorStateToHtml(data.title),
      active: data.active,
    };

    if (!formText?.id) {
      if (data.title.getCurrentContent().getPlainText().length > 100) {
        return setError('title', {
          type: 'title',
          message: 'Text is limit 100 characters',
        });
      }
      addLinklist(requestData);
    } else {
      editLinklist({ id: formText?.id, data: requestData });
    }
  };

  const handleDeleteForm = () => {
    if (formText?.id) {
      deleteLinklist(formText?.id);
    } else {
      dispatch(setForm(null));
    }
  };

  return (
    <BBBCard
      id="form-text"
      title="Text"
      rightButton={
        !isMobile && (
          <Controller
            control={control}
            name="active"
            render={({ field }) => (
              <BBBPrimarySwitch
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
        )
      }
      className={cx('mb-cardBottom md:p-6', isMobile && 'border-none p-0')}
    >
      <Controller
        control={control}
        name="title"
        render={({ field }) => (
          <BBBRichTextEditor
            placeholder="Type your text"
            editorState={field.value}
            hasLimit
            maxChar={100}
            onChangeEditorState={(val) => field.onChange(val)}
            rows={2}
            error={errors.title?.message}
          />
        )}
      />
      <BBBFooterAction
        containerClassName="justify-end"
        onCancel={() => dispatch(setForm(null))}
        withoutDelete={formText?.id ? undefined : true}
        loadingSave={isLoading || isLoadingEdit}
        onSave={handleSubmit(onSubmit)}
        onDelete={() => handleDeleteForm()}
      />
    </BBBCard>
  );
}

export default FormText;
