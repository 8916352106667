import { useEffect, useState } from 'react';

import CompleteOnboardingIcon from '@/assets/icons/CompleteOnboardingIcon';
import BBBJourneyV2, { Step } from '@/components/ui/BBBJourneyV2/BBBJourneyV2';
import useOnboarding from '@/hooks/bitApp/onboarding/useOnboarding';
import useUpdateOnboarding from '@/hooks/bitApp/onboarding/useUpdateOnboarding';
import { useAppDispatch } from '@/hooks/rtk/store';
import {
  closeAllModal,
  setActiveDesign,
  setActivePreview,
  setPopup,
  show,
} from '@/stores/bitApp';
import { handleCancelLiveSection } from '@/utils/bitApp';

function Journey() {
  const { data, status } = useOnboarding('design');

  if (status !== 'success') return null;

  if (data?.lastStep && data.lastStep > 11) return null;

  return <_Journey lastStep={data?.lastStep || 0} />;
}

function _Journey({ lastStep }: { lastStep: number }) {
  const dispatch = useAppDispatch();
  const { mutate: updateOnboarding } = useUpdateOnboarding();

  const droppableId = document.querySelector('#mobile-content');
  const joyrideSteps: Step[] = [
    {
      title: 'Welcome to bitApp',
      content:
        'In this tour, you can learn how to build your own mobile app for your business. It simply drag n drop then you gonna have your own mobile app',
      isModal: true,
      icon: <StartIcon />,
    },
    {
      target: '#onboarding-dragable',
      title: <div className="text-center">Drag to add content</div>,
      content: (
        <div className="text-center">
          You can add content to your app by dragging the content to mobile view
        </div>
      ),
      spotlightColor: 'transparent',
    },
    {
      target: '#design-layout',
      title: <div className="text-center">Combine your contents</div>,
      content: (
        <div className="text-center">
          You can combine images, collections, and product to give the best
          shopping experience to your customer
        </div>
      ),
      placement: 'right',
    },
    {
      target: '#bbb-device-frame',
      title: (
        <div className="text-center">Edit and re-arrange your contents</div>
      ),
      content: (
        <div className="text-center">
          You can edit and re-arrange your contents by click and drag content
          inside the mobile view
        </div>
      ),
      placement: 'left',
    },
    {
      target: '#livepreview-circle-image',
      title: <div className="text-center">Now, try to edit this section</div>,
      content: (
        <div className="text-center">
          <span className="font-bold">Click here</span> to edit this section!
        </div>
      ),
      placement: 'left',
      clickSpotlightToNext: true,
      pulseOffset: {
        top: 50,
        left: -100,
      },
    },
    {
      target: '#edit-collection-popup',
      title: <div className="text-center">Circle image grid</div>,
      content: (
        <div className="text-center">
          In this grid, you can add your own image, add title, and choose
          destination page.
        </div>
      ),
      placement: 'left',
    },
    {
      target: '#edit-collection-popup',
      title: <div className="text-center">Select product or collections</div>,
      content: (
        <div className="text-center">
          You can put your products or collections and it&apos;s 100% integrated
          with shopify
        </div>
      ),
      placement: 'left',
      offset: {
        top: 160,
      },
    },
    {
      target: '#navigator-tab',
      title: <div className="text-center">You did it!</div>,
      content: (
        <div className="text-center">
          You have created your home section! Now, try to re-arrange your app
          navigation. <span className="font-bold">Click here!</span>
        </div>
      ),
      clickSpotlightToNext: true,
      pulseOffset: {
        left: -50,
        top: 10,
      },
    },
    {
      target: '#design-navigation',
      title: <div className="text-center">Re-arrange navigation bar</div>,
      content: (
        <div className="text-center">
          You can arrange the navigation bar order and you can adjust to what’s
          your customers need
        </div>
      ),
      placement: 'right',
    },
    {
      target: '#navigator-tab',
      title: <div className="text-center">Great! Keep it going!</div>,
      content: (
        <div className="text-center">
          Now open the branding section to adjust your app style and match with
          your brand identity. <span className="font-bold">Click here!</span>
        </div>
      ),
      clickSpotlightToNext: true,
      pulseOffset: {
        left: 120,
        top: 10,
      },
      offset: {
        left: 170,
      },
    },
    {
      target: '#design-branding',
      title: <div className="text-center">Adjust your app appearance</div>,
      content: (
        <div className="text-center">
          In this section, you can set your app name, icon, splash screen,
          colors, font, and icon style. Match it with your brand identity.
        </div>
      ),
      placement: 'right',
    },
    {
      title: 'You did it!',
      content: 'Now you can start building your own mobile app',
      isModal: true,
      onNext: (val) => {
        updateOnboarding({
          moduleKey: 'design',
          lastStep: val,
        });
      },
      icon: <CompleteOnboardingIcon />,
    },
  ];

  const [stepIndex, setStepIndex] = useState(lastStep);

  useEffect(() => {
    if (lastStep === 3) {
      droppableId?.scrollTo({
        top: -300,
        behavior: 'smooth',
      });
    } else if (lastStep === 4) {
      droppableId?.scrollTo({
        top: 300,
        behavior: 'smooth',
      });
      dispatch(setPopup(null));
    } else if (lastStep === 5 || lastStep === 6) {
      dispatch(
        setPopup({
          type: 'imagegrids',
        })
      );
      dispatch(setActiveDesign('layout'));
    } else if (lastStep === 7) {
      dispatch(setPopup(null));
    } else if (lastStep === 8 || lastStep === 9) {
      dispatch(setActiveDesign('navigation'));
      dispatch(setActivePreview('home'));
    } else if (lastStep === 10) {
      dispatch(setActivePreview('app-icon'));
      dispatch(setActiveDesign('branding'));
    } else if (lastStep === 11) {
      dispatch(setActivePreview('home'));
      dispatch(setActiveDesign('layout'));
    } else {
      handleCancelLiveSection();
    }
    setStepIndex(lastStep);
  }, [dispatch, droppableId, lastStep]);

  return (
    <BBBJourneyV2
      steps={joyrideSteps}
      stepIndex={stepIndex}
      onChangeStep={(val) => {
        if (val === joyrideSteps.length) {
          dispatch(closeAllModal());
        }
        updateOnboarding({
          moduleKey: 'design',
          lastStep: val,
        });
      }}
    />
  );
}

function StartIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="768"
      height="728.858"
      data-name="Layer 1"
      viewBox="0 0 768 728.858"
      className="w-[215px] h-[190px]"
    >
      <path
        fill="#e6e6e6"
        d="M466.884 172.947h-3.999V63.402A63.402 63.402 0 00399.483 0H167.397a63.402 63.402 0 00-63.402 63.402v600.974a63.402 63.402 0 0063.401 63.402h232.087a63.402 63.402 0 0063.402-63.402V250.923h3.999z"
      ></path>
      <path
        fill="#fff"
        d="M402.041 16.495h-30.295a22.495 22.495 0 01-20.827 30.99H217.96a22.495 22.495 0 01-20.826-30.99h-28.296a47.348 47.348 0 00-47.348 47.348v600.092a47.348 47.348 0 0047.348 47.348h233.204a47.348 47.348 0 0047.348-47.348V63.843a47.348 47.348 0 00-47.348-47.348z"
      ></path>
      <path
        fill="#3f3d56"
        d="M767 728.858H1a1 1 0 010-2h766a1 1 0 010 2zM426.82 182.504H143.946a5.245 5.245 0 01-5.238-5.239v-70.169a5.245 5.245 0 015.238-5.238H426.82a5.245 5.245 0 015.239 5.238v70.17a5.245 5.245 0 01-5.239 5.238zm-282.874-78.55a3.146 3.146 0 00-3.143 3.142v70.17a3.146 3.146 0 003.143 3.142H426.82a3.146 3.146 0 003.143-3.143v-70.169a3.146 3.146 0 00-3.143-3.143zM426.82 460.139H143.946a5.245 5.245 0 01-5.238-5.239V325.014a5.245 5.245 0 015.238-5.239H426.82a5.245 5.245 0 015.239 5.239V454.9a5.245 5.245 0 01-5.239 5.239zM143.946 321.87a3.146 3.146 0 00-3.143 3.143V454.9a3.146 3.146 0 003.143 3.144H426.82a3.146 3.146 0 003.143-3.144V325.014a3.146 3.146 0 00-3.143-3.143z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M424.725 291.462H299.003a5.245 5.245 0 01-5.239-5.238v-70.169a5.245 5.245 0 015.239-5.238h125.722a5.245 5.245 0 015.238 5.238v70.17a5.245 5.245 0 01-5.238 5.237zm-125.722-78.55a3.146 3.146 0 00-3.143 3.143v70.169a3.146 3.146 0 003.143 3.143h125.722a3.146 3.146 0 003.143-3.143v-70.169a3.146 3.146 0 00-3.143-3.143zM269.668 629.863H143.946a5.245 5.245 0 01-5.238-5.239V493.69a5.245 5.245 0 015.238-5.238h125.722a5.245 5.245 0 015.239 5.238v130.935a5.245 5.245 0 01-5.239 5.238zM143.946 490.547a3.146 3.146 0 00-3.143 3.143v130.935a3.146 3.146 0 003.143 3.143h125.722a3.146 3.146 0 003.143-3.143V493.69a3.146 3.146 0 00-3.143-3.143zM424.725 629.863H299.003a5.245 5.245 0 01-5.239-5.239V493.69a5.245 5.245 0 015.239-5.238h125.722a5.245 5.245 0 015.238 5.238v130.935a5.245 5.245 0 01-5.238 5.238zM299.003 490.547a3.146 3.146 0 00-3.143 3.143v130.935a3.146 3.146 0 003.143 3.143h125.722a3.146 3.146 0 003.143-3.143V493.69a3.146 3.146 0 00-3.143-3.143z"
      ></path>
      <circle cx="185.804" cy="365.787" r="21.338" fill="#fd823e"></circle>
      <path
        fill="#fd823e"
        d="M235.085 351.562a3.556 3.556 0 000 7.112h167.66a3.556 3.556 0 000-7.112zM235.085 372.9a3.556 3.556 0 00-.015 7.113h72.16a3.556 3.556 0 100-7.113z"
      ></path>
      <path
        fill="#ccc"
        d="M169.437 407.014c-2.706 0-4.907 1.596-4.907 3.557s2.201 3.556 4.907 3.556h231.318c2.705 0 4.907-1.596 4.907-3.556s-2.202-3.557-4.907-3.557zM169.437 428.353c-2.706 0-4.907 1.595-4.907 3.556s2.201 3.556 4.907 3.556h231.318c2.705 0 4.907-1.595 4.907-3.556s-2.202-3.556-4.907-3.556z"
      ></path>
      <circle cx="187.899" cy="139.488" r="21.338" fill="#fd823e"></circle>
      <path
        fill="#fd823e"
        d="M237.18 125.263a3.556 3.556 0 000 7.113h167.66a3.556 3.556 0 10.015-7.113h-.015zM237.18 146.601a3.556 3.556 0 000 7.113h72.145a3.556 3.556 0 10.015-7.113h-.015z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M269.557 291.475H151.619l27.755-48.073a5.668 5.668 0 019.816 0l11.857 20.536 20.16-34.918a7.096 7.096 0 0112.29 0zm-114.31-2.095h110.681l-34.245-59.313a5 5 0 00-8.66 0l-21.976 38.061-13.671-23.679a3.572 3.572 0 00-6.187 0zM420.423 291.475H302.485l27.754-48.073a5.668 5.668 0 019.817 0l11.857 20.536 20.16-34.918a7.096 7.096 0 0112.29 0zm-114.31-2.095h110.68l-34.244-59.313a5 5 0 00-8.66 0l-21.976 38.061-13.672-23.679a3.572 3.572 0 00-6.186 0z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M269.668 291.462H143.946a5.245 5.245 0 01-5.238-5.238v-70.169a5.245 5.245 0 015.238-5.238h125.722a5.245 5.245 0 015.239 5.238v70.17a5.245 5.245 0 01-5.239 5.237zm-125.722-78.55a3.146 3.146 0 00-3.143 3.143v70.169a3.146 3.146 0 003.143 3.143h125.722a3.146 3.146 0 003.143-3.143v-70.169a3.146 3.146 0 00-3.143-3.143zM170.735 515.453a3.556 3.556 0 100 7.113h72.144a3.556 3.556 0 000-7.113zM170.735 536.406a3.556 3.556 0 100 7.113h72.144a3.556 3.556 0 000-7.113zM206.807 602.862a22.386 22.386 0 1122.386-22.386 22.411 22.411 0 01-22.386 22.386zm0-42.677a20.29 20.29 0 1020.291 20.29 20.314 20.314 0 00-20.29-20.29zM397.936 602.862a3.556 3.556 0 100-7.113h-72.144a3.556 3.556 0 000 7.113zM397.936 581.908a3.556 3.556 0 100-7.113h-72.144a3.556 3.556 0 000 7.113zM361.864 515.453a22.386 22.386 0 11-22.386 22.386 22.411 22.411 0 0122.386-22.386zm0 42.676a20.29 20.29 0 10-20.291-20.29 20.314 20.314 0 0020.29 20.29z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M589.987 713.726L573.8 713.726 566.1 651.292 589.99 651.293 589.987 713.726z"
      ></path>
      <path
        fill="#2f2e41"
        d="M557.47 709.1h35.985v19.656h-50.872v-4.769a14.887 14.887 0 0114.887-14.886z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M654.681 713.726L638.495 713.726 630.794 651.292 654.684 651.293 654.681 713.726z"
      ></path>
      <path
        fill="#2f2e41"
        d="M622.165 709.1h35.985v19.656h-50.872v-4.769a14.887 14.887 0 0114.887-14.886z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M561.786 533.743a12.38 12.38 0 00-1.067-18.954l6.935-27.428-15.64-8.28-9.25 38.872a12.447 12.447 0 0019.022 15.79z"
      ></path>
      <path
        fill="#2f2e41"
        d="M660.438 485.836L659.118 578.257 657.797 699.724 628.751 697.083 614.228 529.406 595.744 697.083 565.756 697.083 562.736 528.086 573.299 491.117 660.438 485.836z"
      ></path>
      <path
        fill="#3f3d56"
        d="M570.403 491.681l-.425-.191 16.554-113.228-8.415-34.223a7.49 7.49 0 014.641-8.799l15.142-5.677 5.35-14.712 42.206 4.548 1.038 11.498c4.574 1.467 42.237 14.01 39.638 28.302-2.565 14.107-17.709 58.488-18.478 60.738l-2.652 63.649-.326.163c-.393.196-29.485 14.5-61.089 14.498-11.13 0-22.577-1.776-33.184-6.566z"
      ></path>
      <path
        fill="#3f3d56"
        d="M542.217 503.566l13.307-85.161c.079-.502 7.952-50.355 10.563-66.02 2.696-16.173 15.215-16.36 15.342-16.36h6.89l8.058 36.265-15.876 72.763-14.647 60.298z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M628.529 495.161a12.38 12.38 0 0115.115-11.485l16.372-23.073 16.448 6.53-23.578 32.258a12.447 12.447 0 01-24.357-4.23z"
      ></path>
      <path
        fill="#3f3d56"
        d="M646.489 475.319l29.109-48.957-11.86-31.626 16.153-43.074.595.256c.39.167 9.569 4.263 10.929 18.93l18.562 58.336-49.54 64.267z"
      ></path>
      <circle
        cx="838.699"
        cy="362.753"
        r="30.389"
        fill="#ffb8b8"
        transform="rotate(-61.337 658.55 502.09)"
      ></circle>
      <path
        fill="#2f2e41"
        d="M602.296 262.38c3.146 3.723 8.47 4.774 13.38 5.204 9.974.875 24.477-.544 34.224-2.794.695 6.742-1.202 14.012 2.77 19.559a90.798 90.798 0 003.226-20.584c.12-2.986.073-6.075-1.14-8.816s-3.86-5.068-6.907-5.092a17.311 17.311 0 017.721-3.301l-9.649-4.85 2.478-2.543-17.469-1.07 5.06-3.214a109.715 109.715 0 00-22.986-.725c-3.56.262-7.282.763-10.166 2.826s-4.551 6.162-2.76 9.19a13.344 13.344 0 00-10.17 8.069c-1.286 3.26-1.168 6.88-.772 10.355a73.399 73.399 0 003.849 16.28"
      ></path>
    </svg>
  );
}

export default Journey;
