import React, {
  CSSProperties,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { ChevronDown, ChevronUp, Info } from 'react-feather';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { sortBy } from 'lodash-es';
import { twMerge as cx } from 'tailwind-merge';
import SearchInput from '../SearchInput';
import {
  HeadersTypes,
  TableDropdownLimitType,
  TableTypes,
} from './BBBTableV2.type';

import BBBDraggableIcon from '@/assets/icons/BBBDraggableIcon';
import {
  BBBAlert,
  BBBCheckbox,
  BBBPagination,
  BBBSelect,
  BBBSpinner,
  BBBTooltip,
} from '@/components/ui';
import getDescendantProp from '@/types/utils/getDescendantProp';
import { Paths } from '@/types/utils/nestedObjectPath';

const actionOptions = [
  {
    label: 'Delete',
    value: 'delete',
  },
];

function calculateHeight() {
  let total = 0;
  document.querySelectorAll(`#table-row`).forEach((element) => {
    total += element.clientHeight;
  });

  return total;
}

const BBBTableV2 = <TData extends Record<string, any>>(
  props: TableTypes<TData>,
  ref?: Ref<HTMLDivElement>
) => {
  const {
    headers,
    data,
    className,
    headerClassName,
    tableClassName,
    loadingBody = false,
    loadingHeader = false,
    customLoadingBodyRenderer,
    customLoadingHeadRenderer,
    error,
    tableMaxHeight,
    tableMaxHeightFull,
    dataId,
    footer,
    renderEmptyMessage = () => 'No data found',
    renderCustomHeader,
    onClickRow,
    disabledRow,
    containerProps,
    isColumnHeightFixed = false,
    withoutHeader,

    //* * Start region pagination prop */
    isPaginate,
    pagination,
    /** End region pagination prop */

    /** Start region dropdown prop */
    isShowLimit,
    optionNumbers = [10, 25, 50, 100],
    limitValue = 10,
    onLimitChange,
    /** End region dropdown prop */

    /** Start region search prop */
    isSearchable,
    searchValue,
    onChangeSearch,
    searchPlaceholder = 'Search anything',
    /** End region search prop */

    /** Start region filter prop */
    isFilterable,
    renderFilterSection,
    /** End region filter prop */

    /** Start region selectable prop */
    isSelectable,
    onChangeSelectable,
    onActionChange,
    withoutActionOption,
    selected: selectedFromProps,
    isAllRowSelected: allSelected,
    onChangeAllRowSelected,
    /** End region selectable prop */

    /** Start region draggable prop */
    isDraggable,
    onDragEnd,
    /** End region draggable prop */

    /** Start region expandable prop */
    isExpandable,
    expandedRow,
    renderExpandedRow,

    subHeaderComponent,
    /** End region expandable prop */

    /**
     * Start region sorting header prop
     */
    isAsyncSort = false,
    onClickSortHeader,
    customSelectedHeader,

    /**
     * End region sorting header prop
     */

    renderCustomRow,
    linkDestination,
  } = props;

  const isSelectedControlled = typeof selectedFromProps !== 'undefined';

  const getStyle = useCallback(
    (idx: number, colSpan: number | undefined = 1): CSSProperties => ({
      alignItems: headers[idx].align,
      justifyContent: headers[idx].align,
      flex: headers[idx].columnWidth ? 'none' : '1',
      width: headers[idx].columnWidth,
      minWidth: 0,
      wordWrap: 'break-word',
      alignContent: 'center',
    }),
    [headers]
  );

  const [localSelected, setSelected] = useState<TData[]>([]);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | undefined>(
    undefined
  );
  const [sortKey, setSortKey] = useState<string | undefined>(undefined);
  const tableBodyRef = useRef<HTMLDivElement | null>(null);

  const selected = isSelectedControlled ? selectedFromProps : localSelected;

  const allRowSelectedManual =
    dataId &&
    !!data?.length &&
    data.every((singleData) =>
      selected.some((_selected) => _selected[dataId] === singleData[dataId])
    );

  const isHasSortOrder = headers.find((header) => header.sortOrder);

  const handleDragEnd = useCallback(
    (result: DropResult) => onDragEnd?.(result),
    [onDragEnd]
  );

  const sortData = useCallback(
    ({
      data,
      sortKey,
      reverse,
    }: {
      data?: TData[];
      sortKey?: string;
      reverse: boolean;
    }) => {
      if (!sortKey) {
        return data;
      }

      const sortedData = sortBy(data, [sortKey]);

      return reverse ? sortedData.reverse() : sortedData;
    },
    []
  );

  const sortedData = useMemo(
    () =>
      isAsyncSort
        ? data
        : sortData({
            data,
            sortKey,
            reverse: sortOrder === 'desc',
          }),
    [data, isAsyncSort, sortData, sortKey, sortOrder]
  );

  const handleSort = useCallback(
    <T extends Record<string, unknown>, K extends Paths<T> | undefined>(
      key: K
    ) => {
      if (sortKey === key) {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      } else {
        setSortKey(key);
        setSortOrder('asc');
      }
      onClickSortHeader?.(key, sortOrder === 'asc' ? 'desc' : 'asc');
    },
    [onClickSortHeader, sortKey, sortOrder]
  );

  useEffect(() => {
    if (isHasSortOrder) {
      const { sortOrder, accesor } = isHasSortOrder;
      setSortKey(accesor);
      setSortOrder(sortOrder);
    }
  }, [isHasSortOrder]);

  const [calculatedHeight, setCalculatedHeight] = useState<number>();

  useEffect(() => {
    setCalculatedHeight(calculateHeight());
  }, [data?.length]);

  useEffect(() => {
    if (data?.length && tableBodyRef.current) {
      const observer = new MutationObserver(() => {
        setCalculatedHeight(calculateHeight());
      });
      observer.observe(tableBodyRef.current, {
        childList: true,
        subtree: true,
        attributes: true,
      });
      return () => observer.disconnect();
    }
  }, [data?.length]);

  const memoizedTable = useMemo(
    () => (
      <div
        style={{
          maxHeight: tableMaxHeightFull ? '100%' : tableMaxHeight,
          position: 'relative',
        }}
        className={tableClassName}
        ref={ref}
      >
        <div
          style={{
            minWidth: '100%',
          }}
        >
          {!withoutHeader &&
            (loadingHeader ? (
              customLoadingHeadRenderer || <BBBSpinner />
            ) : (
              <div className="flex border-b rounded-tl-2xl rounded-tr-2xl bg-neutral-20 items-center">
                {isDraggable && (
                  <div className="p-2 opacity-0 pointer-events-none">
                    <BBBDraggableIcon color="#DDDDDD" />
                  </div>
                )}
                {isSelectable && !!data?.length && (
                  <div className="p-2 flex items-center">
                    <BBBCheckbox
                      checked={allSelected || allRowSelectedManual}
                      onValueChange={(value) => {
                        if (allSelected) {
                          onChangeAllRowSelected?.(false);
                          setSelected([]);
                          onChangeSelectable?.([]);
                        } else {
                          const _data = !value ? [] : data ?? [];
                          setSelected(_data);
                          onChangeSelectable?.(!value ? [] : data ?? []);
                        }
                      }}
                    />
                  </div>
                )}
                {isSelectable && (allSelected ? true : selected.length) ? (
                  <>
                    {customSelectedHeader?.(
                      selected.map((_selected) => _selected[dataId])
                    ) || (
                      <p className="pl-1.5 text-base font-medium">
                        {selected.length} selected
                      </p>
                    )}
                  </>
                ) : (
                  headers.map((headerAttr, idx) => (
                    <div
                      key={
                        headerAttr.accesor
                          ? headerAttr.accesor
                          : `custom-header-${idx}`
                      }
                      className={cx(
                        'font-medium flex items-center gap-2 text-primary-main',
                        isColumnHeightFixed ? 'h-10 px-4' : 'h-auto p-2',
                        headerAttr.isSortable &&
                          'cursor-pointer pointer-events-auto hover:bg-[#EEEEEE]',
                        sortKey === headerAttr.accesor && 'bg-[#EEEEEE]',
                        headerAttr.isAdditionalColumn && 'bg-neutral-20',
                        headerAttr.headerClassName,
                        !(isSelectable && !!data?.length) &&
                          idx === 0 &&
                          'rounded-tl-2xl rounded-bl-none',
                        idx === headers.length - 1 &&
                          'rounded-tr-2xl rounded-br-none'
                      )}
                      style={getStyle(idx, headerAttr.columnSpan)}
                      onClick={() =>
                        headerAttr.isSortable && handleSort(headerAttr.accesor)
                      }
                    >
                      {headerAttr.renderHeader?.()}
                      {headerAttr.isSortable && (
                        <div className="flex flex-col">
                          <ChevronUp
                            width={12}
                            height={12}
                            className={`${
                              sortOrder === 'desc' &&
                              sortKey === headerAttr.accesor
                                ? 'text-primary-main'
                                : 'text-primary-main opacity-25'
                            }`}
                          />
                          <ChevronDown
                            width={12}
                            height={12}
                            className={`${
                              sortOrder === 'asc' &&
                              sortKey === headerAttr.accesor
                                ? 'text-primary-main'
                                : 'text-primary-main opacity-25'
                            }`}
                          />
                        </div>
                      )}
                      {headerAttr.isHasTooltip && (
                        <BBBTooltip
                          show={!!headerAttr.isHasTooltip}
                          offset={{
                            top: -6,
                          }}
                          content={headerAttr.isHasTooltip}
                        >
                          <Info size={16} />
                        </BBBTooltip>
                      )}
                    </div>
                  ))
                )}
              </div>
            ))}

          {loadingBody ? (
            customLoadingBodyRenderer || <BBBSpinner />
          ) : error ? (
            <BBBAlert
              type="danger"
              message="Error loading data"
              className="mt-3"
            />
          ) : !data?.length ? (
            typeof renderEmptyMessage !== 'undefined' &&
            typeof renderEmptyMessage() !== 'string' ? (
              renderEmptyMessage()
            ) : (
              <div className="flex px-5 items-center py-3 gap-2">
                <div className="text-neutral-40">{renderEmptyMessage()}</div>
              </div>
            )
          ) : (
            <motion.div
              initial={false}
              animate={{ height: renderCustomRow ? 'auto' : calculatedHeight }}
              transition={{ type: 'tween', duration: 0.3 }}
              id="table-body"
              ref={tableBodyRef}
            >
              {renderCustomRow?.()}
              {isDraggable ? (
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="table-body-droppable">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {sortedData?.map((_data, i) => {
                          const draggableId =
                            typeof _data[dataId] === 'string'
                              ? _data[dataId]
                              : _data[dataId].toString();
                          return (
                            <Draggable
                              draggableId={draggableId}
                              key={draggableId}
                              index={i}
                            >
                              {(providedDraggable) => (
                                <TableRow
                                  isDraggable={isDraggable}
                                  isSelectable={isSelectable}
                                  selected={selected}
                                  _data={_data}
                                  dataId={dataId}
                                  getStyle={getStyle}
                                  data={data}
                                  headers={headers}
                                  setSelected={setSelected}
                                  providedDraggable={providedDraggable}
                                  expandedRow={expandedRow}
                                  rowIdx={i}
                                  renderExpandedRow={renderExpandedRow}
                                  onClickRow={onClickRow}
                                  onChangeSelectable={onChangeSelectable}
                                  isSelectedControlled={isSelectedControlled}
                                  disabledRow={disabledRow}
                                  key={`${_data[dataId]}-${i}`}
                                  isColumnHeightFixed={isColumnHeightFixed}
                                  customSelectedHeader={customSelectedHeader}
                                  isAllRowSelected={allSelected}
                                  linkDestination={linkDestination}
                                  lastInList={i === sortedData.length - 1}
                                />
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <>
                  {sortedData?.map((_data, i) => (
                    <TableRow
                      isDraggable={isDraggable}
                      isSelectable={isSelectable}
                      selected={selected}
                      _data={_data}
                      dataId={dataId}
                      getStyle={getStyle}
                      data={data}
                      headers={headers}
                      setSelected={setSelected}
                      key={`${_data[dataId]}-${i}`}
                      expandedRow={expandedRow}
                      rowIdx={i}
                      renderExpandedRow={renderExpandedRow}
                      onClickRow={onClickRow}
                      onChangeSelectable={onChangeSelectable}
                      isSelectedControlled={isSelectedControlled}
                      disabledRow={disabledRow}
                      isAllRowSelected={allSelected}
                      linkDestination={linkDestination}
                      lastInList={i === sortedData.length - 1}
                    />
                  ))}
                </>
              )}
            </motion.div>
          )}
        </div>
      </div>
    ),
    [
      tableMaxHeightFull,
      tableMaxHeight,
      tableClassName,
      ref,
      loadingHeader,
      customLoadingHeadRenderer,
      isDraggable,
      isSelectable,
      data,
      allSelected,
      allRowSelectedManual,
      selected,
      customSelectedHeader,
      headers,
      loadingBody,
      customLoadingBodyRenderer,
      error,
      renderEmptyMessage,
      renderCustomRow,
      calculatedHeight,
      handleDragEnd,
      sortedData,
      onChangeAllRowSelected,
      onChangeSelectable,
      dataId,
      isColumnHeightFixed,
      sortKey,
      getStyle,
      sortOrder,
      handleSort,
      expandedRow,
      renderExpandedRow,
      onClickRow,
      isSelectedControlled,
      disabledRow,
      linkDestination,
      withoutHeader,
    ]
  );

  return (
    <div
      className={cx('bg-white rounded-2xl border-neutral-30 border', className)}
      {...containerProps}
    >
      {renderCustomHeader?.()}
      {(isSearchable || isFilterable || isSelectable) && (
        <div
          className={cx(
            'flex flex-col lg:flex-row lg:items-center gap-3 py-3 lg:px-2 px-3',
            headerClassName
          )}
        >
          {isSearchable && (
            <SearchInput
              placeholder={searchPlaceholder}
              value={searchValue}
              onValueChange={(value) => onChangeSearch?.(value)}
              containerClassName="!mb-0 grow"
            />
          )}
          {isFilterable && renderFilterSection?.()}
          {!withoutActionOption && isSelectable && !!selected.length && (
            <BBBSelect
              placeholder="Action"
              options={actionOptions}
              optionLabel="label"
              optionValue="value"
              onValueChange={(option) =>
                option?.value &&
                onActionChange?.(
                  option?.value,
                  selected.map((_selected) => _selected[dataId])
                )
              }
            />
          )}
        </div>
      )}

      {subHeaderComponent}

      {memoizedTable}

      {footer}

      {(isShowLimit || isPaginate) && (
        <>
          <div className="flex justify-between items-center px-3 my-2">
            {isShowLimit ? (
              <TablePagination
                optionNumbers={optionNumbers}
                limitValue={limitValue}
                onLimitChange={onLimitChange}
              />
            ) : (
              <div />
            )}
            {isPaginate && <BBBPagination {...pagination} />}
          </div>
        </>
      )}
    </div>
  );
};

BBBTableV2.displayName = 'BBBTableV2';

export default React.forwardRef(BBBTableV2) as <
  TData extends Record<string, any>
>(
  props: TableTypes<TData> & {
    ref?: Ref<HTMLDivElement>;
  }
) => React.ReactElement;

type TableRowProps<T extends Record<string, any>> = {
  selected: T[];
  _data: T;
  dataId: keyof T;
  getStyle: (idx: number, colSpan?: number | undefined) => CSSProperties;
  data: T[];
  headers: HeadersTypes<T>;
  setSelected: React.Dispatch<React.SetStateAction<T[]>>;
  providedDraggable?: DraggableProvided;
  isSelectable?: boolean;
  isDraggable?: boolean;
  expandedRow?: number[];
  rowIdx: number;
  renderExpandedRow?: (row: T) => React.ReactNode;
  onClickRow?: (row: T) => void;
  onChangeSelectable?: (row: T[]) => void;
  isSelectedControlled?: boolean;
  disabledRow?: (row: T) => boolean;
  isColumnHeightFixed?: boolean;
  customSelectedHeader?: (selected: T[]) => React.ReactNode;
  isAllRowSelected?: boolean;
  linkDestination?: (row: T) => void;
  lastInList?: boolean;
};

function TableRow<T extends Record<string, any>>({
  selected,
  _data,
  dataId,
  getStyle,
  data,
  headers,
  isSelectable,
  setSelected,
  isDraggable,
  providedDraggable,
  expandedRow,
  rowIdx,
  renderExpandedRow,
  onClickRow,
  onChangeSelectable,
  isSelectedControlled,
  disabledRow,
  isColumnHeightFixed,
  customSelectedHeader,
  isAllRowSelected,
  linkDestination,
  lastInList,
}: TableRowProps<T>) {
  const hasLinkDestination = typeof linkDestination?.(_data) !== 'undefined';
  const Tag = hasLinkDestination ? Link : 'div';

  return (
    <div
      ref={providedDraggable ? providedDraggable.innerRef : undefined}
      {...(providedDraggable ? providedDraggable.draggableProps : {})}
      id={`table-row`}
    >
      {/* @ts-ignore */}
      <Tag
        className={cx(
          'block border-b items-center text-neutral-60 transition-all duration-100 ease-linear',
          isColumnHeightFixed ? 'h-10' : 'h-auto',
          disabledRow?.(_data) && 'pointer-events-none',
          (hasLinkDestination || typeof onClickRow !== 'undefined') &&
            'cursor-pointer hover:bg-neutral-20',
          lastInList && 'border-none'
        )}
        onClick={() => !disabledRow?.(_data) && onClickRow?.(_data)}
        {...(typeof linkDestination !== 'undefined' && {
          to: linkDestination(_data),
        })}
      >
        <div className={cx('flex')}>
          {isDraggable && (
            <div
              className="p-2 flex items-center"
              {...(providedDraggable ? providedDraggable.dragHandleProps : {})}
            >
              <BBBDraggableIcon color="#DDDDDD" />
            </div>
          )}
          {isSelectable && (
            <div className="p-2 flex items-center">
              <BBBCheckbox
                checked={
                  isAllRowSelected
                    ? !selected.some(
                        (_selected) => _selected[dataId] === _data[dataId]
                      )
                    : selected.some(
                        (_selected) => _selected[dataId] === _data[dataId]
                      )
                }
                onValueChange={(checked) => {
                  const _selected = (isAllRowSelected ? checked : !checked)
                    ? selected.filter(
                        (prevData) => prevData[dataId] !== _data[dataId]
                      )
                    : [...selected, _data];

                  onChangeSelectable?.(
                    data.filter((rowData) =>
                      _selected.some(
                        (__selected) => __selected[dataId] === rowData[dataId]
                      )
                    )
                  );

                  if (!isSelectedControlled) {
                    setSelected(_selected);
                  }
                }}
              />
            </div>
          )}
          {headers.map((headerAttr, idx) => {
            const row = headerAttr.isAdditionalColumn
              ? headerAttr.render(_data)
              : headerAttr.render?.(
                  _data,
                  getDescendantProp(_data, headerAttr.accesor),
                  rowIdx,
                  data.map((__data) =>
                    getDescendantProp(_data, headerAttr.accesor)
                  )
                );
            return (
              <div
                className={cx(
                  isColumnHeightFixed ? 'py-2 px-4' : 'lg:p-2 p-3',
                  headerAttr.headerClassName
                )}
                style={getStyle(idx, headerAttr.columnSpan)}
                key={`${rowIdx}-${idx}`}
              >
                {headerAttr.isAdditionalColumn
                  ? row
                  : row || getDescendantProp(_data, headerAttr.accesor)}
              </div>
            );
          })}
        </div>
        {typeof renderExpandedRow !== 'undefined' &&
          (expandedRow?.includes(rowIdx) ? (
            <>{renderExpandedRow?.(_data)}</>
          ) : (
            <>{renderExpandedRow?.(_data)}</>
          ))}
      </Tag>
    </div>
  );
}

export function TablePagination({
  optionNumbers = [10, 25, 50, 100],
  limitValue,
  onLimitChange,
}: TableDropdownLimitType) {
  const mappedOptions = useMemo(
    () =>
      (optionNumbers ?? []).map((opt) => ({
        label: opt.toString(),
        value: opt,
      })),
    [optionNumbers]
  );

  return (
    <div className="flex gap-4 items-center">
      <p className="text-sm text-[#A6A6A6]">Show</p>
      <BBBSelect
        options={mappedOptions}
        value={mappedOptions.find((opt) => opt.value === limitValue)}
        onValueChange={(newValue) =>
          onLimitChange?.(
            mappedOptions.find((opt) => opt.value === newValue?.value)?.value ||
              undefined
          )
        }
        optionLabel="label"
        optionValue="value"
      />
      <p className="text-sm text-[#A6A6A6]">entries</p>
    </div>
  );
}
