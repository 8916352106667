import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { toast } from '@/utils/common/toast';

const useUpdateSortingModel = () => {
  const activeCompany = useActiveCompany();

  const client = useQueryClient();

  return useMutation(
    ({
      data: { collectionId, modelId },
    }: {
      data: { collectionId: string; modelId: string | null };
    }) =>
      services.bitAi.v2New.sorting.updateSortingModel(
        activeCompany,
        encodeURIComponent(collectionId),
        {
          modelId,
        }
      ),
    {
      onSuccess: (_, payload) => {
        if (payload.data.modelId) {
          toast.success(
            'Sorting request acknowledged. Please allow a few minutes for the changes to be reflected live on your store.'
          );
        } else {
          toast.success('Model updated');
        }

        client.invalidateQueries([
          `bitai-sorting-v2-collections`,
          activeCompany,
        ]);
        setTimeout(() => {
          client.invalidateQueries(['pricing-v2']);
          client.invalidateQueries(['pricing-feature-v2']);
          client.invalidateQueries(['pricing-features-v2']);
        }, 1000);
      },
    }
  );
};

export default useUpdateSortingModel;
