import { Draggable, Droppable } from 'react-beautiful-dnd';
import { twMerge as cx } from 'tailwind-merge';
import { BannerPlaceholder } from '../../components/LivePreview/Section/Banner';
import { CollectionPlaceholder } from '../../components/LivePreview/Section/Collections';
import { ImageGridPlaceholder } from '../../components/LivePreview/Section/ImageGrid';
import { SearchPlaceholder } from '../../components/LivePreview/Section/Search';

import { BBBCard } from '@/components/ui';
import { useAppSelector } from '@/hooks/rtk/store';

export const contentData = [
  {
    id: 'search',
    name: 'Search Bar',
    component: <SearchPlaceholder />,
    active: false,
  },
  {
    id: 'banners',
    name: 'Image slider',
    component: <BannerPlaceholder />,
    active: true,
  },
  {
    id: 'imagegrids',
    name: 'Circle image grid',
    component: <ImageGridPlaceholder />,
    active: true,
  },
  {
    id: 'collections',
    name: 'Collections',
    component: <CollectionPlaceholder />,
    active: true,
  },
];

function LayoutHomepage() {
  const draggingDroppable = useAppSelector(
    (state) => state.bitApp.draggingDroppable
  );
  const loadingPreview = useAppSelector((state) => state.bitApp.loadingPreview);

  return (
    <Droppable
      droppableId="layout-contents"
      isDropDisabled={draggingDroppable !== 'layout-contents'}
    >
      {(provided) => (
        <BBBCard
          ref={provided.innerRef}
          {...provided.droppableProps}
          title="Contents"
          desc={
            <>
              <strong className="font-medium">Drag & drop</strong> contents to
              the phone display on the right
            </>
          }
          id="design-layout"
        >
          {contentData.map(
            (content, idx: number) =>
              content.active && (
                <div className="flex flex-col gap-8 mt-8" key={content.id}>
                  <Draggable
                    index={idx}
                    key={content.id}
                    draggableId={content.id}
                    isDragDisabled={loadingPreview}
                  >
                    {(providedDraggable, snapshot) => (
                      <div
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                      >
                        <div className="flex items-center mt-4 mb-2.5 font-light  text-neutral-60 text-sm">
                          {content.name}
                        </div>
                        <div
                          className={cx(
                            'p-2.5 border-[0.5px] border-neutral-40 rounded-lg',
                            snapshot.isDragging &&
                              'border-2 border-secondary-main'
                          )}
                        >
                          {content.component}
                        </div>
                      </div>
                    )}
                  </Draggable>
                </div>
              )
          )}
          {provided.placeholder}
        </BBBCard>
      )}
    </Droppable>
  );
}

export default LayoutHomepage;
