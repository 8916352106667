import { useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';
import BeatLoader from 'react-spinners/BeatLoader';
import SearchSection from './Section/Search';
import Section from './Section';

import { BBBModal, BBBSpinner } from '@/components/ui';
import useAddSection from '@/hooks/bitApp/design/useAddSection';
import useSection from '@/hooks/bitApp/design/useSection';
import { useAppSelector } from '@/hooks/rtk/store';
import { contentData } from '@/pages/BitApp/Design/Layout/Homepage';
import { ModalState } from '@/stores/bitApp/types';

export default function Homepage() {
  const placeholderPosition = useAppSelector(
    (state) => state.bitApp.placeholderPosition
  );
  const draggingDroppable = useAppSelector(
    (state) => state.bitApp.draggingDroppable
  );

  const {
    isInitialLoading: loadingSection,
    data: dataSection,
    error: errorSection,
    fetchNextPage,
    hasNextPage,
  } = useSection();

  const [selectedMobile, setSelectedMobile] = useState<number>();
  const [showAddContentMobile, setShowAddContentMobile] = useState(false);

  const flattenedSection = dataSection?.pages.flatMap((page) => page.data);

  if (loadingSection) {
    return <BBBSpinner />;
  }

  if (errorSection) return null;

  return (
    <div className="relative h-full">
      <InfiniteScroll
        next={() => fetchNextPage()}
        hasMore={hasNextPage || false}
        scrollableTarget="mobile-content"
        loader={
          <div className="flex justify-center">
            <BeatLoader size={10} />
          </div>
        }
        dataLength={flattenedSection?.length || 0}
      >
        <Droppable
          droppableId="device-preview"
          isDropDisabled={
            !['layout-contents', 'device-preview'].includes(
              draggingDroppable as string
            )
          }
        >
          {(provided, snapshot) => (
            <>
              <SearchSection />
              <div
                ref={provided.innerRef}
                className="relative scrollbar-hide"
                {...provided.droppableProps}
              >
                {flattenedSection?.map((content, idx: number) => (
                  <Section
                    idx={idx}
                    id={content.id}
                    type={content.type}
                    data={
                      content.type === 'banners'
                        ? content.banners
                        : content.type === 'imagegrids'
                        ? content.imageGrids
                        : content.type === 'collections'
                        ? content.collections
                        : content.searches
                    }
                    selectedMobile={selectedMobile}
                    showAdd={showAddContentMobile}
                    onChangeShowAdd={setShowAddContentMobile}
                    onChangeSelect={setSelectedMobile}
                    key={content.id}
                  />
                ))}
                {provided.placeholder}
                {!snapshot.draggingFromThisWith && snapshot.draggingOverWith && (
                  <div
                    style={{
                      position: 'absolute',
                      top: (placeholderPosition?.clientY ?? 0) + 80,
                      left: 10,
                      right: 10,
                      width: '94%',
                    }}
                    className="py-3.5 flex items-center justify-center rounded-lg border border-dashed text-secondary-main bg-secondary-surface border-secondary-main text-ls text-center"
                  >
                    Drop contents here
                  </div>
                )}
              </div>
            </>
          )}
        </Droppable>
      </InfiniteScroll>

      {(flattenedSection?.length || 0) <= 1 && (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <div className="flex justify-center items-center flex-col">
            <div className="mb-6">
              <EmptyState />
            </div>
            <div className="text-[#9E9E9E] text-center">
              Start build your app by dragging contents here
            </div>
          </div>
        </div>
      )}

      {showAddContentMobile && (
        <AddContentMobileModal
          selectedMobile={flattenedSection?.findIndex(
            (content) => content.id === selectedMobile
          )}
          onHide={() => {
            setShowAddContentMobile(false);
            setSelectedMobile(undefined);
          }}
        />
      )}
    </div>
  );
}

type Props = {
  selectedMobile?: number;
  onHide?: () => void;
};

function AddContentMobileModal({ selectedMobile, onHide }: Props) {
  const { mutate: addSection } = useAddSection();

  return (
    <BBBModal onHide={onHide} title="Choose a content to be displayed" show>
      {contentData
        .filter((content) => content.active)
        .map((content) => (
          <div
            key={content.id}
            className="flex font-light border-b pb-4 last:pb-0 last:border-transparent mb-5 gap-3 items-center"
            onClick={() =>
              addSection(
                {
                  draggableId: content.id as keyof ModalState,
                  destination: selectedMobile! + 1,
                },
                {
                  onSuccess: onHide,
                }
              )
            }
          >
            <div className="flex-1">{content.component}</div>
            <div className="flex-1">{content.name}</div>
          </div>
        ))}
    </BBBModal>
  );
}

function EmptyState() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="250"
      height="209"
      fill="none"
      viewBox="0 0 250 209"
    >
      <g clipPath="url(#clip0_6217_39807)">
        <rect
          width="90"
          height="90"
          x="1"
          y="71"
          stroke="#DDD"
          strokeDasharray="8 8"
          strokeWidth="2"
          rx="3"
        ></rect>
        <rect
          width="90"
          height="90"
          x="14.186"
          y="66.145"
          fill="#fff"
          rx="3"
          transform="rotate(-12 14.186 66.145)"
        ></rect>
        <g
          stroke="#DDD"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          clipPath="url(#clip1_6217_39807)"
        >
          <path d="M49.177 94.49a6.667 6.667 0 015.135-7.908l19.563-4.158a6.667 6.667 0 017.907 5.135l4.158 19.563a6.666 6.666 0 01-5.135 7.907l-19.563 4.158a6.665 6.665 0 01-7.907-5.135l-4.158-19.563z"></path>
          <path d="M61.507 100.388a4.166 4.166 0 10-1.732-8.15 4.166 4.166 0 001.732 8.15zM71.892 100.942l-10.65 18.245 19.78-4.204a6.45 6.45 0 004.964-7.645l-.046-.216c-.161-.76-.508-.991-1.142-1.445l-8.092-5.768a3.333 3.333 0 00-4.814 1.033z"></path>
        </g>
        <rect
          width="90"
          height="90"
          x="14.186"
          y="66.145"
          stroke="#DDD"
          strokeWidth="2"
          rx="3"
          transform="rotate(-12 14.186 66.145)"
        ></rect>
        <path
          fill="#fff"
          d="M0 0H32V32H0z"
          transform="translate(95 116)"
        ></path>
        <path
          fill="#D4D4D4"
          d="M118.5 126.25a3.272 3.272 0 00-1.825.563 3.25 3.25 0 00-5.675-1.388 3.247 3.247 0 00-3.603-.98 3.245 3.245 0 00-2.147 3.055v2.25h-1.75a3.258 3.258 0 00-2.294.956 3.258 3.258 0 00-.956 2.294v2a10.75 10.75 0 0021.5 0v-5.5a3.258 3.258 0 00-.956-2.294 3.258 3.258 0 00-2.294-.956zm1.75 8.75a9.25 9.25 0 01-18.5 0v-2c0-.464.184-.909.513-1.237a1.746 1.746 0 011.237-.513h1.75v3.25c0 .199.079.39.22.53a.747.747 0 001.06 0 .747.747 0 00.22-.53v-7c0-.464.184-.909.513-1.237a1.746 1.746 0 012.474 0c.329.328.513.773.513 1.237v4c0 .199.079.39.22.53a.747.747 0 001.06 0 .747.747 0 00.22-.53v-4c0-.464.184-.909.513-1.237a1.746 1.746 0 012.474 0c.329.328.513.773.513 1.237v4c0 .199.079.39.22.53a.747.747 0 001.06 0 .747.747 0 00.22-.53v-2c0-.464.184-.909.513-1.237a1.746 1.746 0 012.474 0c.329.328.513.773.513 1.237v5.5z"
        ></path>
      </g>
      <rect width="109" height="207" x="140" y="1" fill="#fff" rx="7"></rect>
      <rect
        width="86"
        height="39"
        x="151.5"
        y="12.5"
        stroke="#DDD"
        rx="3.5"
      ></rect>
      <rect
        width="23"
        height="23"
        x="151.5"
        y="60.5"
        fill="#F8F8F8"
        rx="1.5"
      ></rect>
      <rect
        width="23"
        height="23"
        x="151.5"
        y="60.5"
        stroke="#DDD"
        rx="1.5"
      ></rect>
      <rect
        width="23"
        height="23"
        x="183"
        y="60.5"
        fill="#F8F8F8"
        rx="1.5"
      ></rect>
      <rect
        width="23"
        height="23"
        x="183"
        y="60.5"
        stroke="#DDD"
        rx="1.5"
      ></rect>
      <rect
        width="23"
        height="23"
        x="214.5"
        y="60.5"
        fill="#F8F8F8"
        rx="1.5"
      ></rect>
      <rect
        width="23"
        height="23"
        x="214.5"
        y="60.5"
        stroke="#DDD"
        rx="1.5"
      ></rect>
      <rect
        width="109"
        height="207"
        x="140"
        y="1"
        stroke="#DDD"
        strokeWidth="2"
        rx="7"
      ></rect>
      <defs>
        <clipPath id="clip0_6217_39807">
          <path
            fill="#fff"
            d="M0 0H122V115H0z"
            transform="translate(0 47)"
          ></path>
        </clipPath>
        <clipPath id="clip1_6217_39807">
          <path
            fill="#fff"
            d="M0 0H40V40H0z"
            transform="rotate(-12 428.188 -165.844)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}
