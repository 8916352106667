import { useEffect, useMemo, useState } from 'react';
import { Controller, FieldError } from 'react-hook-form';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { createId } from '@paralleldrive/cuid2';
import dayjs from 'dayjs';
import { EditorState } from 'draft-js';
import { pick } from 'lodash-es';
import { twMerge as cx } from 'tailwind-merge';
import * as yup from 'yup';
import { formatPhonePayload } from 'utils/common/phone';
import CampaignMessage from '../../components/CampaignMessage';
import Footer from './components/Footer';
import ScheduleMessage from './components/ScheduleMessage';
import SegmentOptions from './components/SegmentOptions';
import Ticket from './components/Ticket';
import useSegmentOptions from './hooks/useSegmentOptions';

import {
  BBBAlert,
  BBBCard,
  BBBFileUpload,
  BBBSpinner,
  BBBTelInput,
  BBBTelInputValue,
  BBBTextInput,
} from '@/components/ui';
import { Link } from '@/components/ui/Link';
import {
  EVERYONE_AGENT_ID,
  generalAiAgentOption,
} from '@/constants/bitChat/agent';
import { acceptedCrmFileTypes } from '@/constants/crm';
import { mapRoutesToSource } from '@/constants/whatsApp';
import {
  useWhatsappBusinessIntegration,
  useWhatsappCloudIntegration,
} from '@/hooks/bitChat/integration/integrations';
import useCampaignDetail from '@/hooks/bitCRM/campaign/useCampaignDetail';
import useSendCampaign, {
  SendCampaignPayload,
} from '@/hooks/bitCRM/campaign/useSendCampaign';
import useCustomForm from '@/hooks/common/useCustomForm';
import useDefaultCountryCode from '@/hooks/common/useDefaultCountryCode';
import useResponsive from '@/hooks/common/useResponsive';
import { useSegmentByName } from '@/hooks/customers/segment';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import { AgentOption } from '@/pages/BitChat/components/AgentOptions';
import ReviewChecklist from '@/pages/BitCRM/Campaign/components/ReviewChecklist';
import TemplateOptions from '@/pages/BitCRM/Campaign/Whatsapp/components/TemplateOptions';
import { CTAButtonType } from '@/pages/BitCRM/components/CTA';
import CampaignWrapper from '@/pages/BitCRM/components/Wrapper';
import { ChannelType } from '@/types/bitCRM/base';
import { Campaign, CampaignTemplatePayload } from '@/types/bitCRM/campaign';
import {
  CampaignTemplate,
  TemplateBodyParameters,
} from '@/types/bitCRM/template';
import { FileType } from '@/types/utils/file';
import { Nullable } from '@/types/utils/nullable';
import { SettingsTag } from '@/types/whatsApp/settings';
import { convertRemoteUrlToFileType } from '@/utils/bitCRM';
import { phoneValidator } from '@/utils/common/phone';
import {
  convertEditorStateToHtml,
  convertHtmlToEditorState,
} from '@/utils/common/rich';
import { richValidation } from '@/utils/common/validation';

export type FormSchema = {
  id?: string;
  campaignName: string;
  template?: CampaignTemplate | CampaignTemplatePayload | null;
  isScheduled: boolean;
  customerSegment:
    | { label: string; value: string; count: number }
    | null
    | undefined;
  buttons?: CTAButtonType[];
  isDraft?: boolean;
  date?: {
    selectedDay: Date | null;
    selectedTime?: string | null;
  };
  param?: (TemplateBodyParameters | undefined)[];
  phoneReceiver: BBBTelInputValue | null;
  campaignSchedule2: string | null;
  convertToTicket?: boolean;
  ticketCategory?: Partial<
    SettingsTag | Pick<SettingsTag, 'id' | 'label'> | null
  >;
  agent?:
    | AgentOption
    | (Pick<AgentOption, 'formattedName'> & {
        user: Pick<AgentOption['user'], 'id'>;
      })
    | null;
  ignoreAutoResolve?: boolean;
  body: {
    message: EditorState;
    parameters: TemplateBodyParameters[];
  };
  fileUrl?: Nullable<FileType>;
};

const defaultFormSchema: FormSchema = {
  id: undefined,
  campaignName: '',
  isScheduled: false,
  customerSegment: null,
  template: null,
  buttons: [
    {
      ctaId: createId(),
      label: '',
      value: '',
    },
  ],
  date: {
    selectedDay: dayjs().add(1, 'days').toDate(),
    selectedTime: dayjs().format('HH:mm'),
  },
  phoneReceiver: null,
  campaignSchedule2: dayjs()
    .add(1, 'day')
    .set('hour', 10)
    .set('minute', 0)
    .toISOString(),
  convertToTicket: false,
  agent: {
    user: {
      id: EVERYONE_AGENT_ID,
    },
    formattedName: 'Everyone',
  },
  ticketCategory: {
    id: undefined,
    label: 'Regular ticket',
    color: 'neutral',
  },
  ignoreAutoResolve: false,
  body: {
    message: EditorState.createEmpty(),
    parameters: [],
  },
};

const formatFormToPayload = (
  data: FormSchema,
  isSandbox: boolean,
  type: 'whatsapp-business' | 'whatsapp-cloud'
): SendCampaignPayload => {
  const template = pick(data.template!, [
    'id',
    'templateName',
    'params',
    'message',
  ]);

  return {
    type: (isSandbox
      ? 'SANDBOX_WHATSAPP'
      : mapRoutesToSource[type]) as ChannelType,
    id: data?.id,
    message:
      type === 'whatsapp-business'
        ? convertEditorStateToHtml(data.body.message)
        : undefined,
    fileUrl:
      type === 'whatsapp-business'
        ? data.fileUrl?.remoteUrl || null
        : undefined,
    campaignName: data.campaignName,
    templateOfficial:
      type === 'whatsapp-cloud'
        ? {
            templateName: data.template!.templateName,
            params: data.param?.map((param) => param!.value) || [],
            id: data.template!.id,
          }
        : null,
    params: data.param as TemplateBodyParameters[],
    templateOfficial2: type === 'whatsapp-cloud' ? template : null,
    customerSegment: data.customerSegment?.value,
    campaignSchedule2: data.isScheduled ? data.campaignSchedule2 : undefined,
    phoneReceiver: formatPhonePayload(data.phoneReceiver),
    customerTotal: data.customerSegment?.count,
    convertToTicket: data.convertToTicket,
    agentUserDisplayName:
      data.agent?.formattedName &&
      // @ts-ignore
      data.agent.formattedName === generalAiAgentOption.formattedName
        ? 'AI Agent'
        : data.agent?.formattedName,
    agentUserId: data.agent?.user.id,
    ticketCategoryId: data.ticketCategory?.id,
    ticketCategoryLabel: data.ticketCategory?.label,
    ignoreAutoResolve: data.ignoreAutoResolve,
  };
};

export type CampaignScheduleTypes = {
  scheduled: boolean;
  date?: string;
  time?: string;
  timezone?: string;
};

const paramSchema = {
  value: yup.string().required('Param is required'),
};

const getTemplateSchema = (source: 'whatsapp-cloud' | 'whatsapp-business') => {
  const base = yup.mixed<CampaignTemplate>();

  if (source === 'whatsapp-cloud') {
    return base.required('Template cannot be empty');
  }

  return base;
};

const getBodySchema = (source: 'whatsapp-cloud' | 'whatsapp-business') => {
  if (source === 'whatsapp-business') {
    return yup.object().shape({
      message: richValidation,
    });
  }
};

const schema = {
  id: yup.string(),
  campaignName: yup.string().required('This field cannot be empty'),
  customerSegment: yup
    .mixed<typeof defaultFormSchema['customerSegment']>()
    .required('Customer segment cannot be empty'),
  isScheduled: yup.bool().required(),
  date: yup.mixed<string>().when('isScheduled', {
    is: (val: boolean) => !!val,
    then: (rule) => rule.required(),
  }),
  param: yup.array().of(yup.object().shape(paramSchema)),
  phoneReceiver: phoneValidator(true).when('customerSegment', {
    is: (opt: FormSchema['customerSegment']) => opt?.value === 'demo',
    then: () => phoneValidator(),
  }),
  convertToTicket: yup.bool(),
  ticketCategory: yup
    .mixed<SettingsTag>()
    .label('Ticket category')
    .when('convertToTicket', {
      is: (val: boolean) => val,
      then: yup.mixed<SettingsTag>().required('Ticket category is required'),
    }),
  agent: yup
    .mixed<AgentOption>()
    .label('Agent')
    .when('convertToTicket', {
      is: (val: boolean) => val,
      then: yup.mixed<AgentOption>().required('Agent is required'),
    }),
  ignoreAutoResolve: yup.bool(),
};

export type Props = {
  channel: 'whatsapp-business' | 'whatsapp-cloud';
};

export default function CampaignWhatsApp(
  props: Props & {
    isSandbox?: boolean;
  }
) {
  return <CampaignWhatsAppDefault {...props} />;
}

function CampaignWhatsAppDefault({
  channel,
  isSandbox,
}: Props & {
  isSandbox?: boolean;
}) {
  const { data: pricingData, status } = usePricingByApp('BITCRM');

  if (status === 'loading') {
    return <BBBSpinner text="Loading campaign" />;
  }

  if (status === 'error') {
    return null;
  }

  if (pricingData?.pricingName === 'free' && channel !== 'whatsapp-business') {
    return <Redirect to={'/bitcrm/campaign'} />;
  }

  return (
    <CampaignWhatsAppCheckConnection channel={channel} isSandbox={isSandbox} />
  );
}

function CampaignWhatsAppCheckConnection(
  props: Props & {
    isSandbox?: boolean;
  }
) {
  const { id } = useParams<{ id: string }>();

  const enableWaBusinessQuery =
    id === 'new' && props.channel === 'whatsapp-business' && !props.isSandbox;
  const enableWaCloudQuery = id === 'new' && props.channel === 'whatsapp-cloud';

  const waBusinessQuery = useWhatsappBusinessIntegration({
    enabled: enableWaBusinessQuery,
  });

  const waCloudQuery = useWhatsappCloudIntegration({
    enabled: enableWaCloudQuery,
  });

  if (enableWaBusinessQuery) {
    if (waBusinessQuery.status !== 'success') {
      return <BBBSpinner />;
    }

    if (waBusinessQuery.data?.status !== 'CONNECTED') {
      return <Redirect to={'/bitcrm/campaign'} />;
    }
  }

  if (enableWaCloudQuery) {
    if (waCloudQuery.status !== 'success') {
      return <BBBSpinner />;
    }

    if (waCloudQuery.data?.status !== 'CONNECTED') {
      return <Redirect to={'/bitcrm/campaign'} />;
    }
  }

  return <_CampaignWhatsAppCheckStatus {...props} />;
}

function _CampaignWhatsAppCheckStatus({
  channel,
  isSandbox,
}: Props & {
  isSandbox?: boolean;
}) {
  const { id } = useParams<{ id: string }>();

  const {
    data,
    status,
    isInitialLoading: loading,
  } = useCampaignDetail(id, {
    enabled: id !== 'new',
  });

  if (loading) return <BBBSpinner />;

  if (
    status === 'success' &&
    !(data.status === 'SCHEDULED' || data.status === 'DRAFT')
  ) {
    if (data.status === 'SENT') {
      return <Redirect to={`${window.location.pathname}/log`} />;
    }
    return <Redirect to="/bitcrm/campaign" />;
  }

  return (
    <_CampaignWhatsApp channel={channel} data={data} isSandbox={isSandbox} />
  );
}

function _CampaignWhatsApp({
  channel,
  data,
  isSandbox,
}: Props & {
  data?: Campaign | undefined;
  isSandbox?: boolean;
}) {
  const { state } = useLocation<
    | {
        data: Pick<
          FormSchema,
          | 'campaignName'
          | 'isScheduled'
          | 'customerSegment'
          | 'date'
          | 'campaignSchedule2'
        >;
      }
    | undefined
  >();

  const official = channel !== 'whatsapp-business';

  const isMobile = useResponsive('sm');
  const [showReviewModal, setShowReviewModal] = useState(false);

  const phoneDefaultValues = useDefaultCountryCode();

  const { handleSubmit, formState, control, setValue, watch, reset } =
    useCustomForm<FormSchema>({
      resolver: yupResolver(
        yup.object().shape({
          ...schema,
          template: getTemplateSchema(channel),
          ...(channel === 'whatsapp-business' && {
            body: getBodySchema(channel),
          }),
        })
      ),
      defaultValues: defaultFormSchema,
    });

  const {
    mutate: sendCampaign,
    isLoading: loadingSave,
    status,
  } = useSendCampaign();

  const { data: segmentData } = useSegmentByName(data?.customerSegment, {
    enabled: !isSandbox,
  });
  const { data: customerSegmentsWithAll } = useSegmentOptions();

  function _handleSave(
    data: FormSchema,
    isDraft: boolean,
    leaveCb?: () => void
  ) {
    const payload = formatFormToPayload(data, !!isSandbox, channel);

    sendCampaign({
      ...payload,
      isTest: false,
      isDraft: isDraft,
      leaveCb,
    });
  }

  const _handleSubmit = () => {
    handleSubmit(() => {
      setShowReviewModal(true);
    })();
  };

  const handleSubmitDraft = (leaveCb?: () => void) => {
    _handleSave(watch(), true, leaveCb);
  };

  const dataFromApi = useMemo<FormSchema>(() => {
    const template =
      (official ? data?.templateOfficial2 : data?.waTemplate) || null;

    return {
      id: data?.id,
      campaignName: data?.campaignName || '',
      isScheduled: !!data?.scheduledAt,
      isDraft: data?.isDraft || false,
      customerSegment: segmentData
        ? {
            label: `${segmentData.data.name} - ${segmentData.count}`,
            value: segmentData.data.name,
            count: segmentData.count,
          }
        : customerSegmentsWithAll[0],
      template,
      param: data?.params || [],
      date: {
        selectedDay: data?.campaignSchedule?.date
          ? new Date(data?.campaignSchedule?.date)
          : defaultFormSchema.date?.selectedDay || null,
        selectedTime:
          data?.campaignSchedule?.time || defaultFormSchema.date?.selectedTime,
      },
      phoneReceiver: phoneDefaultValues || null,
      campaignSchedule2:
        data?.campaignSchedule2 || defaultFormSchema.campaignSchedule2,
      convertToTicket: data?.convertToTicket || false,
      ticketCategory:
        data?.ticketCategoryId && data.ticketCategoryLabel
          ? {
              id: data.ticketCategoryId,
              label: data.ticketCategoryLabel,
            }
          : null,
      agent: data?.agentId
        ? {
            user: {
              id: data.agentId,
            },
            formattedName: data.agentUserDisplayName || '',
          }
        : null,
      ignoreAutoResolve: data?.ignoreAutoResolve || false,
      body: {
        message: data?.message
          ? convertHtmlToEditorState(data?.message)
          : defaultFormSchema.body.message,
        parameters: [],
      },
      fileUrl: data?.fileUrl ? convertRemoteUrlToFileType(data?.fileUrl) : null,
    };
  }, [
    data?.fileUrl,
    data?.waTemplate,
    official,
    data?.params,
    customerSegmentsWithAll,
    data?.agentId,
    data?.campaignName,
    data?.campaignSchedule?.date,
    data?.campaignSchedule?.time,
    data?.campaignSchedule2,
    data?.convertToTicket,
    data?.id,
    data?.agentUserDisplayName,
    data?.ignoreAutoResolve,
    data?.isDraft,
    data?.scheduledAt,
    data?.ticketCategoryId,
    data?.ticketCategoryLabel,
    phoneDefaultValues,
    segmentData,
    data?.templateOfficial2,
    data?.message,
  ]);

  useEffect(() => {
    if (state?.data) {
      reset({
        campaignName: state.data.campaignName,
        customerSegment: state.data.customerSegment,
        isScheduled: state.data.isScheduled,
        date: state.data.date,
      });
    }
  }, [reset, state?.data]);

  return (
    <CampaignWrapper
      control={control}
      type="campaign-wa-reply"
      source={channel}
      title={`WhatsApp ${
        channel === 'whatsapp-business'
          ? 'Business App'
          : channel === 'whatsapp-cloud'
          ? 'Cloud API'
          : 'Official API'
      } campaign`}
      description="Create WhatsApp message blast to your customers"
    >
      {channel === 'whatsapp-business' && (
        <BBBAlert
          message={
            <>
              Sending large number of messages may be considered spam with
              potential risk of being banned by meta. For large volume, please
              use{' '}
              <Link
                className="font-semibold underline"
                to={{
                  pathname: '/integrations',
                }}
              >
                WhatsApp API.
              </Link>
            </>
          }
          type="secondary"
          className="leading-6 mb-5"
        />
      )}
      {showReviewModal && (
        <ReviewChecklist
          showReviewModal={showReviewModal}
          setShowReviewModal={setShowReviewModal}
          tempFinal={watch()}
          onConfirm={() => {
            handleSubmit((data) => _handleSave(data, false))();
          }}
          loadingSave={loadingSave}
          type="wa"
          source={channel}
        />
      )}

      <BBBCard
        id="campaign-setup"
        title="Setup"
        desc="Setup your campaign. You can choose the customer segment that you want to send."
        className={cx(isMobile && 'border-none rounded-none shadow-none')}
      >
        <BBBTextInput
          isHookForm
          control={control}
          controlName="campaignName"
          error={formState.errors.campaignName?.message}
          label="Campaign name"
          containerClassname="mb-5"
          placeholder="Enter campaign name"
        />

        <Controller
          name="customerSegment"
          control={control}
          render={({ field: { value, onChange } }) => (
            <SegmentOptions
              value={value}
              onChange={onChange}
              error={(formState.errors.customerSegment as FieldError)?.message}
            />
          )}
        />

        {watch('customerSegment')?.value === 'demo' && (
          <Controller
            name="phoneReceiver"
            control={control}
            render={({ field }) => (
              <BBBTelInput
                label="Input your phone number"
                containerClassname="mb-6"
                value={field.value || undefined}
                onChange={field.onChange}
                error={formState.errors.phoneReceiver?.message}
              />
            )}
          />
        )}

        <ScheduleMessage control={control} />
        {channel === 'whatsapp-cloud' && (
          <TemplateOptions control={control} setValue={setValue} />
        )}
        {channel === 'whatsapp-business' && (
          <>
            <Controller
              control={control}
              name="body"
              render={({ field }) => (
                <CampaignMessage
                  editorState={field.value.message}
                  onChangeEditorState={(message) => {
                    field.onChange({ ...field.value, message });
                  }}
                  source="whatsapp-business"
                  params={field.value.parameters}
                  onChangeParams={(parameters) => {
                    field.onChange({ ...field.value, parameters });
                  }}
                  onChange={({ params, editorState }) => {
                    field.onChange({
                      ...field.value,
                      parameters: params,
                      message: editorState,
                    });
                  }}
                  //@ts-ignore
                  error={formState.errors.body?.message?.message}
                  type={'campaign'}
                  //@ts-ignore
                  paramErrors={formState.errors.body?.parameters?.map(
                    (param) => param?.value?.message || ''
                  )}
                />
              )}
            />
            <Controller
              name="fileUrl"
              control={control}
              render={({ field }) => (
                <BBBFileUpload
                  isSingle
                  files={field.value}
                  onChangeFile={field.onChange}
                  accept={acceptedCrmFileTypes}
                  withLabel
                  containerClassName="my-5"
                />
              )}
            />
          </>
        )}
        <Ticket control={control} />
      </BBBCard>
      <Footer
        control={control}
        dataFromApi={dataFromApi}
        onSubmit={_handleSubmit}
        onSubmitDraft={handleSubmitDraft}
        status={status}
      />
    </CampaignWrapper>
  );
}
