import { ReactNode } from 'react';
import { Check, X } from 'react-feather';
import { Switch } from '@headlessui/react';
import { twMerge as cx } from 'tailwind-merge';
import { BBBTooltip } from '../BBBTooltip';

import { cn } from '@/utils/styles';

export type BBBPrimarySwitchProps = {
  key?: string;
  checked?: boolean;
  onChange?: (value: boolean | undefined) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean;
  containerClassName?: string;
  label?: ReactNode;
  description?: string;
  descriptionClassName?: string;
  tooltip?: ReactNode;
} & Omit<JSX.IntrinsicElements['div'], 'onChange'>;

function BBBPrimarySwitch({
  checked = false,
  onChange = () => ({}),
  onClick,
  disabled = false,
  key,
  label,
  description,
  containerClassName,
  tooltip,
  descriptionClassName,
  ...props
}: BBBPrimarySwitchProps) {
  const SwitchComponent = () => (
    <Switch
      key={key}
      checked={checked}
      onChange={onChange}
      className={cx(
        checked
          ? 'bg-success-main border-transparent'
          : 'bg-white border-gray-300',
        disabled ? 'opacity-50' : '',
        'relative inline-flex items-center border rounded-[4px] h-7 w-14 flex-none'
      )}
      disabled={disabled}
    >
      {checked && (
        <Check className="pointer-events-none absolute text-white m-1 h-5 w-5" />
      )}
      <span
        className={`${
          checked
            ? 'translate-x-[1.9em] shadow-[0_0px_4px_#78D54E] border-transparent'
            : '-translate-x-[3px] shadow-[0_0px_4px_#00000029] border-gray-300'
        } inline-block w-7 h-7 border  transform rounded-[4px] bg-white transition-all duration-100`}
        id="switch-toggle"
      />
      {!checked && (
        <X className="pointer-events-none absolute left-1/2 text-[#939393] m-1 h-5 w-5" />
      )}
    </Switch>
  );

  return (
    <div
      className={cx('flex gap-4 items-center', containerClassName)}
      onClick={onClick}
      {...props}
    >
      {tooltip ? (
        <BBBTooltip show content={tooltip}>
          <SwitchComponent />
        </BBBTooltip>
      ) : (
        <SwitchComponent />
      )}

      {(label || description) && (
        <div>
          <div className="text-sm text-primary-main">{label}</div>
          <div className={cn('text-xs text-sub-heading', descriptionClassName)}>
            {description}
          </div>
        </div>
      )}
    </div>
  );
}

export default BBBPrimarySwitch;
