import { Edit3, PlusCircle, Trash2 } from 'react-feather';
import { Link } from 'react-router-dom';

import { BBBCard, BBBTooltip } from '@/components/ui';
import BBBBadge from '@/components/ui/BBBBadge/BBBBadge';
import useDeleteWACloudTemplate from '@/hooks/bitChat/settings/useDeleteWACloudTemplate';
import useWACloudTemplates from '@/hooks/bitChat/settings/useWACloudTemplates';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import { Accent } from '@/types/utils/color';
import { WACloudTemplate } from '@/types/whatsApp/settings';

export const mapStatusToBadgeProps: Record<
  WACloudTemplate['status'],
  { text: string; type: Accent }
> = {
  DRAFT: { text: 'Draft', type: 'neutral' },
  APPROVED: { text: 'Approved', type: 'success' },
  REJECTED: { text: 'Rejected', type: 'danger' },
  WAITING: { text: 'Waiting', type: 'info' },
  ERROR: { text: 'Error', type: 'danger' },
  PREPARING: { text: 'Preparing', type: 'info' },
};

export default function ChatInitiationTemplate() {
  const { data } = useWACloudTemplates();
  const { mutateAsync: deleteWACloud } = useDeleteWACloudTemplate();

  const confirm = useConfirmationModal();

  return (
    <BBBCard
      title="Chat initiation template"
      desc="Create message template to initiate conversation with your customer using WhatsApp"
    >
      {!data?.length ? (
        <div className="mb-6 text-neutral-50">
          You don’t have any template yet, create one!
        </div>
      ) : (
        data.map((template, idx) => (
          <div className="flex items-center gap-6 mb-6" key={idx}>
            <div className="grow">
              <Link
                to={`/bitchat/settings/wa-cloud-template/${template.id}`}
                className="text-primary-main mb-2 flex items-center gap-1 cursor-pointer group hover:underline transition-all"
              >
                {template.name}{' '}
                <span className="text-[#535353] opacity-0 group-hover:opacity-100 transition-opacity">
                  <Edit3 size={10} />
                </span>
              </Link>
              <div
                className="text-neutral-50 text-sm line-clamp-1"
                dangerouslySetInnerHTML={{
                  __html: template.message,
                }}
              />
            </div>
            <div className="flex-none">
              {template.status === 'WAITING' ? (
                <BBBTooltip
                  content="Usually take 1-3 days to approved"
                  show
                  offset={{ left: 30, top: 10 }}
                  tooltipClassName="w-auto"
                >
                  <BBBBadge {...mapStatusToBadgeProps[template.status]} />
                </BBBTooltip>
              ) : (
                <BBBBadge {...mapStatusToBadgeProps[template.status]} />
              )}
            </div>
            <Trash2
              className="flex-none text-neutral-30 hover:text-danger-main transition-colors cursor-pointer"
              onClick={() => {
                confirm({
                  title: 'Delete template',
                  description:
                    'Are you sure want to delete this template? Once you deleted the action cannot be undone.',
                  onAccept: (hide) => {
                    deleteWACloud(template.id);
                    hide();
                  },
                  submitText: 'Delete',
                  deleteModal: true,
                });
              }}
            />
          </div>
        ))
      )}
      <Link
        to={'/misc/templates/new?section=chat'}
        className="flex items-center text-neutral-40 gap-2"
      >
        <PlusCircle />
        Create new template
      </Link>
    </BBBCard>
  );
}
