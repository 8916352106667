import ProfileInformation from './ProfileInformation';

import { BBBContainer } from '@/components/ui';
import { useWhatsappCloudIntegration } from '@/hooks/bitChat/integration/integrations';
import ConnectWaCloudBanner from '@/pages/Misc/components/ConnectWaCloudBanner';
import { cn } from '@/utils/styles';

export default function WhatsappApiProfile() {
  const { data: whatsAppCloudApiIntegrationData } =
    useWhatsappCloudIntegration();

  const isWhatsAppCloudConnected =
    whatsAppCloudApiIntegrationData?.status === 'CONNECTED';

  return (
    <BBBContainer
      pageTitle={`WhatsApp API Profile`}
      className="md:px-0 md:py-0 lg:max-w-none"
      {...{
        hasHeading: true,
        pageDescription: 'Set up your WhatsApp API profile settings',
      }}
    >
      <ConnectWaCloudBanner />
      <div
        className={cn(
          'flex flex-col gap-5',
          !isWhatsAppCloudConnected
            ? 'opacity-50 pointer-events-none cursor-none'
            : 'opacity-100 pointer-events-auto'
        )}
      >
        <ProfileInformation />
      </div>
    </BBBContainer>
  );
}
