import BottomNavigation from '../components/LivePreview/BottomNavigation';
import NavigationCard from '../components/NavigationCard';
import Journey from '../Journey';

import useResponsive from '@/hooks/common/useResponsive';

export default function DesignNavigation() {
  const isTablet = useResponsive('lg');

  return (
    <>
      {isTablet ? (
        <div className="flex flex-col h-full">
          <div className="h-1/3 flex-none border-b relative">
            <div className="absolute inset-0 overflow-auto px-8 pb-4">
              <div className="rounded-bl-[2em] flex justify-end flex-col rounded-br-[2em] border-t-transparent border-bl-3xl border-br-3xl border-black border-4 overflow-hidden h-full relative">
                <BottomNavigation className="border-t" />
              </div>
            </div>
          </div>
          <div className="h-2/3 flex-none relative">
            <div className="absolute inset-0 overflow-auto">
              <NavigationCard className="border-none" />
            </div>
          </div>
        </div>
      ) : (
        <NavigationCard />
      )}
      <Journey />
    </>
  );
}
