import { SidebarIcon } from '@/constants/layouts/sidebarData';

function MiscIcon({ mode = 'default', ...props }: SidebarIcon) {
  return (
    <>
      {mode === 'default' || mode === 'hover' ? (
        <MiscIconDefault {...props} />
      ) : (
        <MiscIconActive {...props} />
      )}
    </>
  );
}

function MiscIconDefault({ size = 25 }: SidebarIcon) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.49967 4.58325H4.99967C4.76956 4.58325 4.58301 4.7698 4.58301 4.99992V7.49992C4.58301 7.73004 4.76956 7.91658 4.99967 7.91658H7.49967C7.72979 7.91658 7.91634 7.73004 7.91634 7.49992V4.99992C7.91634 4.7698 7.72979 4.58325 7.49967 4.58325ZM4.99967 3.33325C4.0792 3.33325 3.33301 4.07944 3.33301 4.99992V7.49992C3.33301 8.42039 4.0792 9.16658 4.99967 9.16658H7.49967C8.42015 9.16658 9.16634 8.42039 9.16634 7.49992V4.99992C9.16634 4.07944 8.42015 3.33325 7.49967 3.33325H4.99967Z"
        fill="#727272"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9997 4.58325H12.4997C12.2696 4.58325 12.083 4.7698 12.083 4.99992V7.49992C12.083 7.73004 12.2696 7.91658 12.4997 7.91658H14.9997C15.2298 7.91658 15.4163 7.73004 15.4163 7.49992V4.99992C15.4163 4.7698 15.2298 4.58325 14.9997 4.58325ZM12.4997 3.33325C11.5792 3.33325 10.833 4.07944 10.833 4.99992V7.49992C10.833 8.42039 11.5792 9.16658 12.4997 9.16658H14.9997C15.9201 9.16658 16.6663 8.42039 16.6663 7.49992V4.99992C16.6663 4.07944 15.9201 3.33325 14.9997 3.33325H12.4997Z"
        fill="#727272"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9997 12.0833H12.4997C12.2696 12.0833 12.083 12.2698 12.083 12.4999V14.9999C12.083 15.23 12.2696 15.4166 12.4997 15.4166H14.9997C15.2298 15.4166 15.4163 15.23 15.4163 14.9999V12.4999C15.4163 12.2698 15.2298 12.0833 14.9997 12.0833ZM12.4997 10.8333C11.5792 10.8333 10.833 11.5794 10.833 12.4999V14.9999C10.833 15.9204 11.5792 16.6666 12.4997 16.6666H14.9997C15.9201 16.6666 16.6663 15.9204 16.6663 14.9999V12.4999C16.6663 11.5794 15.9201 10.8333 14.9997 10.8333H12.4997Z"
        fill="#727272"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.49967 12.0833H4.99967C4.76956 12.0833 4.58301 12.2698 4.58301 12.4999V14.9999C4.58301 15.23 4.76956 15.4166 4.99967 15.4166H7.49967C7.72979 15.4166 7.91634 15.23 7.91634 14.9999V12.4999C7.91634 12.2698 7.72979 12.0833 7.49967 12.0833ZM4.99967 10.8333C4.0792 10.8333 3.33301 11.5794 3.33301 12.4999V14.9999C3.33301 15.9204 4.0792 16.6666 4.99967 16.6666H7.49967C8.42015 16.6666 9.16634 15.9204 9.16634 14.9999V12.4999C9.16634 11.5794 8.42015 10.8333 7.49967 10.8333H4.99967Z"
        fill="#727272"
      />
    </svg>
  );
}

function MiscIconActive({ size = 20 }: SidebarIcon) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.49967 4.58325H4.99967C4.76956 4.58325 4.58301 4.7698 4.58301 4.99992V7.49992C4.58301 7.73004 4.76956 7.91658 4.99967 7.91658H7.49967C7.72979 7.91658 7.91634 7.73004 7.91634 7.49992V4.99992C7.91634 4.7698 7.72979 4.58325 7.49967 4.58325ZM4.99967 3.33325C4.0792 3.33325 3.33301 4.07944 3.33301 4.99992V7.49992C3.33301 8.42039 4.0792 9.16658 4.99967 9.16658H7.49967C8.42015 9.16658 9.16634 8.42039 9.16634 7.49992V4.99992C9.16634 4.07944 8.42015 3.33325 7.49967 3.33325H4.99967Z"
        fill="#FF7C11"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9997 4.58325H12.4997C12.2696 4.58325 12.083 4.7698 12.083 4.99992V7.49992C12.083 7.73004 12.2696 7.91658 12.4997 7.91658H14.9997C15.2298 7.91658 15.4163 7.73004 15.4163 7.49992V4.99992C15.4163 4.7698 15.2298 4.58325 14.9997 4.58325ZM12.4997 3.33325C11.5792 3.33325 10.833 4.07944 10.833 4.99992V7.49992C10.833 8.42039 11.5792 9.16658 12.4997 9.16658H14.9997C15.9201 9.16658 16.6663 8.42039 16.6663 7.49992V4.99992C16.6663 4.07944 15.9201 3.33325 14.9997 3.33325H12.4997Z"
        fill="#FF7C11"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9997 12.0833H12.4997C12.2696 12.0833 12.083 12.2698 12.083 12.4999V14.9999C12.083 15.23 12.2696 15.4166 12.4997 15.4166H14.9997C15.2298 15.4166 15.4163 15.23 15.4163 14.9999V12.4999C15.4163 12.2698 15.2298 12.0833 14.9997 12.0833ZM12.4997 10.8333C11.5792 10.8333 10.833 11.5794 10.833 12.4999V14.9999C10.833 15.9204 11.5792 16.6666 12.4997 16.6666H14.9997C15.9201 16.6666 16.6663 15.9204 16.6663 14.9999V12.4999C16.6663 11.5794 15.9201 10.8333 14.9997 10.8333H12.4997Z"
        fill="#FF7C11"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.49967 12.0833H4.99967C4.76956 12.0833 4.58301 12.2698 4.58301 12.4999V14.9999C4.58301 15.23 4.76956 15.4166 4.99967 15.4166H7.49967C7.72979 15.4166 7.91634 15.23 7.91634 14.9999V12.4999C7.91634 12.2698 7.72979 12.0833 7.49967 12.0833ZM4.99967 10.8333C4.0792 10.8333 3.33301 11.5794 3.33301 12.4999V14.9999C3.33301 15.9204 4.0792 16.6666 4.99967 16.6666H7.49967C8.42015 16.6666 9.16634 15.9204 9.16634 14.9999V12.4999C9.16634 11.5794 8.42015 10.8333 7.49967 10.8333H4.99967Z"
        fill="#FF7C11"
      />
    </svg>
  );
}

export default MiscIcon;
