/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Route, RouteProps } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import { BBBSpinner } from '@/components/ui';
import useAuthenticatedUser from '@/hooks/auth/useAuthenticatedUser';
import useAuthenticatedUserCompanies from '@/hooks/auth/useAuthenticatedUserCompanies';
import useAuthenticatedUserCompany from '@/hooks/auth/useAuthenticatedUserCompany';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import useAppEmbed from '@/hooks/common/useAppEmbed';
import { useAccessToken, useCompany } from '@/hooks/rtk/selector';
import { useAppDispatch } from '@/hooks/rtk/store';
import AppLayout from '@/layouts/AppLayout';
import EmbedLayout from '@/layouts/EmbedLayout';
import {
  setActiveCompany,
  setCompany,
  setUser,
  setUserCompany,
} from '@/stores/auth';
import { _localStorage } from '@/utils/common/localStorage';
import { toast } from '@/utils/common/toast';

type Props = RouteProps & {
  customLayout?: boolean;
  title?: string;
};

export default function PrivateRoute(props: Props) {
  const accessToken = useAccessToken();

  if (!accessToken) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          search: props.location
            ? `redirectTo=${encodeURIComponent(
                props.location.pathname + props.location.search
              )}`
            : undefined,
        }}
      />
    );
  }

  return <_PrivateRoute {...props} accessToken={accessToken} />;
}

function _PrivateRoute(
  props: Props & {
    accessToken: string;
  }
) {
  const { data, status } = useAuthenticatedUserCompanies();

  if (status === 'loading') {
    return <BBBSpinner />;
  }

  if (status === 'error') {
    throw new Error('Server error. Cannot retrieve authentication data');
  }

  if (data.pages.length && !data.pages[0].meta.total) {
    return (
      <Redirect
        to={{
          pathname: '/company',
          search: props.location
            ? `redirectTo=${encodeURIComponent(
                props.location.pathname + props.location.search
              )}`
            : undefined,
        }}
      />
    );
  }

  return (
    <__PrivateRoute {...props} companyId={data.pages[0].data[0].companyId} />
  );
}

function __PrivateRoute(
  props: Props & { companyId: number; accessToken: string }
) {
  const dispatch = useAppDispatch();

  const [readyUser, setReadyUser] = useState(false);

  const authenticatedUserQuery = useAuthenticatedUser();

  useEffect(() => {
    if (authenticatedUserQuery.status === 'success') {
      dispatch(setUser(authenticatedUserQuery.data));
      setReadyUser(true);
    }
  }, [authenticatedUserQuery.data, authenticatedUserQuery.status, dispatch]);

  if (!readyUser) {
    return <BBBSpinner />;
  }

  return <__PrivateRouteValidateCompany {...props} />;
}

function __PrivateRouteValidateCompany({
  title,
  component: Component,
  companyId: defaultCompanyId,
  accessToken,
  ...rest
}: Props & { companyId: number; accessToken: string }) {
  const dispatch = useAppDispatch();
  const queryParams = useQuerySearchParams();

  const [readyCompany, setReadyCompany] = useState(false);

  const companyIdFromParams = queryParams.get('companyId');

  const activeCompanyFromLocalStorage = _localStorage.getItem('activeCompany');
  const [companyIds, setCompanyIds] = useState(
    [
      companyIdFromParams ? parseInt(companyIdFromParams, 10) : undefined,
      activeCompanyFromLocalStorage
        ? parseInt(activeCompanyFromLocalStorage, 10)
        : undefined,
      defaultCompanyId,
    ].filter(Boolean)
  );

  const companyId = companyIds[0];

  const authenticatedUserCompanyQuery = useAuthenticatedUserCompany(companyId);

  useEffect(() => {
    if (companyId) {
      if (authenticatedUserCompanyQuery.status === 'success') {
        localStorage.setItem('activeCompany', companyId.toString());
        dispatch(setActiveCompany(companyId));
        dispatch(setCompany(authenticatedUserCompanyQuery.data.company));
        dispatch(setUserCompany(authenticatedUserCompanyQuery.data));
        setReadyCompany(true);
      } else if (
        authenticatedUserCompanyQuery.status === 'error' &&
        authenticatedUserCompanyQuery.error.response?.status === 400
      ) {
        toast.error(
          `Company ID ${companyId} does not exist in current authentication`
        );
        setCompanyIds((prev) => prev.slice(1));
      }
    }
  }, [
    authenticatedUserCompanyQuery.data,
    authenticatedUserCompanyQuery.error?.response?.status,
    authenticatedUserCompanyQuery.status,
    companyId,
    dispatch,
  ]);

  if (!readyCompany) {
    return <BBBSpinner />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {title && <Head title={title} />}
          <___PrivateRoute component={Component} {...props} {...rest} />
        </>
      )}
    />
  );
}

function ___PrivateRoute({
  customLayout,
  component: Component,
  ...props
}: Omit<Props, 'title'>) {
  const { isEmbedVersion } = useAppEmbed();

  if (customLayout) {
    // @ts-ignore
    return <Component {...props} />;
  }

  if (isEmbedVersion) {
    return (
      <EmbedLayout>
        {/* @ts-ignore */}
        <Component {...props} />
      </EmbedLayout>
    );
  }

  return (
    <AppLayout>
      {/* @ts-ignore */}
      <Component {...props} />
    </AppLayout>
  );
}

type HeadProps = {
  title: string;
};

function Head({ title }: HeadProps) {
  const company = useCompany();

  const formatTitle = useMemo(() => {
    if (!title.includes('-')) {
      return `${title} - ${company.name || 'loading..'}`;
    }
    const splittedTitle = title.split(' - ');
    return `${splittedTitle[0]} - ${company.name || 'loading..'} - ${
      splittedTitle[1]
    }`;
  }, [company.name, title]);

  return (
    <Helmet>
      <title>{title ? `${formatTitle} by bitbybit` : 'bitbybit'}</title>
    </Helmet>
  );
}
