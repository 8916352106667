import analytics from './analytics';
import appearance from './appearance';
import configs from './configs';
import integrations from './integrations';
import login from './login';
import settings from './settings';

export default {
  analytics,
  integrations,
  login,
  appearance,
  settings,
  configs,
};
