import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useAddCategory,
  useCategoryById,
  useDeleteCategory,
  useUpdateCategory,
} from 'hooks/bitApp/category';
import useCustomForm from 'hooks/common/useCustomForm';
import { CategoryTypes } from 'stores/bitApp/types';
import { twMerge as cx } from 'tailwind-merge';
import * as yup from 'yup';
import { generateShopifyId, getFragmentShopifyName } from 'utils/bitApp';
import NavigateCategory from './NavigateCategory';
import SubCategory from './SubCategory';

import {
  BBBCard,
  BBBFooterAction,
  BBBPrimarySwitch,
  BBBTextInput,
} from '@/components/ui';

export const destinationOptions = [
  { label: 'Collections', value: 'collections' },
  { label: 'Custom URL page', value: 'custom-url' },
];

const subCategorySchema = yup.array().of(
  yup.object({
    categoryId: yup.number().nullable().required(),
    title: yup.string(),
    idCollection: yup.string().nullable().optional(),
    destination: yup.mixed<typeof destinationOptions[number]>(),
    customUrl: yup.string().nullable(),
    position: yup.number().nullable().required(),
  })
);

const schema = yup.object().shape({
  id: yup.number().required(),
  title: yup.string().required('Title is required'),
  active: yup.boolean().required(),
  destination: yup.mixed<typeof destinationOptions[number]>(),
  customUrl: yup.string().nullable(),
  collection: yup.string().nullable(),
  subCategories: subCategorySchema,
});

export type SchemaCategory = CategoryTypes;

type Props = {
  categoryId: number;
  onClose: () => void;
};

function Category({ categoryId, onClose }: Props) {
  const { data, isInitialLoading } = useCategoryById(categoryId, {
    enabled: categoryId === 0 ? false : true,
  });

  const deleteCategory = useDeleteCategory();

  const defaultValues: SchemaCategory = useMemo(
    () => ({
      id: data?.id || categoryId,
      title: data?.title || '',
      active: data?.active || true,
      destination:
        destinationOptions.find(
          (option) => option.value === data?.destination
        ) || destinationOptions[0],
      customUrl: data?.customUrl || '',
      collection: data?.idCollection
        ? generateShopifyId('Collection', data?.idCollection?.toString())
        : '',
      position: data?.position || 0,
      subCategories:
        data?.subCategories?.map((item) => ({
          ...item,
          destination:
            destinationOptions.find(
              (option) => option.value === item.destination
            ) || destinationOptions[0],
          title: item.title || '',
          position: item.position || 0,
          idCollection: item?.idCollection
            ? generateShopifyId('Collection', item.idCollection?.toString())
            : '',
        })) || [],
    }),
    [
      data?.id,
      data?.title,
      data?.active,
      data?.customUrl,
      data?.idCollection,
      data?.position,
      data?.subCategories,
      data?.destination,
      categoryId,
    ]
  );

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    setError,
    setValue,
    watch,
  } = useCustomForm<SchemaCategory>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { append } = useFieldArray({
    control,
    name: 'subCategories',
  });

  const subCategories = useWatch({
    control,
    name: 'subCategories',
  });

  const { mutate: updateCategory, isLoading: isLoadingUpdateCategory } =
    useUpdateCategory(categoryId);
  const { mutate: createCategory, isLoading: isLoadingCreateCategory } =
    useAddCategory();

  const onSubmit = useCallback(
    (data: SchemaCategory) => {
      function validateForm() {
        let hasErrors = false;
        if (data?.subCategories?.length && data?.id === categoryId) {
          data?.subCategories?.forEach((subCategory, idx) => {
            if (
              (subCategory.title || subCategory.idCollection) &&
              subCategory.destination.value === 'collections'
            ) {
              if (!subCategory.title) {
                setError(`subCategories.${idx}.title`, {
                  type: 'required',
                  message: `Title on sub-category ${idx + 1} is required`,
                });
                hasErrors = true;
              }
              if (!subCategory.idCollection) {
                setError(`subCategories.${idx}.idCollection`, {
                  type: 'required',
                  message: `Collection on sub-category ${idx + 1} is required`,
                });
                hasErrors = true;
              }
            }
            if (
              (subCategory.title || subCategory.customUrl) &&
              subCategory.destination.value === 'custom-url'
            ) {
              if (!subCategory.title) {
                setError(`subCategories.${idx}.title`, {
                  type: 'required',
                  message: `Title on sub-category ${idx + 1} is required`,
                });
                hasErrors = true;
              }
              if (!subCategory.customUrl) {
                setError(`subCategories.${idx}.idCollection`, {
                  type: 'required',
                  message: `Collection on sub-category ${idx + 1} is required`,
                });
                hasErrors = true;
              }
            }
          });
          const subCategories = data?.subCategories?.filter(
            (sub) => sub.title || sub.idCollection
          );
          setValue('subCategories', subCategories);
        }
        return hasErrors;
      }

      function submit() {
        const requestData = {
          title: data.title,
          idCollection: data?.collection
            ? parseInt(getFragmentShopifyName(data?.collection))
            : null,
          active: data.active,
          destination: data.destination.value,
          customUrl: data.customUrl,
          position: data.position,
          subCategories:
            data?.subCategories
              ?.filter((sub) => sub.title || sub.idCollection || sub.customUrl)
              ?.map((sub) => ({
                title: sub.title,
                idCollection: parseInt(
                  getFragmentShopifyName(sub.idCollection?.toString() || '')
                ),
                position: sub.position,
                categoryId: data.id,
                destination: sub.destination.value,
                customUrl: sub.customUrl || '',
              })) || [],
        };

        return data.id === 0
          ? createCategory(requestData, { onSuccess: onClose })
          : updateCategory(requestData, { onSuccess: onClose });
      }

      if (!validateForm()) {
        submit();
      }
    },
    [categoryId, createCategory, onClose, setError, setValue, updateCategory]
  );

  useEffect(() => {
    if (categoryId) {
      reset(defaultValues);
    }
  }, [categoryId, defaultValues, reset]);

  if (isInitialLoading) {
    return <CategorySkeleton />;
  }

  return (
    <BBBCard id="bitapp-category-form" className={cx('mb-cardBottom')}>
      <div className="flex flex-col gap-5">
        <div className="flex items-center gap-2">
          <BBBTextInput
            label="Title"
            placeholder="Enter category title"
            value="Best Seller"
            hasMaxCharLabel
            maxChar={60}
            containerClassname="mb-0 grow"
            isHookForm
            control={control}
            controlName="title"
            error={errors.title?.message}
          />
          <Controller
            control={control}
            name="active"
            render={({ field }) => (
              <BBBPrimarySwitch
                className="mt-7"
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </div>
        {subCategories?.length ? (
          <SubCategory control={control} setValue={setValue} watch={watch} />
        ) : (
          <NavigateCategory
            control={control}
            onClick={() => {
              append({
                title: '',
                idCollection: '',
                categoryId: categoryId,
                position: 0,
                destination: destinationOptions[0],
                customUrl: '',
              });
            }}
            setValue={setValue}
          />
        )}
      </div>
      <BBBFooterAction
        containerClassName="justify-end"
        onCancel={onClose}
        discardLabel="Cancel"
        onSave={() => handleSubmit(onSubmit)()}
        disableSave={!isDirty}
        onDelete={() => deleteCategory(categoryId)}
        loadingSave={isLoadingUpdateCategory || isLoadingCreateCategory}
      />
    </BBBCard>
  );
}

function CategorySkeleton() {
  return (
    <BBBCard id="bitapp-category-form" className={cx('mb-cardBottom')}>
      <div className="flex flex-col gap-5">
        <div className="flex items-center gap-2">
          <Skeleton width={370} height={20} />
          <Skeleton width={50} height={20} />
        </div>
      </div>
    </BBBCard>
  );
}

export default Category;
