import React, { useEffect, useState } from 'react';
import { ArrowLeft, Calendar, ChevronDown } from 'react-feather';

import { BBBButton, BBBCheckbox } from '@/components/ui';
import { BBBCard } from '@/components/ui/BBBCard';
import {
  useLoginBasicSetting,
  useLoginSetting,
  useLoginSettingByType,
  useLoginTitleSetting,
  useTotalCollectDataPage,
} from '@/hooks/bitLogin/login/login-settings';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { setCollectDataPage } from '@/stores/bitLogin';
import { cn } from '@/utils/styles';

export default function PreviewAdditionalForm() {
  return (
    <div
      className={cn(
        'border-l flex-[1_1_0%] flex justify-center items-center overflow-auto'
      )}
    >
      <_PreviewAdditionalForm />
    </div>
  );
}

function _PreviewAdditionalForm() {
  return (
    <div className="flex flex-col gap-6">
      <PreviewCard />
    </div>
  );
}

function PreviewCard() {
  const { value: titleValue } = useLoginTitleSetting('form_title');
  const { value: subHeadingValue } = useLoginTitleSetting('form_sub_heading');
  const { active: consentDisclaimerActive } =
    useLoginTitleSetting('consent_disclaimer');
  const { active: addressActive } = useLoginSettingByType('address');
  const customSettingData = useLoginSetting('custom');
  const basicSettingData = useLoginSetting('basic');

  const hasCustomSettings = customSettingData?.some(
    (setting) => setting.active
  );
  const hasBasicSettings = basicSettingData?.some((setting) => setting.active);

  const page = useAppSelector((s) => s.bitLogin.collectDataPage);
  const dispatch = useAppDispatch();

  const totalPage = useTotalCollectDataPage();

  useEffect(() => {
    dispatch(setCollectDataPage(totalPage ? 1 : 0));
  }, [dispatch, totalPage]);

  return (
    <>
      {!hasBasicSettings && !addressActive && !hasCustomSettings ? (
        <EmptyPreview />
      ) : (
        <>
          <CardContent
            titleValue={titleValue || ''}
            subHeadingValue={subHeadingValue || ''}
            hasBasicSettings={hasBasicSettings || false}
            addressActive={addressActive || false}
            hasCustomSettings={hasCustomSettings || false}
            totalPage={totalPage}
            consentDisclaimerActive={consentDisclaimerActive || false}
          />
          <StepsIndicator page={page} totalPage={totalPage} />
        </>
      )}
    </>
  );
}

function EmptyPreview() {
  return (
    <BBBCard className="w-96 !p-10 min-h-[23.75rem] justify-center items-center flex">
      <p className="text-neutral-500 text-center">
        Turn on fields to see preview
      </p>
    </BBBCard>
  );
}

type CardContentProps = {
  titleValue: string;
  subHeadingValue: string;
  hasBasicSettings: boolean;
  addressActive: boolean;
  hasCustomSettings: boolean;
  consentDisclaimerActive: boolean;
  totalPage: number;
};

function CardContent({
  titleValue,
  subHeadingValue,
  hasBasicSettings,
  addressActive,
  hasCustomSettings,
  consentDisclaimerActive,
  totalPage,
}: CardContentProps) {
  const page = useAppSelector((s) => s.bitLogin.collectDataPage);

  return (
    <BBBCard
      title={<TitleContent titleValue={titleValue} />}
      desc={page === 1 ? subHeadingValue : null}
      className="w-96 !p-10 min-h-[23.75rem] flex flex-col gap-4 justify-between"
      titleClassName="text-2xl mb-0"
      containerTitleClassName="mb-0"
    >
      <div className="flex flex-col gap-4 h-full">
        <Content
          hasBasicSettings={hasBasicSettings}
          addressActive={addressActive}
          hasCustomSettings={hasCustomSettings}
          totalPage={totalPage}
        />
      </div>
      <div className="flex flex-col gap-2.5">
        <BBBButton text={page + 1 <= totalPage ? 'Next' : 'Submit'} />
        {page < totalPage && (
          <p className="text-end text-neutral-500 text-xs cursor-pointer">
            Step {page}/{totalPage}
          </p>
        )}
        {consentDisclaimerActive && page === totalPage && (
          <p className="text-center mt-2 text-neutral-500 text-xs underline cursor-pointer">
            How we use data for marketing needs
          </p>
        )}
      </div>
    </BBBCard>
  );
}

function TitleContent({ titleValue }: Pick<CardContentProps, 'titleValue'>) {
  const page = useAppSelector((s) => s.bitLogin.collectDataPage);

  return (
    <>
      {page === 1 ? (
        titleValue
      ) : (
        <div className="flex items-center gap-1">
          <ArrowLeft size={12} color="#9A9A9A" />
          <p className="text-xs text-neutral-500">Back</p>
        </div>
      )}
    </>
  );
}

function Content({
  hasBasicSettings,
  addressActive,
  hasCustomSettings,
  totalPage,
}: Pick<
  CardContentProps,
  'hasBasicSettings' | 'addressActive' | 'hasCustomSettings' | 'totalPage'
>) {
  const page = useAppSelector((s) => s.bitLogin.collectDataPage);

  if (!totalPage) {
    return null;
  }

  if (totalPage === 1) {
    if (hasBasicSettings) {
      return <BasicPreview />;
    }

    if (addressActive) {
      return <AddressPreview />;
    }

    if (hasCustomSettings) {
      return <CustomPreview />;
    }

    return null;
  }

  if (totalPage === 2) {
    if (hasBasicSettings && addressActive) {
      if (page === 1) {
        return <BasicPreview />;
      }

      return <AddressPreview />;
    }

    if (hasBasicSettings && hasCustomSettings) {
      if (page === 1) {
        return <BasicPreview />;
      }

      return <CustomPreview />;
    }

    if (addressActive && hasCustomSettings) {
      if (page === 1) {
        return <AddressPreview />;
      }

      return <CustomPreview />;
    }
  }

  if (totalPage === 3) {
    if (page === 1) {
      return <BasicPreview />;
    }

    if (page === 2) {
      return <AddressPreview />;
    }

    return <CustomPreview />;
  }

  return null;
}

type StepsIndicatorProps = {
  page: number;
  totalPage: number;
};

function StepsIndicator({ page, totalPage }: StepsIndicatorProps) {
  const dispatch = useAppDispatch();
  return (
    <div className="flex items-center gap-2 justify-center">
      {Array.from({ length: totalPage }).map((_, index) => (
        <div
          key={index}
          className={cn(
            'w-3 h-3 rounded-full cursor-pointer transition-all',
            index + 1 === page ? 'bg-primary-main' : 'bg-neutral-30'
          )}
          onClick={() => dispatch(setCollectDataPage(index + 1))}
        ></div>
      ))}
    </div>
  );
}

function BasicPreview() {
  const basicSettingsData = useLoginSetting('basic');
  const { active: phoneNumberActive, mandatory: phoneNumberRequired } =
    useLoginBasicSetting('phoneNumber');
  const { active: genderActive, mandatory: genderRequired } =
    useLoginBasicSetting('gender');
  const { active: dateOfBirthActive, mandatory: dateOfBirthRequired } =
    useLoginBasicSetting('dateOfBirth');

  const inactive = basicSettingsData?.every((setting) => !setting.active);

  if (inactive) return null;

  return (
    <>
      {phoneNumberActive && (
        <div className="flex flex-col gap-1">
          <p className="text-primary-main font-medium text-ls">
            Phone number
            {phoneNumberRequired && <span className="text-danger-main">*</span>}
          </p>
          <div className="flex items-center gap-4 py-3 px-4 border border-neutral-30 rounded-lg">
            <div className="flex items-center gap-1">
              <div className="w-3 h-2.5 bg-neutral-30"></div>
              <p className="text-primary-main text-sm">+1</p>
            </div>
            <ChevronDown size={14} color="#232323" />
            <p className="text-sm text-neutral-30">123456789</p>
          </div>
        </div>
      )}
      {genderActive && (
        <div className="flex flex-col gap-1">
          <p className="text-primary-main font-medium text-ls">
            Gender
            {genderRequired && <span className="text-danger-main">*</span>}
          </p>
          <div className="flex items-center justify-between gap-4 py-3 px-4 border border-neutral-30 rounded-lg">
            <p className="text-sm text-neutral-30">What is your gender</p>
            <ChevronDown size={14} color="#232323" />
          </div>
        </div>
      )}
      {dateOfBirthActive && (
        <div className="flex flex-col gap-1">
          <p className="text-primary-main font-medium text-ls">
            Date of birth
            {dateOfBirthRequired && <span className="text-danger-main">*</span>}
          </p>
          <div className="flex items-center justify-between gap-4 py-3 px-4 border border-neutral-30 rounded-lg">
            <p className="text-sm text-neutral-30">Date of birth</p>
            <Calendar size={14} color="#232323" />
          </div>
        </div>
      )}
    </>
  );
}

function AddressPreview() {
  const { mandatory } = useLoginSettingByType('address');
  return (
    <>
      <div className="flex flex-col gap-1">
        <p className="text-primary-main font-medium text-ls">
          Address 1{mandatory && <span className="text-danger-main">*</span>}
        </p>

        <div className="flex items-center gap-4 py-3 px-4 border border-neutral-30 rounded-lg">
          <p className="text-sm text-neutral-30">Enter your address</p>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-primary-main font-medium text-ls">
          Address 2 (optional)
        </p>
        <div className="flex items-center gap-4 py-3 px-4 border border-neutral-30 rounded-lg">
          <p className="text-sm text-neutral-30">Enter your address</p>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-primary-main font-medium text-ls">
          City
          {mandatory && <span className="text-danger-main">*</span>}
        </p>
        <div className="flex items-center justify-between gap-4 py-3 px-4 border border-neutral-30 rounded-lg">
          <p className="text-sm text-neutral-30">Enter your city</p>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-primary-main font-medium text-ls">
          Country/region
          {mandatory && <span className="text-danger-main">*</span>}
        </p>
        <div className="flex items-center justify-between gap-4 py-3 px-4 border border-neutral-30 rounded-lg">
          <p className="text-sm text-neutral-30">Enter your country</p>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-primary-main font-medium text-ls">
          Province
          {mandatory && <span className="text-danger-main">*</span>}
        </p>
        <div className="flex items-center justify-between gap-4 py-3 px-4 border border-neutral-30 rounded-lg">
          <p className="text-sm text-neutral-30">Enter your province</p>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-primary-main font-medium text-ls">
          Postal/Zip Code
          {mandatory && <span className="text-danger-main">*</span>}
        </p>
        <div className="flex items-center justify-between gap-4 py-3 px-4 border border-neutral-30 rounded-lg">
          <p className="text-sm text-neutral-30">Enter your postal</p>
          <ChevronDown size={14} color="#232323" />
        </div>
      </div>
    </>
  );
}

function CustomPreview() {
  const mappedCustomFields = useLoginSetting('custom');

  const isAllNotActive = mappedCustomFields?.every(
    (setting) => !setting.active
  );

  const customFieldSorted = mappedCustomFields
    ?.filter((setting) => setting.active)
    .sort((a, b) => {
      const aIndex = a.index ?? Infinity;
      const bIndex = b.index ?? Infinity;

      return aIndex - bIndex;
    });

  if (isAllNotActive) return null;

  return (
    <>
      {customFieldSorted?.map((field, idx) => (
        <React.Fragment key={`field-${idx}`}>
          {field.type === 'single_line_text' && field.active && (
            <div className="flex flex-col gap-1">
              <p className="text-primary-main font-medium text-ls">
                {field.label}
                {field.mandatory && <span className="text-danger-main">*</span>}
              </p>
              <div className="flex items-center gap-4 py-3 px-4 border border-neutral-30 rounded-lg">
                <p className="text-sm text-neutral-30">Enter your answer</p>
              </div>
            </div>
          )}
          {field.type === 'multi_line_text' && field.active && (
            <div className="flex flex-col gap-1">
              <p className="text-primary-main font-medium text-ls">
                {field.label}
                {field.mandatory && <span className="text-danger-main">*</span>}
              </p>
              <div className="flex items-start gap-4 py-3 px-4 h-28 border border-neutral-30 rounded-lg">
                <p className="text-sm text-neutral-30">Enter your answer</p>
              </div>
            </div>
          )}
          {field.type === 'dropdown_list' && field.active && (
            <div className="flex flex-col gap-1">
              <p className="text-primary-main font-medium text-ls">
                {field.label}
                {field.mandatory && <span className="text-danger-main">*</span>}
              </p>
              <div className="flex items-center gap-4 py-3 justify-between px-4 border border-neutral-30 rounded-lg">
                <p className="text-sm text-neutral-30">Enter your answer</p>
                <ChevronDown size={14} color="#232323" />
              </div>
            </div>
          )}
          {field.type === 'radio_button' && field.active && (
            <div className="flex flex-col gap-1">
              <p className="text-primary-main font-medium text-ls">
                {field.label}
                {field.mandatory && <span className="text-danger-main">*</span>}
              </p>
              <div
                className={cn(
                  'flex',
                  field.orientation === 'horizontal'
                    ? 'flex-row gap-4 items-center flex-wrap'
                    : 'flex-col gap-2 items-start mt-1'
                )}
              >
                {field.options?.map((option, idx) => (
                  <div
                    className="flex items-center gap-1.5"
                    key={`option-${idx}`}
                  >
                    <div
                      className={cn(
                        'flex items-center justify-center w-4 h-4 border-2 border-primary-main bg-white rounded-full',
                        idx === 0 ? 'border-primary-main' : 'border-neutral-400'
                      )}
                    >
                      <div
                        className={cn(
                          'w-2 h-2 rounded-full',
                          idx === 0 ? 'bg-primary-main' : 'bg-transparent'
                        )}
                      ></div>
                    </div>
                    <p className=" text-primary-main">{option}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {field.type === 'multiple_choice' && field.active && (
            <div className="flex flex-col gap-1">
              <p className="text-primary-main font-medium text-ls">
                {field.label}
                {field.mandatory && <span className="text-danger-main">*</span>}
              </p>
              <div
                className={cn(
                  'flex',
                  field.orientation === 'horizontal'
                    ? 'flex-row gap-4 items-center flex-wrap'
                    : 'flex-col gap-2 items-start mt-1'
                )}
              >
                {field.options?.map((option, idx) => (
                  <BBBCheckbox
                    key={`option-${idx}`}
                    label={option}
                    checked={idx === 0}
                    inputClassName="w-5 h-5 p-0"
                    checkSize={10}
                    onValueChange={() => null}
                  />
                ))}
              </div>
            </div>
          )}
        </React.Fragment>
      ))}
    </>
  );
}
