import SparklesIcon from 'assets/icons/SparklesIcon';

export const RECO_AGENT_ID = -3;
export const TRACKO_AGENT_ID = -2;
export const ROBO_AGENT_ID = -4;
export const EVERYONE_AGENT_ID = -1;

export const allAgentOptions = [
  {
    label: 'All agent',
    value: 'all',
    parentValue: 'agent',
  },
];

export const aiOptions = [
  {
    label: aiAgentLabel('AI Agent'),
    value: TRACKO_AGENT_ID,
    parentValue: 'agent',
  },
];

export const staticAgents = [
  EVERYONE_AGENT_ID,
  RECO_AGENT_ID,
  TRACKO_AGENT_ID,
  ROBO_AGENT_ID,
];

export const aiAgentOptions = [
  {
    name: 'Reco',
    speciality: {
      label: 'Product recommendation',
      value: 'product-recommendation',
      description: 'Recommend products according to customer’s preferences',
    },
    value: RECO_AGENT_ID,
  },
  {
    name: 'Tracko',
    speciality: {
      label: 'Order tracking',
      value: 'order-tracking',
      description: 'Sending current status of customer’s order',
    },
    value: TRACKO_AGENT_ID,
  },
  {
    name: 'Robo',
    speciality: {
      label: 'FAQ',
      value: 'faq',
      description: 'Answering customer questions based on uploaded file',
    },
    value: ROBO_AGENT_ID,
  },
];

export const mapAgentOption = (agent: typeof aiAgentOptions[number]) => ({
  formattedName: aiAgentLabel(agent.name),
  formattedName2: agent.name,
  user: {
    id: agent.value,
  },
});

export function aiAgentLabel(name: string) {
  return (
    <div className="flex items-center gap-2 text-ai-main">
      <SparklesIcon size={16} color="#8F68C0" />
      <span>{name}</span>
    </div>
  );
}

export const generalAiAgentOption = mapAgentOption({
  name: 'AI Agent',
  value: TRACKO_AGENT_ID,
  speciality: {
    label: 'Ai agent',
    value: 'ai-agent',
    description: 'reserved for AI agent',
  },
});
