import { Fragment, useMemo, useRef, useState } from 'react';
import { Edit } from 'react-feather';
import { Link } from 'react-router-dom';
import { twMerge as cx } from 'tailwind-merge';
import StartChat from './StartChat';

import FilterIcon2 from '@/assets/icons/FilterIcon2';
import { BBBAlert, BBBCard, BBBCheckbox, BBBSelect } from '@/components/ui';
import BBBBadge from '@/components/ui/BBBBadge/BBBBadge';
import BBBLimitAlert from '@/components/ui/BBBLimitAlert/BBBLimitAlert';
import UpgradeText from '@/components/ui/BBBLimitAlert/UpgradeText';
import SearchInput from '@/components/ui/SearchInput';
import { aiOptions, allAgentOptions } from '@/constants/bitChat/agent';
import { staticFilterOptions } from '@/constants/crm';
import useCompanyUsers from '@/hooks/auth/useCompanyUsers';
import useIsModuleRestricted from '@/hooks/auth/useIsModuleRestricted';
import useSettings from '@/hooks/bitChat/settings/useSettings';
import useOutsideAlerter from '@/hooks/common/useOutsideAlerterv2';
import useIsMaximumLevel from '@/hooks/pricing/useIsMaximumLevel';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import useWithCRMPricing from '@/hooks/pricing/useWithCRMPricing';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import useActiveStatus from '@/hooks/whatsApp/useActiveStatus';
import {
  setActiveStatus,
  setChatFilter,
  setSearchChat,
  setSearchedMessageId,
  setSelectedLivechat,
} from '@/stores/bitCRM';
import { formatUserDisplayName } from '@/utils/auth';
import { _localStorage } from '@/utils/common/localStorage';
import { cn } from '@/utils/styles';

const options = [
  {
    label: 'Waiting',
    value: 'waiting',
  },
  {
    label: 'Yours',
    value: 'active',
  },
  {
    label: 'All',
    value: 'all',
  },
];

export default function HeaderChatPanel() {
  return <_HeaderChatPanel />;
}

function _HeaderChatPanel() {
  const search = useAppSelector((state) => state.bitCRM.searchChat);
  const filter = useAppSelector((state) => state.bitCRM.chatFilter);

  const [showStartChat, setShowStartChat] = useState(false);

  const { data: isRestricted } = useIsModuleRestricted(
    'BITCHAT_EDIT_TICKET_LIVECHAT'
  );

  const dispatch = useAppDispatch();

  return (
    <div className="flex flex-col">
      {showStartChat && (
        <StartChat
          showModal={showStartChat}
          onChangeShowModal={setShowStartChat}
        />
      )}
      <div
        className="flex-none h-[84px] relative flex gap-3 border-b px-3 py-4 items-center"
        id="chat-panel-head-1"
      >
        <div className="grow">
          <SearchInput
            placeholder="Search or start new chat"
            containerClassname="mb-0"
            value={search || ''}
            onValueChange={(value) => dispatch(setSearchChat(value))}
          />
        </div>
        {!search ? (
          <div>
            <Filter
              length={filter?.filter((val) => val.value !== 'all').length}
              filter={filter}
            />
          </div>
        ) : (
          <div
            className="hover:bg-gray-100 cursor-pointer p-1 rounded"
            onClick={() => {
              dispatch(setSearchChat(undefined));
              dispatch(setSearchedMessageId(undefined));
            }}
          >
            Cancel
          </div>
        )}
      </div>
      {!search && (
        <Status
          options={options.filter((opt) =>
            isRestricted ? opt.value !== 'active' : true
          )}
          onChangeStatus={(opt) => {
            dispatch(setSelectedLivechat(null));
            const newActiveStatusKey = opt.value === 'all' ? null : opt.value;
            //@ts-ignore
            dispatch(setActiveStatus(newActiveStatusKey));
            if (newActiveStatusKey) {
              _localStorage.setItem('activeStatus1', newActiveStatusKey);
            } else {
              _localStorage.removeItem('activeStatus1');
            }
          }}
          onStartChat={() => {
            setShowStartChat(true);
          }}
        />
      )}
      <CombinedPricingAlert />
    </div>
  );
}

function CombinedPricingAlert() {
  const { data: pricingFeatureData, limit: conversationLimit } =
    useWithCRMPricing('BITCHAT', 'conversation');

  const { limit: aiCreditsLimit } = useWithCRMPricing('BITCHAT', 'ai_credits');
  const { data: pricingData } = usePricingByApp('BITCHAT');

  const conversationPercentage =
    (pricingFeatureData?.usage || 0) / conversationLimit;

  const aiCreditPercentage =
    (pricingData?.aiCreditBalance || 0) / aiCreditsLimit;

  const reachedConversationLimit = conversationPercentage >= 1;
  const reachedAiCreditLimit = aiCreditPercentage >= 1;

  return (
    <>
      {reachedConversationLimit && reachedAiCreditLimit ? (
        <BBBAlert type="secondary" className="rounded-none border-transparent">
          <span className="font-bold">
            You’ve reached your 1,000 conversation limit.
          </span>{' '}
          To reply to your customers, please upgrade your plan.{' '}
          <UpgradeText appType="BITCHAT" label="Upgrade now" />
        </BBBAlert>
      ) : conversationPercentage >= 0.9 && reachedAiCreditLimit ? (
        <BBBAlert type="secondary" className="rounded-none border-transparent">
          <span className="font-bold">
            You’re{' '}
            {(
              conversationLimit - (pricingFeatureData?.usage || 0)
            ).toLocaleString()}{' '}
            conversation away from your conversation limit and your free AI
            tokens have reached their limit.
          </span>{' '}
          Please upgrade your plan.{' '}
          <UpgradeText appType="BITCHAT" label="Upgrade now" />{' '}
          <span className="font-bold">or</span>{' '}
          <a className="underline font-bold" href="/pricing?tab=bitchat">
            learn more
          </a>
        </BBBAlert>
      ) : conversationPercentage >= 0.9 && aiCreditPercentage < 90 ? (
        <BBBAlert type="secondary" className="rounded-none border-transparent">
          <span className="font-bold">
            You’re{' '}
            {(
              conversationLimit - (pricingFeatureData?.usage || 0)
            ).toLocaleString()}{' '}
            conversation away from your conversation limit.
          </span>{' '}
          To reply to your customers, please upgrade your plan.{' '}
          <UpgradeText appType="BITCHAT" label="Upgrade now" /> to avoid missing
          out on new customers.
        </BBBAlert>
      ) : (
        <AiCreditAlert />
      )}
    </>
  );
}

export function AiCreditAlert({
  context = 'livechat',
}: {
  context?: 'livechat' | 'ticket-detail';
}) {
  const { data: pricingData } = usePricingByApp('BITCHAT');
  const { limit: aiCreditsLimit } = useWithCRMPricing('BITCHAT', 'ai_credits');

  const aiCreditPercentage =
    (pricingData?.aiCreditBalance || 0) / aiCreditsLimit;

  return (
    <>
      {pricingData?.aiCreditAutoChargeStatus === 'failed' ? (
        <BBBAlert
          type="secondary"
          className={cn(
            context === 'livechat' && 'rounded-none border-transparent'
          )}
        >
          <span className="font-bold">
            Payment Failed. You’ve run out of AI Tokens.
          </span>{' '}
          <UpgradeText appType="BITCHAT" label="Update Payment" /> now to
          continue using AI Agent or{' '}
          <a href="/pricing?tab=bitchat" className="underline font-bold">
            learn more.
          </a>
        </BBBAlert>
      ) : pricingData?.pricingName === 'free' ? (
        pricingData.aiCreditBalance === 0 ? (
          <BBBAlert
            type="secondary"
            className={cn(
              context === 'livechat' && 'rounded-none border-transparent'
            )}
          >
            <span className="font-bold">
              Your free AI tokens have reached it&apos;s limit.
            </span>{' '}
            <UpgradeText appType="BITCHAT" label="Upgrade now" /> now to
            continue using AI Agent or{' '}
            <a href="/pricing?tab=bitchat" className="underline font-bold">
              learn more.
            </a>
          </BBBAlert>
        ) : (
          aiCreditPercentage >= 0.9 && (
            <BBBAlert
              type="info"
              className={cn(
                context === 'livechat' && 'rounded-none border-transparent'
              )}
            >
              <span className="font-bold">
                You only have {pricingData?.aiCreditBalance?.toLocaleString()}{' '}
                free AI tokens left.
              </span>{' '}
              <UpgradeText appType="BITCHAT" label="Upgrade now" /> to avoid
              running out of AI tokens or{' '}
              <a href="/pricing?tab=bitchat" className="underline font-bold">
                learn more.
              </a>
            </BBBAlert>
          )
        )
      ) : (
        !pricingData?.aiCreditAutoCharge &&
        (pricingData?.aiCreditBalance === 0 ? (
          <BBBAlert
            type="secondary"
            className={cn(
              context === 'livechat' && 'rounded-none border-transparent'
            )}
          >
            <span className="font-bold">
              Your free AI tokens have reached it&apos;s limit.
            </span>{' '}
            <UpgradeText appType="BITCHAT" label="Activate auto charge" /> now
            to continue using AI Agent or{' '}
            <a href="/pricing?tab=bitchat" className="underline font-bold">
              learn more.
            </a>
          </BBBAlert>
        ) : (
          aiCreditPercentage >= 0.9 && (
            <BBBAlert
              type="info"
              className={cn(
                context === 'livechat' && 'rounded-none border-transparent'
              )}
            >
              <span className="font-bold">
                You only have {pricingData?.aiCreditBalance?.toLocaleString()}{' '}
                free AI tokens left.
              </span>{' '}
              <UpgradeText appType="BITCHAT" label="Activate auto charge" /> to
              avoid running out of AI tokens or{' '}
              <a href="/pricing?tab=bitchat" className="underline font-bold">
                learn more.
              </a>
            </BBBAlert>
          )
        ))
      )}
    </>
  );
}

function FilterOption({
  filter,
  opt,
}: {
  filter?: {
    label: string;
    value: string;
    parentValue?: string;
  }[];
  opt: {
    label: string;
    value: string;
    parentValue?: string;
  };
}) {
  const checked = filter?.some(
    (_opt) => _opt.value === opt.value && opt.parentValue === _opt.parentValue
  );

  const dispatch = useAppDispatch();

  const handleClickOption = (
    val: boolean,
    opt: typeof staticFilterOptions[number]
  ) => {
    dispatch(
      setChatFilter(
        val
          ? [...(filter || []), opt]
          : filter?.filter(
              (_opt) =>
                !(
                  _opt.value === opt.value &&
                  _opt.parentValue === opt.parentValue
                )
            )
      )
    );
  };

  return (
    <Fragment key={opt.value}>
      <div
        className={cx(
          !opt.parentValue
            ? 'font-semibold pointer-events-none'
            : 'pointer-events-auto',
          'mb-4 last:mb-0 flex items-center gap-2 cursor-pointer'
        )}
        onClick={() => handleClickOption(!checked, opt)}
      >
        {opt.parentValue && (
          <BBBCheckbox
            checked={checked}
            onValueChange={(val) => handleClickOption(val, opt)}
          />
        )}
        {opt.label}
      </div>
      {opt.value === 'agent' && <AgentFilter filter={filter} />}
      {opt.value === 'ticket-category' && (
        <TicketCategoryFilter filter={filter} />
      )}
    </Fragment>
  );
}

function TicketCategoryFilter({
  filter,
}: {
  filter?: {
    label: string;
    value: string;
    parentValue?: string;
  }[];
}) {
  const { data } = useSettings();

  const ticketCategoryData = useMemo(
    () => [
      ...(data?.ticketTags?.map((tag) => ({
        label: tag.label,
        value: tag.id,
        color: tag.color,
        parentValue: 'ticket-category',
      })) ?? []),
    ],
    [data?.ticketTags]
  );

  const dispatch = useAppDispatch();

  return (
    <>
      {ticketCategoryData.map((category) => (
        <BBBCheckbox
          checked={filter?.some(
            (data) =>
              data.parentValue === 'ticket-category' &&
              data.value === category.value
          )}
          onValueChange={(val) =>
            dispatch(
              setChatFilter(
                val
                  ? [...(filter ?? []), category]
                  : [
                      ...(filter?.filter(
                        (_opt) => _opt.value !== category.value
                      ) ?? []),
                    ]
              )
            )
          }
          key={category.value}
          label={<BBBBadge text={category.label} type={category.color} />}
          className="mb-4"
        />
      ))}
    </>
  );
}

function AgentFilter({
  filter,
}: {
  filter?: {
    label: string;
    value: string;
    parentValue?: string;
  }[];
}) {
  const dispatch = useAppDispatch();

  const query = useCompanyUsers({
    limit: 5,
  });

  const { data: userCompanies, hasNextPage, fetchNextPage } = query;

  const userCompaniesData = useMemo(
    () => [
      ...(userCompanies?.pages.flatMap((page) =>
        page.data.map((uC) => ({
          label: formatUserDisplayName(uC.user) ?? `-`,
          value: uC.userId,
          parentValue: 'agent',
        }))
      ) ?? []),
    ],
    [userCompanies]
  );

  const selectedAgents = filter?.filter((_opt) => _opt.parentValue === 'agent');

  return (
    <BBBSelect
      options={[...allAgentOptions, ...aiOptions, ...userCompaniesData]}
      optionLabel="label"
      optionValue="value"
      isPaginated
      fetchNext={fetchNextPage}
      hasMore={!!hasNextPage}
      isMulti
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      value={filter?.filter((_opt) => _opt.parentValue === 'agent') ?? []}
      onValueChange={(agentOpts, selected) => {
        dispatch(
          setChatFilter([
            ...(filter?.filter((_opt) => _opt.parentValue !== 'agent') ?? []),
            ...(selected?.value === 'all'
              ? allAgentOptions
              : agentOpts?.filter((_opt) => _opt.value !== 'all') ?? []),
          ])
        );
      }}
      renderCustomSelectedValues={() => (
        <>
          {filter?.some(
            (_opt) => _opt.parentValue === 'agent' && _opt.value === 'all'
          ) ? (
            <>All</>
          ) : (
            <>
              {selectedAgents &&
                (selectedAgents?.length > 1
                  ? `${selectedAgents?.length} Agents`
                  : selectedAgents[0].label)}
            </>
          )}
        </>
      )}
      dropdownPosition="top"
    />
  );
}

function Filter({
  length,
  filter,
}: {
  length?: number;
  filter?: {
    label: string;
    value: string;
    parentValue?: string;
  }[];
}) {
  const [showFilter, setShowFilter] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);

  useOutsideAlerter(ref, (selected) => {
    const wrapperRef = document.getElementById('chat-filter');
    if (selected.closest('#chat-filter') !== wrapperRef) {
      setShowFilter(false);
    }
  });

  const dispatch = useAppDispatch();

  return (
    <div
      className="flex items-center gap-3.5"
      id="chat-filter"
      onClick={() => setShowFilter(true)}
    >
      {!!length && (
        <span className="text-sm whitespace-nowrap">{length} filters</span>
      )}
      <div className="relative">
        <div className="cursor-pointer">
          <FilterIcon2 />
        </div>
        {showFilter && (
          <BBBCard
            className="absolute flex gap-4 z-20 p-4 w-[29rem] md:p-4 overflow-auto"
            ref={ref}
          >
            <div className="flex-1">
              {staticFilterOptions
                .slice(
                  0,
                  staticFilterOptions.findIndex(
                    (opt) => opt.value === 'ticket-category'
                  )
                )
                ?.map((opt) => (
                  <FilterOption key={opt.value} filter={filter} opt={opt} />
                ))}
              <div
                className="mt-4 text-neutral-50 underline cursor-pointer"
                onClick={() => dispatch(setChatFilter(allAgentOptions))}
              >
                Reset filter
              </div>
            </div>
            <div className="flex-1">
              {staticFilterOptions
                .slice(
                  staticFilterOptions.findIndex(
                    (opt) => opt.value === 'ticket-category'
                  )
                )
                .map((opt) => (
                  <FilterOption key={opt.value} filter={filter} opt={opt} />
                ))}
            </div>
          </BBBCard>
        )}
      </div>
    </div>
  );
}

function Status({
  onChangeStatus,
  onStartChat,
  options: _options,
}: {
  onChangeStatus?: (opt: typeof options[number]) => void;
  onStartChat?: () => void;
  options: typeof options;
}) {
  const activeStatus = useActiveStatus();

  return (
    <div
      className="flex-1 px-4 border-b flex justify-center items-center gap-4"
      id="chat-assign-type"
    >
      {_options.map((opt) => (
        <div
          className={cx(
            'flex-1 text-center py-3 border-b border-transparent',
            (activeStatus === null ? 'all' : activeStatus) === opt.value
              ? 'border-black'
              : ''
          )}
          key={opt.value}
        >
          <div
            className="hover:bg-gray-100 rounded-lg py-1 px-2 hover:cursor-pointer"
            onClick={() => {
              onChangeStatus?.(opt);
            }}
          >
            {opt.label}
          </div>
        </div>
      ))}
      <div
        className="hover:bg-gray-100 rounded-lg p-2 cursor-pointer"
        onClick={onStartChat}
      >
        <Edit />
      </div>
    </div>
  );
}
