type Props = {
  mode?: 'default' | 'hover' | 'active';
  size?: number;
};

function HomeIcon({ mode = 'default', ...props }: Props) {
  return (
    <>
      {mode === 'default' || mode === 'hover' ? (
        <HomeIconDefault {...props} />
      ) : (
        <HomeIconActive {...props} />
      )}
    </>
  );
}

function HomeIconDefault({ size = 24 }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <g
        fill="#727272"
        fillRule="evenodd"
        clipPath="url(#clip0_12225_7550)"
        clipRule="evenodd"
      >
        <path d="M6.667 12.5c0-.92.746-1.666 1.666-1.666h3.333c.921 0 1.667.746 1.667 1.666v4.167c0 .92-.746 1.666-1.667 1.666H8.333c-.92 0-1.667-.746-1.667-1.666V12.5zm5 0H8.332v4.167h3.333V12.5z"></path>
        <path d="M8.925 2.317a1.667 1.667 0 012.15 0l5.833 4.922c.375.316.592.782.592 1.273v8.155c0 .92-.746 1.666-1.667 1.666H4.167c-.92 0-1.667-.746-1.667-1.666V8.512c0-.49.217-.957.592-1.273l5.833-4.922zM10 3.59L4.167 8.512v8.155h11.666V8.512L10 3.59z"></path>
      </g>
      <defs>
        <clipPath id="clip0_12225_7550">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function HomeIconActive({ size = 24 }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <g
        fill="#FF7C11"
        fillRule="evenodd"
        clipPath="url(#clip0_12225_7675)"
        clipRule="evenodd"
      >
        <path d="M6.667 12.5c0-.92.746-1.666 1.666-1.666h3.333c.921 0 1.667.746 1.667 1.666v4.167c0 .92-.746 1.666-1.667 1.666H8.333c-.92 0-1.667-.746-1.667-1.666V12.5zm5 0H8.332v4.167h3.333V12.5z"></path>
        <path d="M8.925 2.317a1.667 1.667 0 012.15 0l5.833 4.922c.375.316.592.782.592 1.273v8.155c0 .92-.746 1.666-1.667 1.666H4.167c-.92 0-1.667-.746-1.667-1.666V8.512c0-.49.217-.957.592-1.273l5.833-4.922zM10 3.59L4.167 8.512v8.155h11.666V8.512L10 3.59z"></path>
      </g>
      <defs>
        <clipPath id="clip0_12225_7675">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default HomeIcon;
