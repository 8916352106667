import { useMemo } from 'react';
import { twMerge as cx } from 'tailwind-merge';

import { BBBSelect } from '@/components/ui';
import useAddCustomerTags from '@/hooks/customers/customer/useAddCustomerTags';
import useCreateTag from '@/hooks/customers/customer/useCreateTag';
import useCustomer from '@/hooks/customers/customer/useCustomer';
import useTags from '@/hooks/customers/customer/useTags';

type Props = {
  customerId: string;
  label?: string;
  placeholder?: string;
  className?: string;
};

function AddTag({
  customerId,
  label,
  placeholder = 'Enter tag name',
  className,
}: Props) {
  const { data: customerData } = useCustomer(customerId);

  const {
    data: tagsData,
    isInitialLoading: loadingTags,
    hasNextPage,
    fetchNextPage,
  } = useTags();

  const { mutate: createTag } = useCreateTag();
  const { mutate: addCustomerTags } = useAddCustomerTags();

  const flattenedTags = tagsData?.pages.flatMap((page) => page.data);

  const tagValues = useMemo(
    () =>
      customerData?.customerTags?.map((tag) => ({
        name: tag.tagName,
        companyId: tag.tagCompanyId,
      })) || [],
    [customerData?.customerTags]
  );

  return (
    <BBBSelect
      placeholder={placeholder}
      isMulti
      options={flattenedTags}
      optionLabel="name"
      optionValue="name"
      loading={loadingTags}
      label={label}
      isPaginated
      hasMore={!!hasNextPage}
      fetchNext={fetchNextPage}
      isCreatable
      onCreateOption={(val) =>
        createTag(
          { name: val },
          {
            onSuccess: (data) => {
              addCustomerTags({
                customerId,
                tagName: data.name,
              });
            },
          }
        )
      }
      value={tagValues}
      onValueChange={(opt, selected) =>
        addCustomerTags({
          customerId,
          tagName: selected!.name,
        })
      }
      isSearchable
      containerClassName={cx('mb-5', className)}
      fallbackToPlaceholder
    />
  );
}

export default AddTag;
