import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ArrowLeft, Edit2, MoreHorizontal } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnimatePresence, motion } from 'framer-motion';
import {
  useDeleteFlow,
  useDuplicateFlow,
  useFlow,
  useUpdateFlow,
} from 'hooks/bitCRM/automation/automation-new';
import useQuerySearchParams from 'hooks/common/url/useQuerySearchParams';
import useConfirmationModal from 'hooks/common/useConfirmationModal';
import useOutsideAlerter from 'hooks/common/useOutsideAlerterv2';
import { useConfirmLeaveModal, useDiscardOrLeaveModal } from 'hooks/modal';
import { isEqual } from 'lodash-es';
import { twMerge as cx } from 'tailwind-merge';
import { AutomationNew } from 'types/bitCRM/automation-new';
import { DeepPartial } from 'types/utils/deepPartial';
import * as yup from 'yup';
import { shallow } from 'zustand/shallow';
import { autoLayout } from 'utils/flow';
import useStore, { RFState } from '../../store';
import { ActionData, TriggerData } from '../../types';

import { BBBButton, BBBCard, BBBTooltip } from '@/components/ui';
import { BBBSpinner } from '@/components/ui';
import {
  mapPrebuiltKeyToType,
  prebuiltAutomation,
} from '@/constants/bitCRM/automation-new';
import {
  useWhatsappBusinessIntegration,
  useWhatsappCloudIntegration,
} from '@/hooks/bitChat/integration/integrations';
import { useShopifyIntegrationExtra } from '@/hooks/bitCRM/integration/integrations';

const defaultValue = {
  name: 'Untitled automation',
};

const flowInfoSchema = yup.object({
  name: yup.string().required().label('Flow name'),
  id: yup.string(),
});

type FlowInfoForm = Pick<AutomationNew, 'name' | 'id'>;

const selector = (state: RFState) => ({
  nodes: state.nodes,
  edges: state.edges,
  setNodes: state.setNodes,
  setEdges: state.setEdges,
  setErrorNode: state.setErrorNode,
  setErrorNode2: state.setErrorNode2,
});

export default function Action() {
  const { id } = useParams<{ id: string }>();

  const { data, isLoading: loadingFlow } = useFlow(id);

  const { data: shopifyIntegration, isLoading: loadingShopifyIntegration } =
    useShopifyIntegrationExtra();
  const hasShopifyIntegration = !!shopifyIntegration;

  const { data: waCloudIntegration, isLoading: loadingWaCloudIntegration } =
    useWhatsappCloudIntegration();
  const hasCloudIntegration = !!waCloudIntegration;

  const {
    data: waBusinessIntegration,
    isLoading: loadingWaBusinessIntegration,
  } = useWhatsappBusinessIntegration();
  const hasBusinessIntegration = !!waBusinessIntegration;

  if (
    loadingShopifyIntegration ||
    loadingWaCloudIntegration ||
    loadingWaBusinessIntegration ||
    (id !== 'new' && loadingFlow)
  ) {
    return (
      <div className="h-full flex flex-col justify-center items-center">
        <div>
          <BBBSpinner />
        </div>
        <div>Loading scenario</div>
      </div>
    );
  }

  return (
    <_Action
      hasBusinessIntegration={hasBusinessIntegration}
      hasShopifyIntegration={hasShopifyIntegration}
      hasCloudIntegration={hasCloudIntegration}
      data={data}
    />
  );
}

function _Action({
  hasBusinessIntegration,
  hasCloudIntegration,
  hasShopifyIntegration,
  data,
}: {
  hasBusinessIntegration: boolean;
  hasCloudIntegration: boolean;
  hasShopifyIntegration: boolean;
  data: AutomationNew | undefined;
}) {
  const { nodes, edges, setNodes, setEdges, setErrorNode, setErrorNode2 } =
    useStore(selector, shallow);

  const { id } = useParams<{ id: string }>();

  const search = useQuerySearchParams();

  const prefillType = search.get('prefill_type');

  const enabled = id !== 'new';

  const [showOptions, setShowOptions] = useState(false);

  const flowInfoFromApi = useMemo<DeepPartial<FlowInfoForm> | undefined>(() => {
    return {
      name: data?.name || '',
      id: data?.id,
    };
  }, [data]);

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<FlowInfoForm>({
    resolver: yupResolver(flowInfoSchema),
    defaultValues: flowInfoFromApi,
  });

  const { mutate: updateFlow, isLoading: loadingUpdate } = useUpdateFlow();

  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(
    id === 'new'
  );

  const nodesFromApi = useMemo(
    () =>
      data?.nodes.map((node) => ({
        id: node.id,
        type: node.type || undefined,
        position: { x: 0, y: 0 },
        data: node.data,
        parentNode: node.parentNode,
      })) ?? [],
    [data?.nodes]
  );

  const edgesFromApi = useMemo(
    () =>
      data?.edges.map((edge) => ({
        id: edge.id,
        source: edge.source,
        target: edge.target,
        type: edge.type || 'custom',
        data: edge.data,
      })) ?? [],
    [data?.edges]
  );

  useEffect(() => {
    if (enabled) {
      if (data) {
        reset(flowInfoFromApi);

        const newNodes = autoLayout(nodesFromApi, edgesFromApi);

        setNodes(newNodes);

        setEdges(edgesFromApi);
        setIsInitialLoadComplete(true);
      }
    } else {
      const prebuilt =
        prebuiltAutomation[
          prefillType ? mapPrebuiltKeyToType[prefillType] : 'default'
        ];

      reset({
        name: prebuilt.title,
      });

      const newNodes = autoLayout(prebuilt.nodes, prebuilt.edges);

      setNodes(newNodes);
      setEdges(prebuilt.edges);
    }
  }, [
    data,
    edgesFromApi,
    enabled,
    flowInfoFromApi,
    nodesFromApi,
    prefillType,
    reset,
    setEdges,
    setNodes,
  ]);

  useEffect(() => {
    setErrorNode();
  }, [nodes, setErrorNode]);

  useEffect(() => {
    const errorNodes: [string, boolean][] = nodes.map((node) => {
      let invalid = false;

      if (node.type === 'trigger') {
        if (node.data?.source === 'shopify') {
          if (!hasShopifyIntegration) {
            invalid = true;
          }
        }
      } else if (node.type === 'action') {
        if (node.data?.source === 'WHATSAPP_META') {
          if (!hasCloudIntegration) {
            invalid = true;
          }
        }

        if (node.data?.source === 'WHATSAPP') {
          if (!hasBusinessIntegration) {
            invalid = true;
          }
        }
      }

      return [node.id, invalid];
    });

    setErrorNode2(errorNodes);
  }, [
    nodes,
    hasShopifyIntegration,
    hasCloudIntegration,
    hasBusinessIntegration,
    setErrorNode2,
  ]);

  const isFormEqual = useMemo(() => {
    if (loadingUpdate || isInitialLoadComplete) {
      const formData = {
        ...watch(),
        nodes: nodes.map((node) => {
          const { data, id, type } = node;

          return {
            data,
            id,
            type,
          };
        }),
        edges: edges.map(({ id, source, target, type, data }) => ({
          id,
          source,
          target,
          type: type || 'custom',
          data: data || null,
        })),
      };

      return isEqual(formData, {
        ...flowInfoFromApi,
        nodes: nodesFromApi.map(({ data, id, type }) => ({
          data,
          id,
          type,
        })),
        edges: edgesFromApi,
      });
    }

    return true;
  }, [
    loadingUpdate,
    isInitialLoadComplete,
    watch(),
    nodes,
    edges,
    flowInfoFromApi,
    nodesFromApi,
    edgesFromApi,
  ]);

  const [handleDiscard] = useDiscardOrLeaveModal({
    isFormEqual,
    module: id !== 'new' ? 'changes' : 'automation',
    onSave: (hide, leaveCb) => {
      submit({
        onSuccess: () => {
          hide();
          leaveCb?.();
        },
      });
    },
    loadingSave: loadingUpdate,
  });

  const submit = (params?: {
    onSuccess?: (data: AutomationNew) => void;
    skipRedirect?: boolean;
  }) => {
    handleSubmit(async (_payload) => {
      const { ..._flowInfo } = _payload;

      const payload = {
        ..._flowInfo,
        nodes: nodes.map((node, index) => {
          const data = node.data as TriggerData | ActionData;

          return {
            id: node.id,
            type: node.type,
            data,
            x: node.position.x,
            y: node.position.y,
            parentNode: node.parentNode,
            index,
          };
        }),
        edges: edges.map((edge, index) => ({
          id: edge.id,
          source: edge.source,
          target: edge.target,
          type: edge.type,
          data: edge.data || null,
          index,
        })),
      };

      setIsInitialLoadComplete(false);

      updateFlow(
        {
          ...data,
          ...payload,
          skipRedirect: params?.skipRedirect,
        },
        {
          onSuccess: params?.onSuccess,
          onSettled: () => {
            setIsInitialLoadComplete(true);
          },
        }
      );
    })();
  };

  return (
    <div className="bg-white p-6 flex items-center gap-4" id="flow-info-ref">
      <Link
        to={`/bitcrm/automation-new`}
        className="w-8 h-8 rounded border border-primary-border flex justify-center items-center cursor-pointer"
      >
        <ArrowLeft size={16} />
      </Link>
      {!isInitialLoadComplete ? (
        <Skeleton width={100} />
      ) : (
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <NameInput
              value={field.value}
              onValueChange={field.onChange}
              errors={errors.name?.message}
            />
          )}
        />
      )}

      <div className="grow" />
      <BBBButton
        variant="secondary"
        text={id !== 'new' ? 'Discard changes' : 'Discard automation'}
        disabled={isFormEqual}
        onClick={() => handleDiscard()}
      />
      <BBBButton
        text={id !== 'new' ? 'Save changes' : 'Save automation'}
        disabled={isFormEqual}
        loadingState={loadingUpdate}
        onClick={() => {
          submit();
        }}
      />
      <div className="relative">
        <MoreHorizontal
          className="cursor-pointer"
          onClick={() => {
            setShowOptions(true);
          }}
        />
        <AnimatePresence>
          {showOptions && (
            <OptionsCard
              isFormEqual={isFormEqual}
              onClose={() => setShowOptions(false)}
              submit={submit}
              loadingSave={loadingUpdate}
            />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

function OptionsCard({
  onClose,
  isFormEqual,
  submit,
  loadingSave,
}: {
  onClose: () => void;
  isFormEqual: boolean;
  submit: (params?: {
    onSuccess?: (data: AutomationNew) => void;
    skipRedirect?: boolean;
  }) => void;
  loadingSave: boolean;
}) {
  const { id } = useParams<{ id: string }>();

  const optionsRef = useRef<HTMLDivElement | null>(null);
  const reactFlowRef = document.querySelector('.react-flow__pane')!;

  const { mutate: deleteFlow } = useDeleteFlow();
  const { mutate: duplicateFlow } = useDuplicateFlow();

  const confirm = useConfirmationModal();

  useOutsideAlerter(optionsRef, onClose, true, reactFlowRef);
  useOutsideAlerter(optionsRef, onClose, true);

  const leaveModal = useConfirmLeaveModal();

  return (
    <motion.div
      className="absolute right-0 z-20"
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
    >
      <BBBCard className="p-0 md:p-0 w-56" ref={optionsRef}>
        <div
          className="px-5 pb-2 pt-3 cursor-pointer transition-colors rounded-tl-xl rounded-tr-xl hover:bg-secondary-surface"
          onClick={() => {
            if (isFormEqual) {
              duplicateFlow(id);
            } else {
              leaveModal({
                module: 'automation',
                onSaveProgress: (hide) => {
                  submit({
                    onSuccess: (data) => {
                      hide();
                      duplicateFlow(data.id);
                    },
                    skipRedirect: true,
                  });
                },
                cancelText: 'Cancel',
                loadingSave,
              });
            }

            onClose();
          }}
        >
          Duplicate automation
        </div>
        {id !== 'new' && (
          <div
            className="px-5 pb-3 pt-2 hover:bg-secondary-surface transition-colors rounded-bl-2xl rounded-br-2xl text-danger-main cursor-pointer"
            onClick={() => {
              confirm({
                title: 'Delete automation',
                description:
                  'Are you sure want to delete this automation? Once you delete the action cannot be undone',
                onAccept: (hide) => {
                  deleteFlow(id, {
                    onSuccess: () => {
                      hide();
                    },
                  });
                },
                deleteModal: true,
                submitText: 'Delete automation',
              });
            }}
          >
            Delete automation
          </div>
        )}
      </BBBCard>
    </motion.div>
  );
}

function NameInput({
  value,
  errors,
  onValueChange,
}: {
  value: string;
  errors?: string;
  onValueChange: (val: string) => void;
}) {
  const [editing, setEditing] = useState(false);
  const [nameWidth, setNameWidth] = useState<number>();
  const [localValue, setLocalValue] = useState(value);

  const nameInputRef = useRef<HTMLInputElement | null>(null);
  const nameRef = useRef<HTMLDivElement | null>(null);
  const flowInputRef = useRef<HTMLDivElement | null>(null);
  const reactFlowRef = document.querySelector('.react-flow__pane')!;

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const blurNameInput = useCallback(
    (target?: HTMLElement) => {
      setEditing(false);

      if (target?.closest('button')?.id.startsWith('headlessui-switch')) return;

      setTimeout(() => {
        if (!localValue) {
          onValueChange(defaultValue.name);
        } else {
          onValueChange(localValue);
        }
      }, 0);
    },
    [localValue, onValueChange]
  );

  useOutsideAlerter(flowInputRef, blurNameInput, undefined, reactFlowRef);
  useOutsideAlerter(flowInputRef, blurNameInput, undefined);

  return (
    <div
      className={cx(
        'cursor-pointer flex items-center gap-1',
        !editing && 'cursor-pointer'
      )}
      onClick={() => {
        if (!editing) {
          setEditing(true);
          setNameWidth(nameRef.current?.clientWidth);
          setTimeout(() => {
            nameInputRef.current?.focus();
          }, 0);
        }
      }}
      ref={flowInputRef}
    >
      <div>
        {!editing ? (
          localValue ? (
            <BBBTooltip
              targetRef={nameRef}
              content={localValue}
              position="bottom"
            >
              <div
                ref={nameRef}
                className="text-2xl max-w-[256px] truncate border-b-[2px] border-transparent text-neutral-60 font-medium"
              >
                {localValue}
              </div>
            </BBBTooltip>
          ) : (
            <div className="text-2xl max-w-[256px] truncate border-b-[2px] border-transparent text-neutral-60/50 font-medium">
              Input automation
            </div>
          )
        ) : (
          <input
            className="text-2xl border-b-[2px] border-secondary-main font-medium max-w-[256px]"
            style={{ width: nameWidth }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                blurNameInput();
              }
            }}
            value={localValue}
            onChange={({ target: { value } }) => setLocalValue(value)}
            ref={nameInputRef}
            placeholder="Input automation"
          />
        )}
        {errors && <div className="text-danger-main">{errors}</div>}
      </div>
      {!editing && (
        <div className="flex-none">
          <Edit2 size={14} />
        </div>
      )}
    </div>
  );
}
