import { Link } from 'react-router-dom';
import { ButtonVariant } from '../BBBButton/BBBButton';

import { BBBButton } from '@/components/ui/BBBButton';
import useResponsive from '@/hooks/common/useResponsive';
import TrashWithTransition from '@/pages/BitChat/Chatbot/components/TrashWithTransition';
import { cn } from '@/utils/styles';

export type BBBFooterActionProps = {
  onCancel?: () => void;
  onSave?: () => void;
  onDelete?: () => void;
  loadingSave?: boolean;
  disableSave?: boolean;
  withoutDelete?: boolean;
  withoutCancel?: boolean;
  saveLabel?: string | React.ReactNode;
  submitLink?: string;
  submitHref?: string;
  discardLabel?: string | React.ReactNode;
  deleteLabel?: string | React.ReactNode;
  containerClassName?: string;
  deleteModal?: boolean;
  className?: string;
  submitClassName?: string;
  submitVariant?: ButtonVariant;
  additionalButton?: ({
    onCancel,
    onSave,
    onDelete,
  }: Partial<
    Pick<BBBFooterActionProps, 'onCancel' | 'onSave' | 'onDelete'>
  >) => React.ReactNode;
  deleteWithButton?: boolean;
  overrideSubmitBtn?: boolean;
  cancelHref?: string;
  centerFooter?: boolean;
  overrideCancelBtn?: boolean;
};

export default function BBBFooterAction(props: BBBFooterActionProps) {
  const isMobile = useResponsive('sm');

  const {
    onCancel = () => ({}),
    onSave = () => ({}),
    onDelete = () => ({}),
    loadingSave = false,
    cancelHref,
    withoutDelete,
    withoutCancel,
    deleteModal,
    saveLabel = deleteModal ? 'Delete' : 'Save',
    submitClassName,
    disableSave,
    discardLabel = 'Discard',
    containerClassName,
    deleteLabel,
    className,
    submitLink,
    submitHref,
    additionalButton,
    deleteWithButton,
    submitVariant,
    overrideSubmitBtn,
    centerFooter,
    overrideCancelBtn,
  } = props;

  const DeleteAction = withoutDelete ? (
    <></>
  ) : typeof deleteLabel !== 'undefined' ? (
    <>{deleteLabel}</>
  ) : deleteWithButton ? (
    <BBBButton variant="danger-outline" text="Delete" onClick={onDelete} />
  ) : (
    <TrashWithTransition onClick={onDelete} />
  );

  const _SubmitButton = (
    <BBBButton
      width="full"
      variant={deleteModal ? 'danger' : submitVariant}
      className={cn(!isMobile && submitClassName)}
      onClick={() => {
        onSave();
      }}
      disabled={disableSave}
      loadingState={loadingSave}
    >
      {saveLabel}
    </BBBButton>
  );

  const SubmitButton = overrideSubmitBtn ? (
    <>{saveLabel}</>
  ) : (
    <>
      {!disableSave ? (
        submitLink ? (
          <Link to={submitLink} className={cn(isMobile && 'w-full')}>
            {_SubmitButton}
          </Link>
        ) : submitHref ? (
          <a href={submitHref} target="_blank" rel="noreferrer">
            {_SubmitButton}
          </a>
        ) : (
          _SubmitButton
        )
      ) : (
        _SubmitButton
      )}
    </>
  );

  const __CancelButton = (
    <BBBButton
      text={discardLabel}
      variant="secondary"
      width="full"
      onClick={onCancel}
      className="mr-buttonHorizontal"
    >
      {discardLabel}
    </BBBButton>
  );

  const _CancelButton = overrideCancelBtn ? (
    <>{discardLabel}</>
  ) : cancelHref ? (
    <a href={cancelHref} target="_blank" rel="noreferrer">
      {__CancelButton}
    </a>
  ) : (
    <>{__CancelButton}</>
  );

  const CancelButton = withoutCancel ? null : _CancelButton;

  return isMobile ? (
    <div className={cn('flex gap-2.5 items-center mt-5', containerClassName)}>
      {!withoutDelete && (
        <div className="flex-none p-2 border border-red-500 rounded">
          {DeleteAction}
        </div>
      )}
      {SubmitButton}
    </div>
  ) : (
    <div className="m-5">
      <div className={cn('flex gap-4 items-center', containerClassName)}>
        {DeleteAction}
        <div
          className={cn(
            'flex items-center gap-2',
            centerFooter ? 'mx-auto' : 'ml-auto',
            className
          )}
        >
          {CancelButton}
          {SubmitButton}
        </div>
      </div>
      {typeof additionalButton !== 'undefined' &&
        additionalButton?.({
          onCancel,
          onSave,
          onDelete,
        })}
    </div>
  );
}
