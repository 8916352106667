import { useMemo } from 'react';
import { mapStatusToBadgeProps } from '../../WhatsappApiProfile/ChatInitationTemplate';

import { BBBRenderHtml, BBBTooltip } from '@/components/ui';
import BBBBadge from '@/components/ui/BBBBadge/BBBBadge';
import { HeadersTypes } from '@/components/ui/BBBTableV2/BBBTableV2.type';
import { WACloudTemplate } from '@/types/whatsApp/settings';
import { formatDate2 } from '@/utils/common/date';

const useColumn = () => {
  return useMemo<HeadersTypes<WACloudTemplate>>(() => {
    return [
      {
        accesor: 'name',
        isSortable: true,
        renderHeader: () => 'Template name',
        render: (info) => (
          <div className="line-clamp-1">{info.name || '-'}</div>
        ),
      },
      {
        accesor: 'message',
        renderHeader: () => 'Message',
        columnWidth: '38%',
        isSortable: true,
        render: (info) => {
          return (
            <div className="line-clamp-1 text-primary-main whitespace-pre-line">
              <BBBTooltip
                content={<BBBRenderHtml content={info.message} />}
                show={info.message.length > 50 ? true : false}
              >
                <BBBRenderHtml content={info.message} />
              </BBBTooltip>
            </div>
          );
        },
      },
      {
        accesor: 'status',
        renderHeader: () => 'Status',
        isSortable: true,
        render: (info) => {
          const status = info.status;
          return (
            <>
              {status ? (
                <BBBBadge
                  className="px-2 py-1 text-sm"
                  type={mapStatusToBadgeProps[status].type}
                  text={mapStatusToBadgeProps[status].text}
                />
              ) : (
                <BBBBadge
                  className="px-2 py-1 text-sm"
                  type="neutral"
                  text="Draft"
                />
              )}
            </>
          );
        },
      },
      {
        accesor: 'updatedAt',
        renderHeader: () => 'Last Updated',
        isSortable: true,
        sortOrder: 'desc',
        render: (info) => {
          const updatedAt = info.updatedAt;
          return (
            <>
              {!updatedAt ? null : (
                <p className="text-primary-main">
                  {formatDate2(updatedAt, '12-hour', 'chat')}
                </p>
              )}
            </>
          );
        },
      },
    ];
  }, []);
};

export default useColumn;
