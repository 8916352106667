import { useState } from 'react';
import { BBBModal } from './ui/BBBModal';
import { BBBCard } from './ui';

import ComposeIcon from '@/assets/icons/ComposeIcon';
import ShopifyIcon3 from '@/assets/icons/ShopifyIcon3';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { setChosePaymentMethodModal } from '@/stores/common';
import { cn } from '@/utils/styles';

export default function ChoosePaymentMethodModal() {
  const chosePaymentMethodModalMeta = useAppSelector(
    (s) => s.common.chosePaymentMethodModal
  );

  if (!chosePaymentMethodModalMeta) return null;

  return <_ChoosePaymentMethodModal />;
}

function _ChoosePaymentMethodModal() {
  const { onSave } = useAppSelector((s) => s.common.chosePaymentMethodModal!);

  const dispatch = useAppDispatch();

  const [chosenMethod, setChosenMethod] = useState<'stripe' | 'shopify' | null>(
    null
  );

  return (
    <BBBModal
      show
      title="Select payment method"
      footer
      submitText="Continue to payment"
      size="xl"
      handleSave={() => {
        onSave(chosenMethod!);
        dispatch(setChosePaymentMethodModal(null));
      }}
      disableSave={!chosenMethod}
      onHide={() => {
        dispatch(setChosePaymentMethodModal(null));
      }}
    >
      <BBBCard
        className={cn(
          'flex items-center gap-2 mb-5 hover:border-[#FF7C11] outline outline-1 outline-transparent hover:outline-[#FED5BF80] cursor-pointer',
          chosenMethod === 'shopify' && 'border-[#FF7C11] outline-[#FED5BF80]'
        )}
        onClick={() => setChosenMethod('shopify')}
      >
        <div className="bg-highlight-main border-highlight-secondary w-10 h-10 rounded-lg flex items-center justify-center">
          <ShopifyIcon3 />
        </div>
        Shopify
      </BBBCard>
      <BBBCard
        className={cn(
          'flex items-center gap-2 mb-5 hover:border-[#FF7C11] outline outline-1 outline-transparent hover:outline-[#FED5BF80] cursor-pointer',
          chosenMethod === 'stripe' && 'border-[#FF7C11] outline-[#FED5BF80]'
        )}
        onClick={() => setChosenMethod('stripe')}
      >
        <div className="bg-highlight-main border-highlight-secondary w-10 h-10 rounded-lg flex items-center justify-center">
          <ComposeIcon />
        </div>
        Pay with cards
      </BBBCard>
    </BBBModal>
  );
}
