import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';
import { SocialConfig } from '@/types/bitLogin/v2';
import { toast } from '@/utils/common/toast';

const useUpsertSocialConfig = () => {
  const { data } = useShopifyIntegrationByApp('BITLOGIN');
  const queryClient = useQueryClient();

  return useMutation(
    (payload: Partial<SocialConfig>) =>
      services.bitLogin.configs.upsertSocialConfig(data?.domain, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bitlogin-config']);
        queryClient.invalidateQueries(['bitlogin-config-provider']);
        toast.success('Successfully update config');
      },
    }
  );
};

export default useUpsertSocialConfig;
