import { ReactNode } from 'react';
import { EditorState } from 'draft-js';
import { pick } from 'lodash-es';
import * as yup from 'yup';

import { BBBFileUpload, BBBSelect } from '@/components/ui';
import { chatbotTypes } from '@/constants/bitChat/chatbot';
import { acceptedCrmFileTypes } from '@/constants/crm';
import CampaignMessage from '@/pages/BitCRM/components/CampaignMessage';
import { FileType } from '@/types/utils/file';
import { Nullable } from '@/types/utils/nullable';
import { emptyEditor } from '@/utils/common/rich';
import { richValidation } from '@/utils/common/validation';

export type MessageTypeValue = Partial<{
  messageType: { label: string; value: string } | undefined | null;
  userMessage: EditorState;
  files: Nullable<FileType>;
}>;

export const messageTypeValidation = (
  modules: (keyof MessageTypeValue)[] = ['files', 'userMessage', 'messageType']
) =>
  pick(
    {
      messageType: yup.mixed().required('Message Type is required'),
      userMessage: yup.mixed<EditorState>().when('messageType', {
        is: (option: MessageTypeValue['messageType']) =>
          modules.includes('messageType') ? option?.value === 'TEXT' : true,
        then: () => richValidation,
      }),
      files: yup
        .mixed<FileType>()
        .label('Media')
        .when('messageType', {
          is: (option: MessageTypeValue['messageType']) =>
            option?.value === 'MEDIA',
          then: (rule) => rule.required(),
        }),
    },
    modules
  );

export const defaultMessageType: MessageTypeValue = {
  messageType: {
    label: 'Text',
    value: 'TEXT',
  },
  userMessage: emptyEditor,
  files: null,
};

type Props = {
  modules?: (keyof MessageTypeValue)[];
  placeholder?: string;
  value: MessageTypeValue;
  onValueChange: (val: MessageTypeValue) => void;
  errors: Partial<Record<keyof MessageTypeValue, string>>;
  label?: ReactNode;
  containerClassName?: string;
};

export default function MessageType({
  value,
  onValueChange,
  modules = ['files', 'userMessage', 'messageType'],
  placeholder,
  errors,
  label,
  containerClassName,
}: Props) {
  return (
    <div className={containerClassName}>
      {modules.includes('messageType') && (
        <BBBSelect
          options={chatbotTypes}
          placeholder="Choose message type for the reply"
          optionLabel="label"
          label="Message Type"
          optionValue="value"
          value={value.messageType}
          onValueChange={(opt) => {
            onValueChange({
              ...value,
              messageType: opt,
              files: null,
            });
          }}
          error={errors.messageType}
          containerClassName="mb-3"
        />
      )}

      {modules.includes('userMessage') && (
        <CampaignMessage
          editorState={value.userMessage}
          onChangeEditorState={(message) =>
            onValueChange({ ...value, userMessage: message })
          }
          source="whatsapp-business"
          onlyCustomer
          placeholder={placeholder}
          withoutDynamicVariable
          error={errors.userMessage}
          title={label}
        />
      )}

      {modules.includes('files') && value.messageType?.value === 'MEDIA' && (
        <>
          <BBBFileUpload
            files={value.files}
            onChangeFile={(val) => onValueChange({ ...value, files: val })}
            isSingle
            accept={acceptedCrmFileTypes}
            placeholder="Uploading your media..."
          />
          <div className="text-red-500">{errors.files}</div>
        </>
      )}
    </div>
  );
}
