import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';
import PricingInfo from './PricingInfo/PricingInfo';

import LinkOutIcon from '@/assets/icons/LinkOutIcon';
import BetaBadge from '@/components/Badge/BetaBadge';
import NewBadge from '@/components/Badge/NewBadge';
import { Link } from '@/components/ui/Link';
import { appType } from '@/constants/common/app';
import { sidebarDataTypeSectionV2 } from '@/constants/layouts/sidebarData';
import useResponsive from '@/hooks/common/useResponsive';
import { useOnboardingCompletion as useOnboardingCompletionBitAi } from '@/hooks/onboarding/bitai';
import { useOnboardingCompletion as useOnboardingCompletionBitChat } from '@/hooks/onboarding/bitchat';
import { useOnboardingCompletion as useOnboardingCompletionBitCRM } from '@/hooks/onboarding/bitcrm';
import { AppType } from '@/types/systemLogin';
import { cn } from '@/utils/styles';

const pagesWithOnboarding = ['/bitchat', '/bitcrm', '/bitai'];

const quickLinks: Partial<
  Record<AppType, { title: string; link: string; internal?: boolean }[]>
> = {
  BITLOGIN: [
    {
      title: 'Disable hcaptcha',
      link: 'https://www.bitbybit.studio/knowledge-base/how-to-turn-off-recaptcha-on-shopify',
    },
    {
      title: 'Change Shopify login settings',
      link: 'https://app.bitbybit.studio',
    },
  ],
  BITAPP: [
    {
      title: 'Download app previewer',
      link: 'https://apps.apple.com/us/app/bitapp-shopify-app-previewer/id1614570301',
    },
  ],
  BITCRM: [
    {
      title: 'Template',
      link: '/misc',
      internal: true,
    },
    {
      title: 'Chatbot',
      link: '/bitchat/flows',
      internal: true,
    },
    {
      title: 'WhatsApp form',
      link: '/bitchat/whatsapp-form',
      internal: true,
    },
    {
      title: 'Integrate WhatsApp API',
      link: 'https://www.bitbybit.studio/knowledge-base/how-to-create-and-connect-whatsapp-cloud-api-with-bitbybit',
    },
  ],
  BITCHAT: [
    {
      title: 'Campaign',
      link: '/bitcrm/campaign',
      internal: true,
    },
    {
      title: 'WhatsApp API Settings',
      link: '/misc/whatsapp-api-settings',
      internal: true,
    },
    {
      title: 'Invite team',
      link: 'https://www.bitbybit.studio/knowledge-base/understanding-users-role-authority-super-admin-admin-agents-regular-user',
    },
    {
      title: 'Integrate WhatsApp API',
      link: 'https://www.bitbybit.studio/knowledge-base/how-to-create-and-connect-whatsapp-cloud-api-with-bitbybit',
    },
  ],
};

const mapAppRouteToAppType: Record<string, AppType> = {
  bitapp: 'BITAPP',
  bitlink: 'BITLINK',
  bitchat: 'BITCHAT',
  bitlogin: 'BITLOGIN',
  bitcrm: 'BITCRM',
  bitai: 'BITAI',
};

export default function SidebarSubNav({
  data,
  parent,
  onClick,
  app,
}: {
  parent?: sidebarDataTypeSectionV2;
  data: sidebarDataTypeSectionV2[];
  onClick?: () => void;
  app: string;
}) {
  const { pathname, search } = useLocation();

  if (!parent) return null;

  const appType = mapAppRouteToAppType[app];

  const appQuickLinks = quickLinks[appType];

  return (
    <>
      <div
        className={cx('flex mt-14 mb-4 mx-4 px-2 py-1.5 gap-4 items-center')}
      >
        <div className="font-semibold">{parent.title}</div>
      </div>
      {pagesWithOnboarding.includes(parent.path) && (
        <OnboardingProgress path={parent.path} />
      )}
      {data
        .filter((sub) => !sub.disabled)
        .map((sub) => {
          const isSubPath = isPrefixSplitBySlash(sub.path, pathname);

          return (
            <Link
              className={cx(
                'mx-4  rounded-md flex items-center gap-2 px-2 py-1.5 mb-1 transition-[background]',
                isSubPath
                  ? 'bg-[#FFE6D8] '
                  : 'hover:bg-neutral-200 cursor-pointer'
              )}
              key={sub.path}
              to={formatPricingRedirection(sub.path, pathname, search)}
              onClick={onClick}
            >
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={cx(
                  'flex items-center gap-2',
                  isSubPath && 'font-semibold'
                )}
              >
                {sub.title}
              </motion.span>
              {sub.isBeta && <BetaBadge />}
              {sub.isNew && <NewBadge />}
            </Link>
          );
        })}

      {appQuickLinks && (
        <div className="my-8 mx-4">
          <div className="font-bold px-2 py-1.5">Quick links</div>
          {appQuickLinks.map(({ link, title, internal }) => {
            if (internal) {
              return (
                <Link
                  className="transition-colors rounded-md hover:bg-neutral-200 cursor-pointer px-2 py-1.5 flex items-center gap-2 text-neutral-500 font-medium"
                  key={link}
                  to={link}
                >
                  <span>{title}</span>
                  {!internal && <LinkOutIcon color="#9A9A9A" size={10} />}
                </Link>
              );
            }

            return (
              <a
                href={link}
                className="transition-colors rounded-md hover:bg-neutral-200 cursor-pointer px-2 py-1.5 flex items-center gap-2 text-neutral-500 font-medium"
                key={link}
                target="_blank"
                rel="noreferrer"
              >
                <span>{title}</span>
                <LinkOutIcon color="#9A9A9A" size={10} />
              </a>
            );
          })}
        </div>
      )}

      <div className="grow"></div>
      <PricingInfo app={appType} />
    </>
  );
}

function OnboardingProgress({ path }: { path: string }) {
  if (path === '/bitchat') return <ProgressBitChat />;

  if (path === '/bitcrm') return <ProgressBitCRM />;

  if (path === '/bitai') return <ProgressBitAi />;

  return null;
}

function ProgressBitCRM() {
  const { progress, dismissed } = useOnboardingCompletionBitCRM();

  return (
    <_OnboardingProgress
      path="/bitcrm"
      progress={progress}
      dismissed={!!dismissed}
    />
  );
}

function ProgressBitChat() {
  const { progress, dismissed } = useOnboardingCompletionBitChat();

  return (
    <_OnboardingProgress
      path="/bitchat"
      progress={progress}
      dismissed={!!dismissed}
    />
  );
}

function ProgressBitAi() {
  const { progress, dismissed } = useOnboardingCompletionBitAi();

  return (
    <_OnboardingProgress
      path="/bitai"
      progress={progress}
      dismissed={!!dismissed}
    />
  );
}

function _OnboardingProgress({
  path,
  progress,
  dismissed,
}: {
  path: string;
  progress: number;
  dismissed: boolean;
}) {
  const isMobile = useResponsive('sm');
  const { pathname } = useLocation();

  if (dismissed || isMobile) return null;

  return (
    <Link to={`${path}/onboarding`} className="block p-4">
      <div
        className={cn(
          'rounded-lg px-4 py-3 flex items-center gap-4 transition-colors',
          pathname === `${path}/onboarding` && 'bg-secondary-surface'
        )}
      >
        <CircularProgress percentage={progress} />
        <div>
          <div className="font-medium mb-0.5">Get started</div>
          <div
            className={cn(
              'text-neutral-50 text-sm',
              progress === 100 && 'text-secondary-main'
            )}
          >
            {progress.toFixed(0)}% Complete
          </div>
        </div>
      </div>
    </Link>
  );
}

export function formatPricingRedirection(
  target: string,
  currentPath: string,
  currentSearch: string
) {
  if (target === '/pricing' || target === '/analytics') {
    const splitCurrentPath = currentPath.split('/')[1];
    const isCurrentPathApp = appType.includes(splitCurrentPath);

    if (isCurrentPathApp) {
      return `${target}?tab=${splitCurrentPath}`;
    }

    const searchParams = new URLSearchParams(currentSearch);

    const tab = searchParams.get('tab');

    if (tab) {
      return `${target}?tab=${tab}`;
    }

    return `${target}`;
  }

  return target;
}

function isPrefixSplitBySlash(input1: string, input2: string): boolean {
  const segments1 = input1.split('/').filter(Boolean); // Split and filter out empty strings
  const segments2 = input2.split('/').filter(Boolean); // Split and filter out empty strings

  if (segments1.length > segments2.length) {
    return false;
  }

  for (let i = 0; i < segments1.length; i++) {
    if (segments1[i] !== segments2[i]) {
      return false;
    }
  }

  return true;
}

type CircularProgressProps = {
  percentage: number;
};

const CircularProgress: React.FC<CircularProgressProps> = ({ percentage }) => {
  const radius = 24;
  const stroke = 4;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <div className="relative flex items-center justify-center w-6 h-6 flex-none">
      <svg className="absolute w-full h-full" viewBox="0 0 36 36">
        <circle
          className="text-[#9A9A9A]"
          strokeWidth={stroke}
          stroke="currentColor"
          fill="transparent"
          r={normalizedRadius}
          cx="18"
          cy="18"
        />
        <circle
          className="text-secondary-main"
          strokeWidth={stroke}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
          stroke="currentColor"
          fill="transparent"
          r={normalizedRadius}
          cx="18"
          cy="18"
          transform="rotate(-90 18 18)"
        />
      </svg>
    </div>
  );
};
