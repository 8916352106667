import React, { useEffect } from 'react';
import { X } from 'react-feather';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';

import BBBButton from '@/components/ui/BBBButton/BBBButton';
import useResponsive from '@/hooks/common/useResponsive';

export type IBBBBannerTypes = {
  variant?: 'default' | 'loading' | 'actionable';
  isCancelable?: boolean;
  cancelLabel?: string;
  acceptLabel?: string;
  onAccept?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  text?: string | React.ReactElement;
  className?: string;
  isCloseable?: boolean;
  showBanner: boolean;
  idx: number;
  id: string;
};

function BBBBanner({
  text,
  variant = 'default',
  isCancelable,
  isCloseable,
  cancelLabel = 'Discard changes',
  acceptLabel = 'Save changes',
  onAccept,
  onCancel,
  onClose,
  className,
  showBanner,
  idx,
  id,
}: IBBBBannerTypes) {
  const isMobile = useResponsive('sm');
  const isTablet = useResponsive('lg');

  const [localBannerShow, setLocalBannerShow] = React.useState(showBanner);

  useEffect(() => {
    setLocalBannerShow(showBanner);
  }, [showBanner]);

  if (!localBannerShow) return null;

  return (
    <motion.div
      className={cx(
        `absolute inset-0 h-18 bg-primary-main z-50 px-2 py-4 w-full flex justify-center items-center gap-2`,
        className
      )}
      exit={{ y: -72 }}
      animate={{
        y: 72 * idx,
      }}
      initial={{ y: -72 }}
      transition={{
        type: 'tween',
        duration: 0.3,
      }}
      style={{
        zIndex: 100 - idx,
      }}
    >
      {isCloseable && !isMobile && (
        <div
          className="absolute top-1/2 right-6 transform -translate-y-1/2 cursor-pointer"
          onClick={() => {
            setLocalBannerShow(false);
            onClose?.();
          }}
        >
          <X color="white" />
        </div>
      )}
      <div className="text-white text-center line-clamp-2">
        {text}
        {isCloseable && isTablet && (
          <p
            className="text-white underline"
            onClick={() => setLocalBannerShow(false)}
          >
            Close
          </p>
        )}
      </div>
      {variant === 'loading' ? (
        <ScaleLoader color="white" height={14} />
      ) : (
        variant === 'actionable' && (
          <>
            {isCancelable && (
              <BBBButton
                variant="secondary-outline"
                text={cancelLabel}
                onClick={() => onCancel?.()}
              />
            )}
            <BBBButton
              variant="secondary"
              text={acceptLabel}
              onClick={() => onAccept?.()}
            />
          </>
        )
      )}
    </motion.div>
  );
}

export default BBBBanner;
