import { useCallback, useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Plus } from 'react-feather';
import { shallowEqual } from 'react-redux';
import useConfirmationModal from 'hooks/common/useConfirmationModal';
import { twMerge as cx } from 'tailwind-merge';
import Banner from './Banner';
import Collections from './Collections';
import ImageGrid from './ImageGrid';

import useResponsive from '@/hooks/common/useResponsive';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { setPopup, setSelected, show } from '@/stores/bitApp';
import { ModalState } from '@/stores/bitApp/types';
import { SectionAssociation } from '@/types/bitApp/association';

type Props = {
  idx: number;
  id: number;
  type: string | null;
  data:
    | SectionAssociation['banners']
    | SectionAssociation['imageGrids']
    | SectionAssociation['collections']
    | SectionAssociation['searches'];
  selectedMobile: number | undefined;
  showAdd: boolean;
  onChangeShowAdd: (val: boolean) => void;
  onChangeSelect?: (val: number | undefined) => void;
};

export default function Section({
  idx,
  id,
  type,
  data,
  selectedMobile,
  onChangeSelect,
  onChangeShowAdd,
}: Props) {
  const draggingDroppable = useAppSelector(
    (state) => state.bitApp.draggingDroppable
  );
  const popup = useAppSelector((state) => state.bitApp.popup, shallowEqual);
  const changedPopup = useAppSelector((state) => state.bitApp.changedPopup);
  const popupError = useAppSelector((state) => state.bitApp.popupError);

  const toggleConfirmation = useConfirmationModal();
  const [hovered, setHovered] = useState<string | null>(null);

  const dispatch = useAppDispatch();
  const isMobile = useResponsive('sm');

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const handleEdit = useCallback(
    (type: keyof ModalState, id: number) => {
      dispatch(setSelected(id));
      dispatch(
        show({
          key: type,
        })
      );
      dispatch(
        setPopup({
          type,
          id,
          hasError: false,
        })
      );
    },
    [dispatch]
  );

  return (
    <>
      <Draggable
        index={idx}
        key={id}
        draggableId={id.toString()}
        isDragDisabled={idx === 0}
      >
        {(providedDraggable, snapshot) => (
          <div
            ref={(ref) => {
              providedDraggable.innerRef(ref);
              wrapperRef.current = ref;
            }}
            {...(idx !== 0 ? providedDraggable.draggableProps : {})}
            style={
              idx !== 0
                ? {
                    ...providedDraggable.draggableProps.style,
                    opacity: snapshot.isDragging ? 0.4 : 1,
                  }
                : undefined
            }
          >
            <div
              className={cx(
                `relative mb-2.5 px-4 md:mb-4 border-2 group`,
                idx === 0 ? 'mb-0 pointer-events-none' : '',
                draggingDroppable ? 'pointer-events-none' : '',
                isMobile && selectedMobile === id
                  ? 'border-secondary-main'
                  : 'border-transparent',
                popup?.type === type && popup?.id === id && !popupError
                  ? 'border-secondary-main'
                  : popup?.type === type &&
                    popup?.id === id &&
                    popupError === id
                  ? 'border-danger-main'
                  : 'border-transparent'
              )}
              onMouseEnter={() => setHovered(id.toString())}
              onMouseLeave={() => setHovered(null)}
              onClick={() => {
                if (popup?.type && changedPopup) {
                  return toggleConfirmation({
                    title: 'Unsaved changes',
                    description:
                      'You have unsaved changes. Are you sure you want to continue?',
                    submitText: 'Discard changes',
                    cancelText: 'Back to edit',
                    onAccept(hide) {
                      hide();
                      onChangeSelect?.(id);
                      handleEdit(type as keyof ModalState, id);
                    },
                  });
                } else {
                  onChangeSelect?.(id);
                  handleEdit(type as keyof ModalState, id);
                }
              }}
              {...providedDraggable.dragHandleProps}
            >
              {popup?.type === type &&
                popup?.id === id &&
                popupError === id && (
                  <div className="absolute inset-0 z-30 bg-danger-main/20" />
                )}
              {hovered === id.toString() && (
                <>
                  <div
                    className={cx(
                      !isMobile && 'opacity-0 group-hover:opacity-100',
                      'absolute top-0 z-50 transition-opacity left-0 bg-secondary-main text-white text-sm px-2'
                    )}
                  >
                    {
                      {
                        banners: 'Image slider',
                        imagegrids: 'Circle image grid',
                        collections: 'Collections',
                      }[type as string]
                    }
                  </div>
                  <div className="absolute inset-0 z-30 bg-secondary-main/20" />
                </>
              )}

              {type === 'banners' && (
                <Banner data={data as SectionAssociation['banners']} />
              )}

              {type === 'imagegrids' && (
                <ImageGrid data={data as SectionAssociation['imageGrids']} />
              )}

              {type === 'collections' && (
                <Collections data={data as SectionAssociation['collections']} />
              )}
            </div>
            {isMobile && selectedMobile === id && (
              <div
                onClick={() => {
                  onChangeShowAdd(true);
                }}
                className="border border-dashed gap-3 mx-3 flex justify-center items-center py-4 rounded-lg text-gray-400 my-1"
              >
                <div>Add section</div>
                <Plus />
              </div>
            )}
          </div>
        )}
      </Draggable>
    </>
  );
}
