import { twMerge as cx } from 'tailwind-merge';
import ChatInfo from '../Info';
import { MediaLoading } from '..';

type ChatBubbleVideoProps = {
  isQuotedLevel?: boolean;
  mediaUrl?: string | null;
  isLoading?: boolean;
  error?: unknown;
  fromMe: boolean;
};

export default function ChatBubbleVideo({
  isQuotedLevel,
  mediaUrl,
  isLoading,
  error,
  fromMe,
}: ChatBubbleVideoProps) {
  return isLoading ? (
    <MediaLoading fromMe={fromMe} />
  ) : error ? (
    <ChatInfo />
  ) : mediaUrl ? (
    <div className="flex items-center gap-2">
      <video
        src={mediaUrl}
        className={cx('object-cover', isQuotedLevel ? 'w-24 h-24' : '')}
        controls
      />
    </div>
  ) : null;
}
