import { useCallback, useEffect, useRef, useState } from 'react';
import { X } from 'react-feather';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';

import { BBBButton } from '@/components/ui';
import useResponsive from '@/hooks/common/useResponsive';
import { deleteToast, destroyingToast } from '@/stores/common';

export type IBBBToastProps = {
  isCloseable?: boolean;
  hasAction?: boolean;
  hasProgress?: boolean;
  actionCallback?: (onHide: () => void) => void;
  actionText?: string;
  id: string;
  message: string;
  type: 'primary' | 'error';
  duration?: number;
  index: number;
  destroying?: boolean;
};

const ANIMATE_TRANSITION = {
  type: 'tween',
  duration: 0.3,
};

const BBBToast = ({
  id,
  message,
  isCloseable,
  hasProgress = false,
  hasAction,
  type,
  duration = 5000,
  actionText,
  actionCallback,
  index,
}: IBBBToastProps) => {
  const isMobile = useResponsive('sm');
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const timerID = useRef<ReturnType<typeof setTimeout> | null | undefined>(
    null
  );

  const destroyAndDeleteToast = useCallback(() => {
    dispatch(
      destroyingToast({
        id,
      })
    );
    setTimeout(() => {
      dispatch(
        deleteToast({
          id,
        })
      );
    }, 300);
  }, [dispatch, id]);

  useEffect(() => {
    timerID.current = setTimeout(() => {
      destroyAndDeleteToast();
    }, duration);

    const startProgressBar = () => {
      const intervalID = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(intervalID);
            return prevProgress;
          }
          return prevProgress + 1;
        });
      }, duration / 100);
    };

    startProgressBar();

    return () => {
      clearTimeout(timerID.current ?? 0);
    };
  }, [destroyAndDeleteToast, duration, id]);

  const position = isMobile ? 'bottom' : 'top';
  const offset = 72 * (index * 1.1) + 16 + 'px';

  return (
    <motion.div
      id={id}
      className={cx(
        'lg:w-[27.625rem] w-[25rem] flex flex-col lg:px-6 px-5 lg:py-5 py-4 rounded-2xl border-[1.5px] z-[999999] overflow-clip absolute  lg:right-4 right-2  transform lg:translate-x-0 translate-x-1/2 pointer-events-auto',
        type === 'error'
          ? 'bg-danger-surface border-danger-main'
          : 'bg-primary-main border-primary-main'
      )}
      initial={{
        opacity: 0,
        ...(isMobile
          ? {
              x: '-50%',
              y: 100,
              left: '50%',
            }
          : {
              x: 100,
              y: 0,
            }),
        [position]: offset,
      }}
      animate={{
        opacity: 1,
        ...(isMobile
          ? {
              x: '-50%',
              y: 0,
              left: '50%',
            }
          : {
              x: 0,
              y: 0,
            }),
        [position]: offset,
      }}
      exit={{
        opacity: 0,
        ...(isMobile
          ? {
              x: '-50%',
              y: 100,
              left: '50%',
            }
          : {
              x: 100,
              y: 0,
            }),
        [position]: offset,
      }}
      transition={ANIMATE_TRANSITION}
    >
      <div className="flex justify-between items-center">
        <p
          className={cx(
            'text-sm break-words',
            type === 'error' ? 'text-danger-main' : 'text-neutral-10'
          )}
        >
          {message}
        </p>
        {isCloseable ? (
          <X
            size={24}
            className="flex-none cursor-pointer"
            color={type === 'error' ? '#F87171' : '#FFFF'}
            onClick={() => destroyAndDeleteToast()}
          />
        ) : hasAction ? (
          <BBBButton
            size="sm"
            variant={type === 'error' ? 'danger-outline' : 'secondary'}
            text={actionText || 'Action button'}
            onClick={() => {
              actionCallback?.(() => {
                destroyAndDeleteToast();
              });
            }}
          />
        ) : null}
      </div>
      {hasProgress && (
        <div
          className={cx(
            'h-1 rounded',
            type === 'error' ? 'bg-transparent' : 'bg-neutral-30'
          )}
        >
          <div
            className={cx(
              'h-full',
              type === 'error' ? 'bg-danger-main' : 'bg-neutral-10'
            )}
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      )}
    </motion.div>
  );
};

export default BBBToast;
