import React from 'react';
import { AnimationControls, motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';

import { BBBButton, BBBCard } from '@/components/ui';

type Props = {
  id?: string;
  onSubmit: () => void;
  onCancel: () => void;
  children?: React.ReactNode;
  isLoadingSubmit?: boolean;
  disabledSubmit?: boolean;
  className?: string;
};

function Popup({
  id,
  className,
  isLoadingSubmit,
  disabledSubmit,
  onSubmit,
  onCancel,
  children,
}: Props) {
  return (
    <motion.div
      className="h-full w-2/5 ml-auto"
      initial={{ x: 1000 }}
      animate={{ x: 0 }}
      transition={{ duration: 0.3 }}
    >
      <BBBCard id={id} className={cx('h-full !p-7 overflow-auto', className)}>
        <div className="px-1 h-full flex flex-col justify-between gap-6">
          {children}

          {!isLoadingSubmit && (
            <div className="flex gap-2 justify-end pb-7">
              <BBBButton
                text="Cancel"
                variant="secondary"
                onClick={() => onCancel()}
              />
              <BBBButton
                text="Save"
                onClick={() => onSubmit()}
                loadingState={isLoadingSubmit}
                disabled={disabledSubmit}
              />
            </div>
          )}
        </div>
      </BBBCard>
    </motion.div>
  );
}

export default Popup;
