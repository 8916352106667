import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { ModalState } from '@/stores/bitApp/types';
import {
  SectionAssociation,
  SectionResponse,
} from '@/types/bitApp/association';
import { Banner, Collection, ImageGrid } from '@/types/bitApp/v2';

export default {
  getSectionByCompanyId: (
    companyId: number,
    params?: {
      offset?: number;
    }
  ) =>
    api.bitApp
      .get<ApiResponse<SectionResponse>>(`/section/${companyId}`, {
        params,
      })
      .then((res) => res.data.data),
  getSectionDetail: (sectionId: number, companyId: number) =>
    api.bitApp
      .get<ApiResponse<SectionAssociation>>(
        `/section/${sectionId}/company/${companyId}/detail`
      )
      .then((res) => res.data.data),
  deleteSection: (sectionId: number, companyId: number) =>
    api.bitApp.delete(`/section/${sectionId}/company/${companyId}`),
  rearrangeSection: (
    companyId: number,
    payload: {
      data: {
        id: number;
        position: number;
      }[];
    }
  ) =>
    api.bitApp
      .post<SectionResponse>(`/section/rearrange/${companyId}`, payload)
      .then((res) => res.data.data),
  addSection: (
    companyId: number,
    payload: { type: string; position: number; active: boolean }
  ) =>
    api.bitApp.post<
      ApiResponse<{
        id: number;
        active: boolean;
        companyId: number;
        position: number;
        type: keyof ModalState;
        banners: Banner | null;
        collections: Collection | null;
        imageGrids: ImageGrid | null;
      }>
    >(`/section/${companyId}`, payload),
};
