import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import services from '@/api/services';
import { useAppSelector } from '@/hooks/rtk/store';
import { SectionResponse } from '@/types/bitApp/association';

const useRearrangeSection = () => {
  const client = useQueryClient();
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  return useMutation(
    ({ data }: { data: InfiniteData<SectionResponse> | undefined }) =>
      services.bitApp.section.rearrangeSection(activeCompany!, {
        data:
          data?.pages
            .flatMap((page) => page.data)
            .map((p, index: number) => ({
              id: p.id,
              position: index,
            })) ?? [],
      }),
    {
      onMutate: async (data) => {
        await client.cancelQueries(['sections', activeCompany]);
        const previousSection = client.getQueryData<SectionResponse>([
          'sections',
          activeCompany,
        ]);
        client.setQueryData(['sections', activeCompany], data.data);
        return { previousSection };
      },
      onError: (err, payload, context) => {
        client.setQueryData(
          ['sections', activeCompany],
          context?.previousSection
        );
      },
      onSettled: () => {
        client.invalidateQueries(['sections', activeCompany]);
      },
    }
  );
};

export default useRearrangeSection;
