import { useMutation, useQuery } from '@tanstack/react-query';
import services from 'api/services';
import { WhatsappLoginCallbackParams } from 'api/services/bitLogin/login/whatsapp';

export const useRegistrationWhatsApp = () => {
  return useMutation(
    (data: WhatsappLoginCallbackParams) =>
      services.bitLogin.login.whatsapp.addRegistrationLogin(data),
    {
      onSuccess: (data) => {
        const script = document.createElement('script');
        script.innerHTML = data;

        document.body.appendChild(script);
      },
    }
  );
};

export const useQRWhatsAppLogin = (
  domain: string | null | undefined,
  code: string | null | undefined
) => {
  return useQuery(['qr-whatsapp-bitlogin', domain], () =>
    services.bitLogin.login.whatsapp.getQRCode(domain, code)
  );
};

export const useLoginStatus = (code: string, domain: string) => {
  return useQuery(['whatsapp-login-status', code], () =>
    services.bitLogin.login.whatsapp.getWhatsappLoginStatus(code, domain)
  );
};

export const useUpdateLoginStatus = () => {
  return useMutation(
    (payload: { code: string; domain: string; status: number }) =>
      services.bitLogin.login.whatsapp.updateWhatsappLoginStatus(
        payload.code,
        payload.domain,
        { status: payload.status }
      )
  );
};
