import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import api from '@/config/api';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import { useAppSelector } from '@/hooks/rtk/store';
import { toast } from '@/utils/common/toast';

const useDeleteCampaign = () => {
  const toggleConfirmation = useConfirmationModal();
  const client = useQueryClient();

  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  const deleteCampaignMutation = useMutation(
    ({ id }: { id: string }) =>
      api.bitCRM.v2.delete(`/campaign-new/${activeCompany}/${id}`),
    {
      onSuccess: (_, payload) => {
        toast.success(`Your campaign has been deleted`);
        client.invalidateQueries(['bitcrm-campaigns']);
      },
    }
  );

  function deleteCampaign(withConfirmation: true): (param: string) => void;
  function deleteCampaign(
    withConfirmation: false
  ): UseMutationResult<AxiosResponse<any>, any, { id: string }, unknown>;
  function deleteCampaign(
    withConfirmation: true | false
  ):
    | ((param: string) => void)
    | UseMutationResult<AxiosResponse<any>, any, { id: string }, unknown> {
    return withConfirmation
      ? (id) =>
          toggleConfirmation({
            title: 'Delete campaign?',
            description: `Once deleted you're not able to recover it`,
            onAccept: async (hide) =>
              deleteCampaignMutation.mutate(
                {
                  id,
                },
                {
                  onSuccess: () => {
                    hide();
                  },
                }
              ),
            deleteModal: true,
            submitText: 'Yes',
            cancelText: 'Cancel',
          })
      : deleteCampaignMutation;
  }

  return deleteCampaign;
};

export default useDeleteCampaign;
