import Wrapper from '../components/Wrapper';
import AddressCard from './components/AddressCard';
import BasicInformationCard from './components/BasicInformationCard';
import CustomFieldCard from './components/CustomField/CustomFieldCard';
import TitleCard from './components/TitleCard';
import Upsell from './components/Upsell';

import NewBadge from '@/components/Badge/NewBadge';
import {
  isSameOrAbovePricingTier,
  standardPlanDeprecation,
} from '@/constants/pricing';
import useSocialAppearance from '@/hooks/bitLogin/useSocialAppearance';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import { cn } from '@/utils/styles';

export default function CollectData() {
  return (
    <Wrapper
      hasHeading
      pageTitle={
        <div className="flex gap-2 items-center">
          <p className="font-medium text2-xl text-primary-main">Collect Data</p>
          <NewBadge />
        </div>
      }
      pageDescription={
        <>
          Capture more data of customer by adding fields based on your marketing
          needs.{' '}
          <a
            href="https://www.bitbybit.studio/knowledge-base"
            className="text-info-main underline"
          >
            What is this?
          </a>
        </>
      }
      module="sign-up"
    >
      <Upsell />
      <_CollectData />
    </Wrapper>
  );
}

function _CollectData() {
  const { data: socialAppearance } = useSocialAppearance();
  const { data: pricingData } = usePricingByApp('BITLOGIN');

  const pricingName = pricingData?.pricingName || 'free';

  const isDisabled = !socialAppearance?.captureAdditionalData;

  const isRestricted = !isSameOrAbovePricingTier(
    standardPlanDeprecation(pricingName),
    'proAdvance'
  );

  return (
    <div
      className={cn(
        'flex flex-col gap-4',
        isRestricted && isDisabled
          ? 'opacity-40 pointer-events-none'
          : 'opacity-100'
      )}
    >
      <TitleCard />
      <BasicInformationCard />
      <AddressCard />
      <CustomFieldCard />
      <p className="text-sm text-primary-main">
        Learn more about{' '}
        <a href="" className="text-info-main underline">
          collect data
        </a>
      </p>
    </div>
  );
}
