import React, { useRef, useState } from 'react';
import { ChevronDown, X } from 'react-feather';
import { shallowEqual } from 'react-redux';
import { useHistory } from 'react-router';
import { createId } from '@paralleldrive/cuid2';
import { twMerge as cx } from 'tailwind-merge';
import { v4 } from 'uuid';
import HandleTicket from '../../HandleTicket';
import ChatBubble from '../ChatBoard/ChatBubble';
import QuickAction from './QuickAction';
import QuickReplyRecommendation from './QuickReplyRecommendation';

import SentIcon2 from '@/assets/icons/SentIcon2';
import {
  BBBRichTextEditor,
  BBBSelect,
  SingleOnValueChangeParam,
} from '@/components/ui';
import useShouldInitChat from '@/hooks/bitChat/livechat/useShouldInitChat';
import useWACloudTemplates from '@/hooks/bitChat/settings/useWACloudTemplates';
import { useUploadFiles } from '@/hooks/common/upload';
import { useUserId } from '@/hooks/rtk/selector';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { selectActiveChatTruthy, useStartChat } from '@/hooks/whatsApp/chat';
import {
  activeMessageSelector,
  recommendedProductSelector,
  selectedReplySelector,
} from '@/hooks/whatsApp/useActiveChatMemo';
import useIsGroupChat from '@/hooks/whatsApp/useIsGroupChat';
import useSendMessage from '@/hooks/whatsApp/useSendMessage';
import { upsertLivechatMemo } from '@/stores/bitCRM';
import { WACloudTemplate } from '@/types/whatsApp/settings';
import { MessageNewAssociation } from '@/types/whatsApp/v3';
import { formatSender } from '@/utils/bitChat';
import {
  convertEditorStateToHtml,
  convertHtmlToEditorState,
} from '@/utils/common/rich';

type Props = {
  setCoPilotQuickActionRef: React.Dispatch<
    React.SetStateAction<HTMLDivElement | null | undefined>
  >;
};

const richId = createId();

export default function ChatAction(props: Props) {
  const selectedChat = useAppSelector((state) => !!state.bitCRM.selectedChat);

  if (!selectedChat) return null;

  return <_ChatAction {...props} />;
}

function _ChatAction(props: Props) {
  return <_ChatActionOnboarded {...props} />;
}

function _ChatActionOnboarded(props: Props) {
  return (
    <>
      <div className="w-full bg-white">
        <QuotedReply />
        <MessageAction {...props} />
      </div>
    </>
  );
}

function MessageAction(props: Props) {
  const userId = useUserId();

  const isActive = useAppSelector((s) => {
    const selectedChat = s.bitCRM.selectedChat!;

    return (
      selectedChat.ticket?.userId === userId && selectedChat.status === 'Active'
    );
  }, shallowEqual);

  if (isActive) return <__MessageAction {...props} />;

  return <_MessageAction />;
}

function _MessageAction() {
  const activeChat = useAppSelector((s) => {
    const selectedChat = s.bitCRM.selectedChat!;

    return {
      ticket: selectedChat.ticket,
      status: selectedChat.status,
    };
  }, shallowEqual);

  return (
    <HandleTicket
      className="px-2 pb-3"
      ticket={activeChat.ticket}
      status={activeChat.status}
    />
  );
}

function __MessageAction({ setCoPilotQuickActionRef }: Props) {
  const [selectedTemplate, setSelectedTemplate] =
    useState<WACloudTemplate | null>();

  return (
    <>
      <WACloudTemplateAction
        selectedTemplate={selectedTemplate}
        onChangeTemplate={setSelectedTemplate}
      />
      <___MessageAction
        selectedTemplate={selectedTemplate}
        onChangeTemplate={setSelectedTemplate}
        setCoPilotQuickActionRef={setCoPilotQuickActionRef}
      />
    </>
  );
}

function ___MessageAction({
  selectedTemplate,
  onChangeTemplate,
  setCoPilotQuickActionRef,
}: WACloudTemplateOptionsProps & Props) {
  const activeMessages = useAppSelector(activeMessageSelector);
  const recommendedProducts = useAppSelector(recommendedProductSelector);

  const { mutate: sendMessage, isLoading: loadingSendMessage } =
    useSendMessage();
  const { mutate: startChat, isLoading: loadingStartChat } = useStartChat();

  const selectedChat = useAppSelector(selectActiveChatTruthy);

  const onSubmit = () => {
    if (selectedTemplate) {
      startChat(
        {
          sources: selectedChat.sources,
          conversationId: selectedChat.clientNumber,
          templateId: selectedTemplate.id,
          message: selectedTemplate.message,
          source: 'send_message',
        },
        {
          onSuccess: () => {
            onChangeTemplate(null);
          },
        }
      );
    } else {
      sendMessage(
        {
          message:
            (convertEditorStateToHtml(activeMessages || undefined) || '') +
            (recommendedProducts
              ? `<br/>${recommendedProducts.title}<br/>${recommendedProducts.url}`
              : ''),
          media: recommendedProducts?.image?.src,
          type: 'chat',
          temporaryId: v4(),
        },
        {
          onSuccess: () => {
            onChangeTemplate(null);
          },
        }
      );
    }
  };

  return (
    <div className="flex gap-2 px-2 py-2 relative" id="chat-action-wrapper">
      <MessageBoxArea
        onSubmit={onSubmit}
        setCoPilotQuickActionRef={setCoPilotQuickActionRef}
      />
      <SendMessage
        onSubmit={onSubmit}
        loadingSave={loadingSendMessage || loadingStartChat}
      />
    </div>
  );
}

function MessageBoxArea({
  onSubmit,
  setCoPilotQuickActionRef,
}: { onSubmit: () => void } & Props) {
  const dispatch = useAppDispatch();
  const activeMessages = useAppSelector(activeMessageSelector);

  const isCloudApiRestricted = useShouldInitChat();

  const activeMessagesText = activeMessages
    .getCurrentContent()
    .getPlainText(' ');

  const blockKeyDownRef = useRef(false);

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    setTimeout(() => {
      if (!activeMessagesText.trim().length) return;
      if (!blockKeyDownRef.current && e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        onSubmit();
      }
    }, 100);
  };

  const uploadFiles = useUploadFiles();

  const onPasteEvent = async (
    e: React.ClipboardEvent<HTMLDivElement> | undefined
  ) => {
    const items = e?.clipboardData.items;
    const blob: File[] | null = [];

    for (let i = 0; i < (items?.length || 0); i += 1) {
      const file = items?.[i].getAsFile();

      if (file) {
        blob.push(file as File);
      }
    }

    if (blob.length) {
      await uploadFiles(blob, {
        defaultSelectAdded: true,
      });
    }
  };

  return (
    <BBBRichTextEditor
      editorState={activeMessages}
      onChangeEditorState={(val) =>
        dispatch(
          upsertLivechatMemo({
            messageToSend: val,
          })
        )
      }
      containerClassName={cx(
        'mb-0',
        isCloudApiRestricted && 'pointer-events-none'
      )}
      placeholder={`Or you can type "/ai" to use suggested response`}
      withoutInline
      customInlineComponent={
        <QuickAction setCoPilotQuickActionRef={setCoPilotQuickActionRef} />
      }
      onPaste={onPasteEvent}
      onKeyDown={onKeyDown}
      rows={1}
      id={richId}
      containerStyle={{ width: 'calc(100% - 3.25rem)' }}
    >
      {activeMessagesText.startsWith('/') && (
        <QuickReplyRecommendation
          richId={richId}
          onQuickReplyEnter={() => {
            blockKeyDownRef.current = true;
            setTimeout(() => {
              blockKeyDownRef.current = false;
            }, 200);
          }}
        />
      )}
      <ProductRecommendationExtension />
    </BBBRichTextEditor>
  );
}

function SendMessage({
  onSubmit,
  loadingSave,
}: {
  onSubmit: () => void;
  loadingSave?: boolean;
}) {
  const activeMessages = useAppSelector(activeMessageSelector);

  const activeMessagesText = activeMessages
    .getCurrentContent()
    .getPlainText(' ');

  return (
    <div
      className={cx(
        'self-end bg-primary-main rounded-lg transition-opacity p-3 cursor-pointer flex-none',
        (!activeMessagesText || loadingSave) && 'pointer-events-none opacity-25'
      )}
      onClick={onSubmit}
    >
      <SentIcon2 />
    </div>
  );
}

function ProductRecommendationExtension() {
  const dispatch = useAppDispatch();

  const recommendedProducts = useAppSelector(recommendedProductSelector);

  if (!recommendedProducts) return null;

  return (
    <>
      <div className="flex gap-2 items-center mt-3">
        {recommendedProducts.image?.src && (
          <img
            src={recommendedProducts.image.src}
            className="w-8 h-8 rounded"
          />
        )}
        <div>
          {recommendedProducts.title}{' '}
          <span className="font-semibold">(Rp. 2,000,000)</span>
        </div>
        <div
          className="text-neutral-40 hover:bg-gray-100 p-1 cursor-pointer transition-[background] rounded"
          onClick={() =>
            dispatch(
              upsertLivechatMemo({
                recommendedProduct: null,
              })
            )
          }
        >
          <X />
        </div>
      </div>
    </>
  );
}

function WACloudTemplateAction({
  selectedTemplate,
  onChangeTemplate,
}: WACloudTemplateOptionsProps) {
  const isCloudApiRestricted = useShouldInitChat();
  const dispatch = useAppDispatch();

  if (isCloudApiRestricted) {
    return (
      <WACloudTemplateOptions
        selectedTemplate={selectedTemplate}
        onChangeTemplate={(opt) => {
          onChangeTemplate(opt);
          dispatch(
            upsertLivechatMemo({
              messageToSend: convertHtmlToEditorState(opt?.message),
            })
          );
        }}
      />
    );
  }

  return null;
}

function QuotedReply() {
  const activeSelectedReply = useAppSelector(selectedReplySelector);

  if (!activeSelectedReply) return null;

  return <_QuotedReply activeSelectedReply={activeSelectedReply} />;
}

function _QuotedReply({
  activeSelectedReply,
}: {
  activeSelectedReply: MessageNewAssociation;
}) {
  const dispatch = useAppDispatch();

  const isGroup = useIsGroupChat();

  return (
    <div className="mx-3 my-1 flex items-center gap-4">
      <ChatBubble
        chat={activeSelectedReply}
        isGroup={isGroup}
        level={1}
        sender={formatSender(activeSelectedReply)}
        isHaveEdge={false}
        containerClassName="w-full inset-0 mb-0"
        withoutTimestamp
        bubbleClassName="mb-0 group-hover:border-transparent"
      />
      <div
        className="cursor-pointer hover:bg-gray-100 transition-all rounded p-1"
        onClick={() =>
          dispatch(
            upsertLivechatMemo({
              selectedReply: null,
            })
          )
        }
        tabIndex={0}
        id="close-reply"
      >
        <X className="text-gray-400" />
      </div>
    </div>
  );
}

type WACloudTemplateOptionsProps = {
  selectedTemplate: WACloudTemplate | null | undefined;
  onChangeTemplate: (val: SingleOnValueChangeParam<WACloudTemplate>) => void;
};

function WACloudTemplateOptions({
  selectedTemplate,
  onChangeTemplate,
}: WACloudTemplateOptionsProps) {
  const { data } = useWACloudTemplates({ status: 'APPROVED' });
  const history = useHistory();

  return (
    <BBBSelect
      withCreateRedirectOption
      onClickCreateRedirect={() => {
        history.push(`/misc/templates/new?section=chat`);
      }}
      dropdownOptionsWrapperClassName="max-h-[11.5rem]"
      persistCreateRedirectOption
      createRedirectLabel="Create new template"
      options={data}
      dropdownPosition="top"
      containerClassName="px-2 pt-2"
      selectedDropdownClassName="border-none p-0 text-info-main"
      renderSelectedValues={({ toggleDropdown }) => (
        <span
          onClick={toggleDropdown}
          className="flex justify-start cursor-pointer text-sm text-info-main items-center gap-1"
        >
          {selectedTemplate ? selectedTemplate.name : 'Select template'}{' '}
          <ChevronDown size={'0.875rem'} />
        </span>
      )}
      optionLabel="name"
      optionValue="id"
      onValueChange={onChangeTemplate}
      value={selectedTemplate}
    />
  );
}
