type Props = {
  width?: number | string;
  height?: number | string;
  hasBorder?: boolean;
  shape?: 'circle' | 'square' | 'block';
} & React.SVGProps<SVGSVGElement>;

function BBBThumbnail({
  width = 30,
  height = 30,
  hasBorder,
  shape = 'circle',
  ...props
}: Props) {
  return hasBorder ? (
    <_BBBThumbnailHasBorder width={width} height={height} {...props} />
  ) : shape === 'square' ? (
    <_BBBThumnailSquareBorder width={width} height={height} {...props} />
  ) : shape === 'block' ? (
    <_BBBThumbnailBlockBorder width={width} height={height} {...props} />
  ) : (
    <_BBBThumbnail width={width} height={height} {...props} />
  );
}

function _BBBThumbnail(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 206.518 206.518"
      version="1.1"
      viewBox="0 0 27 14"
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M26.5987 11.1476L22.7544 6.50083L17.9012 0.63167C17.7372 0.433506 17.5313 0.273937 17.2982 0.164395C17.0651 0.0548524 16.8106 -0.00195312 16.5529 -0.00195312C16.2952 -0.00195312 16.0407 0.0548524 15.8076 0.164395C15.5745 0.273937 15.3686 0.433506 15.2046 0.63167L10.3514 6.50083L10.2914 6.57186L8.58868 4.51204C8.4247 4.31388 8.21879 4.15431 7.98569 4.04477C7.75259 3.93523 7.49807 3.87842 7.24036 3.87842C6.98266 3.87842 6.72814 3.93523 6.49504 4.04477C6.26194 4.15431 6.05602 4.31388 5.89205 4.51204L2.6441 8.44102L0.393786 11.1476C0.182004 11.4019 0.0473418 11.7111 0.00558497 12.0389C-0.0361719 12.3668 0.0167075 12.6997 0.158024 12.9987C0.299341 13.2977 0.523238 13.5503 0.803469 13.727C1.0837 13.9036 1.40865 13.997 1.74022 13.9962H25.2522C25.5838 13.997 25.9088 13.9036 26.189 13.727C26.4692 13.5503 26.6931 13.2977 26.8344 12.9987C26.9758 12.6997 27.0286 12.3668 26.9869 12.0389C26.9451 11.7111 26.8105 11.4019 26.5987 11.1476Z"
        fill="#707070"
      />
    </svg>
  );
}

function _BBBThumbnailHasBorder(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 56 56"
      {...props}
    >
      <g clipPath="url(#clip0_4884_51289)">
        <path
          fill="#707070"
          d="M40.6 32.148L36.754 27.5l-4.853-5.87a1.746 1.746 0 00-2.094-.467c-.233.11-.439.27-.603.468L24.352 27.5l-.06.07-1.703-2.059a1.747 1.747 0 00-2.093-.467c-.234.11-.44.269-.604.467l-3.247 3.929-2.25 2.707a1.735 1.735 0 00.409 2.579c.28.177.605.27.937.27h23.512a1.75 1.75 0 001.582-.998 1.734 1.734 0 00-.236-1.851z"
        ></path>
      </g>
      <circle
        cx="28"
        cy="28"
        r="27.5"
        stroke="#707070"
        strokeDasharray="8 8"
      ></circle>
      <defs>
        <clipPath id="clip0_4884_51289">
          <path
            fill="#fff"
            d="M0 0H27V14H0z"
            transform="translate(14 21)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function _BBBThumnailSquareBorder(props: Props) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="80" height="80" rx="8" fill="white" />
      <rect
        x="0.5"
        y="0.5"
        width="79"
        height="79"
        rx="7.5"
        stroke="#262627"
        strokeOpacity="0.5"
        strokeDasharray="8 8"
      />
      <path
        d="M60.3747 45.93L54.3967 39.2909L46.8499 30.9053C46.5949 30.6222 46.2747 30.3942 45.9122 30.2377C45.5498 30.0812 45.154 30 44.7532 30C44.3525 30 43.9567 30.0812 43.5942 30.2377C43.2317 30.3942 42.9115 30.6222 42.6566 30.9053L35.1097 39.2909C35.0597 39.3454 34.9736 39.3449 34.9242 39.2899L32.3686 36.4494C32.1136 36.1663 31.7934 35.9383 31.4309 35.7818C31.0684 35.6253 30.6727 35.5441 30.2719 35.5441C29.8712 35.5441 29.4754 35.6253 29.1129 35.7818C28.7504 35.9383 28.4302 36.1663 28.1752 36.4494L23.1246 42.063L19.6253 45.93C19.2959 46.2934 19.0865 46.7351 19.0216 47.2036C18.9567 47.672 19.0389 48.1477 19.2586 48.5749C19.4784 49.002 19.8266 49.363 20.2623 49.6154C20.6981 49.8678 21.2034 50.0012 21.719 50H58.281C58.7966 50.0012 59.3019 49.8678 59.7377 49.6154C60.1734 49.363 60.5216 49.002 60.7414 48.5749C60.9611 48.1477 61.0433 47.672 60.9784 47.2036C60.9135 46.7351 60.7041 46.2934 60.3747 45.93Z"
        fill="#707070"
      />
    </svg>
  );
}

function _BBBThumbnailBlockBorder(props: Props) {
  return (
    <svg
      width="150"
      height="80"
      viewBox="0 0 150 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="150" height="80" rx="4" fill="white" />
      <rect
        x="0.5"
        y="0.5"
        width="149"
        height="79"
        rx="3.5"
        stroke="#262627"
        strokeOpacity="0.5"
        strokeDasharray="8 8"
      />
      <path
        d="M99.2556 47.116L92.139 39.1491L83.1546 29.0864C82.8511 28.7466 82.4699 28.473 82.0384 28.2852C81.6069 28.0974 81.1357 28 80.6586 28C80.1815 28 79.7104 28.0974 79.2788 28.2852C78.8473 28.473 78.4661 28.7466 78.1626 29.0864L69.1782 39.1491L69.0671 39.2709L65.915 35.7393C65.6114 35.3996 65.2302 35.126 64.7987 34.9382C64.3672 34.7504 63.896 34.653 63.4189 34.653C62.9419 34.653 62.4707 34.7504 62.0392 34.9382C61.6077 35.126 61.2265 35.3996 60.9229 35.7393L54.9102 42.4756L50.7444 47.116C50.3523 47.552 50.103 48.0822 50.0257 48.6443C49.9484 49.2064 50.0463 49.7773 50.3079 50.2899C50.5695 50.8025 50.984 51.2356 51.5028 51.5385C52.0215 51.8414 52.6231 52.0015 53.2369 52H96.7631C97.3769 52.0015 97.9785 51.8414 98.4972 51.5385C99.016 51.2356 99.4305 50.8025 99.6921 50.2899C99.9537 49.7773 100.052 49.2064 99.9743 48.6443C99.897 48.0822 99.6477 47.552 99.2556 47.116Z"
        fill="#707070"
      />
    </svg>
  );
}

export default BBBThumbnail;
