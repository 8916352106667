import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/config/api';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import { useAppSelector } from '@/hooks/rtk/store';
import { toast } from '@/utils/common/toast';

const mapActionToTitle: Record<string, string> = {
  delete: 'Delete multiple campaign template',
};

const mapDescriptionToTitle: Record<string, string> = {
  delete:
    'Are you sure to delete selected campaign template? Once you deleted the action cannot be undone',
};

type MutationPayload = {
  action: 'delete' | 'duplicate';
  selectedRows: string[];
};

export const useTemplatesBulkAction = () => {
  const toggleConfirmation = useConfirmationModal();
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const client = useQueryClient();

  const { mutate: bulkAction } = useMutation(
    ({
      action,
      selectedRows,
    }: {
      action: 'delete' | 'duplicate';
      selectedRows: string[];
    }) =>
      api.bitCRM.v2.post(`/campaign_template/bulk_action`, {
        companyId: activeCompany,
        data: selectedRows,
        action,
      }),
    {
      onSuccess: (_, payload) => {
        toast.success(
          payload.action === 'duplicate'
            ? 'Template duplicated'
            : `Your campaign template has been deleted`
        );
        client.invalidateQueries(['bitcrm-template']);
        client.invalidateQueries(['bitcrm-templates']);
      },
    }
  );

  return ({
    action,
    selectedRows,
    onSuccess,
  }: MutationPayload & {
    onSuccess?: () => void;
  }) => {
    const title = mapActionToTitle[action];
    const description = mapDescriptionToTitle[action];

    if (title || description) {
      toggleConfirmation({
        title: title,
        description,
        onAccept: (hide) =>
          bulkAction(
            {
              action,
              selectedRows,
            },
            {
              onSuccess: () => {
                hide();
                onSuccess?.();
              },
            }
          ),
        deleteModal: true,
        submitText: 'Delete',
        cancelText: 'Cancel',
      });
    } else {
      bulkAction(
        {
          action,
          selectedRows,
        },
        {
          onSuccess: () => {
            onSuccess?.();
          },
        }
      );
    }
  };
};
