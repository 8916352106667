import { Carousel } from 'react-responsive-carousel';
import { twMerge as cx } from 'tailwind-merge';

import BBBThumbnail from '@/assets/icons/BBBThumbnail';
import { env } from '@/config/env';
import { SectionAssociation } from '@/types/bitApp/association';

type Props = {
  data: SectionAssociation['banners'];
};

export default function Banner({ data }: Props) {
  return (
    (data && data.bannerContents.length > 0 && (
      <Carousel
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        autoPlay
        infiniteLoop
        renderIndicator={(onClickHandler, isSelected, index, label) => (
          <div
            onClick={onClickHandler}
            key={index}
            role="button"
            tabIndex={0}
            className={cx(
              'inline-block',
              'w-2 h-2',
              'mr-2',
              isSelected ? 'bg-white' : 'bg-transparent',
              'rounded-full',
              'cursor-pointer',
              isSelected ? 'border border-transparent' : 'border border-white'
            )}
            title={label}
          />
        )}
      >
        {data.bannerContents.map((item) => (
          <div key={item.id} className="h-full">
            <img
              alt="liveshow"
              className="d-block w-[17.5rem] h-[9.6rem] object-cover rounded-xl"
              src={
                item.image ||
                `${env.REACT_APP_CDN_URL}/bitbybit/static/bitApp/banner-hovered-placeholder.png`
              }
            />
          </div>
        ))}
      </Carousel>
    )) || <BannerPlaceholder />
  );
}

export function BannerPlaceholder() {
  return (
    <div className="relative w-full transition-all group">
      <div className="opacity-100 group-hover:opacity-0 transition-all z-0">
        <div className="w-full h-[10.7rem] flex justify-center items-center bg-neutral-20 rounded-lg">
          <div className="flex flex-col justify-center items-center gap-2">
            <BBBThumbnail width={100} height={100} className="opacity-50" />
            <div className="flex items-center gap-2">
              <div className="w-2 h-2 rounded-full bg-neutral-40" />
              <div className="w-2 h-2 rounded-full border border-neutral-40" />
              <div className="w-2 h-2 rounded-full border border-neutral-40" />
            </div>
          </div>
        </div>
      </div>
      <img
        className="opacity-0 group-hover:opacity-100 transition-all duration-200 absolute transition-1/2 top-0 left-0 w-full h-full z-10 object-cover rounded-xl"
        src={`${env.REACT_APP_CDN_URL}/bitbybit/static/bitApp/assets/image-slider.jpg`}
        alt="banner"
      />
      <div className="absolute flex items-center gap-2 z-10 opacity-0 group-hover:opacity-100 transition-all duration-200 transition-1/2 bottom-5 left-1/2 transform -translate-x-1/2">
        <div className="w-2 h-2 rounded-full bg-neutral-10" />
        <div className="w-2 h-2 rounded-full border border-neutral-10" />
        <div className="w-2 h-2 rounded-full border border-neutral-10" />
      </div>
    </div>
  );
}
