import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/config/api';
import { useAppSelector } from '@/hooks/rtk/store';
import { toast } from '@/utils/common/toast';

const useResumeCampaign = () => {
  const client = useQueryClient();
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);

  return useMutation(
    async ({ id }: { id: string }) =>
      api.bitCRM.v2.post(`/campaign-new/action/resume`, {
        companyId: activeCompany,
        id,
      }),
    {
      onSuccess: (_, payload) => {
        toast.success(`Your campaign has been resumed`);
        client.invalidateQueries(['bitcrm-campaigns']);
      },
    }
  );
};

export default useResumeCampaign;
