import React, { forwardRef } from 'react';
import { twMerge as cx } from 'tailwind-merge';

import CheckIcon2 from '@/assets/icons/CheckIcon2';

export type BBBCheckboxTypes = {
  onValueChange?: (value: boolean) => void;
  label?: string | React.ReactNode;
  disabled?: boolean;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  errors?: string;
  leftLabel?: string;
  checkSize?: number;
  leftLabelClassName?: string;
} & Omit<React.HTMLProps<HTMLInputElement>, 'label'>;

const BBBCheckbox = forwardRef<HTMLInputElement, BBBCheckboxTypes>(
  (
    {
      id,
      checked,
      onChange,
      onValueChange,
      label = '',
      disabled = false,
      className,
      labelClassName,
      inputClassName,
      leftLabel = '',
      leftLabelClassName,
      errors,
      checkSize,
    },
    ref
  ) => {
    return (
      <>
        <div
          className={cx(
            `flex items-center cursor-pointer gap-2`,
            disabled ? 'opacity-50 pointer-events-none' : '',
            className
          )}
          onClick={(e) => {
            onValueChange?.(!checked);
            e.stopPropagation();
          }}
          ref={ref}
        >
          {leftLabel && <div className={leftLabelClassName}>{leftLabel}</div>}
          <div
            className={cx(
              'w-6 h-6 flex items-center justify-center border border-primary-main opacity-100 rounded-md p-3 ',
              checked ? 'bg-primary-main' : 'bg-transparent',
              inputClassName
            )}
            id={id}
          >
            {checked && (
              <div className="flex-none">
                <CheckIcon2 color="white" size={checkSize?.toString()} />
              </div>
            )}
          </div>
          {typeof label === 'string' ? (
            label.length ? (
              <div className={labelClassName}>{label}</div>
            ) : null
          ) : (
            label
          )}
        </div>
        {errors && <div className="text-danger-main">{errors}</div>}
      </>
    );
  }
);

BBBCheckbox.displayName = 'BBBCheckbox';

export default BBBCheckbox;
