import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import { useAppSelector } from '@/hooks/rtk/store';
import { handleCancelLiveSection } from '@/utils/bitApp';
import { toast } from '@/utils/common/toast';

const useDeleteSection = () => {
  const selected = useAppSelector((state) => state.bitApp.selected);

  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const client = useQueryClient();

  const toggleConfirmation = useConfirmationModal();

  const { mutate, isLoading } = useMutation(
    async (payload: number) =>
      services.bitApp.section.deleteSection(payload!, activeCompany!),
    {
      onSuccess: (_, payload) => {
        toast.success('Section deleted successfully');
        client.invalidateQueries(['sections', activeCompany]);
      },
    }
  );

  return () =>
    toggleConfirmation({
      title: 'Confirm Deletion',
      description: `Once deleted you're not able to recover it`,
      deleteModal: true,
      submitText: 'Delete',
      onAccept: (hide) => {
        mutate(selected as number, {
          onSuccess: () => {
            handleCancelLiveSection();
            hide();
          },
        });
      },
      loadingSubmit: isLoading,
    });
};

export default useDeleteSection;
