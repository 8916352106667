import { createId } from '@paralleldrive/cuid2';
import { shallow } from 'zustand/shallow';
import useStore, { RFState } from '../../../store';
import ActionModal from './ActionModal';
import ConditionModal from './ConditionModal';
import TriggerModal from './TriggerModal';

const selector = (state: RFState) => ({
  showStateModal: state.showStateModal,
  onChangeStateModal: state.onChangeStateModal,
  triggerModalState: state.triggerModalState,
  onChangeTriggerModalState: state.onChangeTriggerModalState,
  updateNode: state.updateNode,
  insertNode: state.insertNode,
  setErrorNode: state.setErrorNode,
  updateConditionEdgeData: state.updateConditionEdgeData,
});

export default function Modals() {
  const {
    showStateModal,
    onChangeStateModal,
    triggerModalState,
    onChangeTriggerModalState,
    updateNode,
    insertNode,
    updateConditionEdgeData,
  } = useStore(selector, shallow);

  return (
    <>
      {triggerModalState && (
        <>
          <TriggerModal
            onSave={async (data) => {
              if (triggerModalState.id) {
                updateNode(triggerModalState.id, data);
              }
            }}
            onClose={() => {
              onChangeTriggerModalState(null);
            }}
            nodeId={triggerModalState.id}
          />
        </>
      )}
      {showStateModal?.type === 'action' && (
        <ActionModal
          onClose={() => onChangeStateModal(null)}
          nodeId={
            'nodeId' in showStateModal ? showStateModal.nodeId : undefined
          }
          sourceId={
            'sourceId' in showStateModal ? showStateModal.sourceId : undefined
          }
          onSave={async (data) => {
            let nodeId = createId();

            if ('sourceId' in showStateModal) {
              insertNode(
                {
                  id: nodeId,
                  type: 'action',
                  data,
                  position: { x: 0, y: 0 },
                },
                {
                  sourceId: showStateModal.sourceId,
                  targetId: showStateModal.targetId,
                }
              );
            } else if ('nodeId' in showStateModal) {
              nodeId = showStateModal.nodeId;
              updateNode(nodeId, data);
            }
          }}
        />
      )}
      {showStateModal && 'edgeId' in showStateModal && (
        <ConditionModal
          onClose={() => onChangeStateModal(null)}
          onSave={(data) => {
            updateConditionEdgeData(showStateModal.edgeId, data);
          }}
          edgeId={showStateModal.edgeId}
        />
      )}
    </>
  );
}
