import { CurveType } from 'recharts/types/shape/Curve';

import { ApiResponse } from '@/api/types';
import api from '@/config/api';
import { ChannelIntegrationValues } from '@/constants/integrations';
import {
  AnalyticAgents,
  AnalyticAverageAgents,
  AnalyticSummaries,
} from '@/types/bitChat/analytics';
import { Pagination } from '@/types/bitCRM/api';
import { Ticket, TicketTag } from '@/types/whatsApp/ticket';

export default {
  getTicketPerfomance: (
    companyId: number | undefined,
    {
      startDate,
      endDate,
      limit,
      page,
      agents,
      tags,
      search,
      sort,
    }: {
      startDate?: string;
      endDate?: string;
      limit?: number;
      page?: number;
      agents?: number;
      tags?: string;
      search?: string;
      sort?: string;
    }
  ) =>
    api.whatsApp.v1
      .get<
        ApiResponse<TicketPerfomance[]> & {
          pageable: Pagination;
          performance: {
            ticketId: number;
            result: number;
          }[];
        }
      >(`/analytics/${companyId}/tickets/performance`, {
        params: {
          startDate,
          endDate,
          limit,
          page,
          agents,
          tags,
          search,
          sort,
        },
      })
      .then((res) => res.data),
  getAnalyticAgentPerformance: (
    companyId: number | undefined,
    params: GetAnalyticAgentPerformanceParams
  ) =>
    api.whatsApp.v1
      .get<AnalyticAverageAgents>(
        `/analytics/${companyId}/cards/agent-performance`,
        {
          params,
        }
      )
      .then((res) => res.data),
  getAnalyticSummaries: (
    companyId: number | undefined,
    params: {
      startDate?: string;
      endDate?: string;
      channel?: ChannelIntegrationValues;
    }
  ) =>
    api.whatsApp.v3
      .get<AnalyticSummaries>(`/analytics/${companyId}/cards/ticket`, {
        params,
      })
      .then((res) => res.data),
  getAiTags: (companyId: number | undefined, params?: GetAiTagsParams) =>
    api.whatsApp.v1
      .get<ApiResponse<GetAiTagsPayloads>>(`/analytics/${companyId}/ai-tags`, {
        params,
      })
      .then((res) => {
        const tagsByDate: {
          [key: string]: { [key: string]: string | number } & { date: string };
        } = {};

        res.data.data.topTags.forEach((tagGroup) => {
          const date = tagGroup.date;

          if (!tagsByDate[date]) {
            tagsByDate[date] = {
              // @ts-ignore
              date,
            };
          }

          // @ts-ignore
          tagGroup.tags.forEach((tag) => {
            const label = tag.label.toLowerCase();

            if (!tagsByDate[date][label]) {
              tagsByDate[date][label] = 0;
              tagsByDate[date]['percentage'] = 0;
            }
            tagsByDate[date][label] += tag.value;
            tagsByDate[date][`percentage`] += tag.percentage;
          });
        });

        return {
          ...res.data.data,
          topTags: Object.values(tagsByDate),
        };
      }),
  getAiTagsUserDefined: (
    companyId: number | undefined,
    type: string,
    params?: GetAiTagsUserDefinedParams
  ) =>
    api.whatsApp.v1
      .get<ApiResponse<GetAiTagsUserDefinedPayloads>>(
        `/analytics/${companyId}/ai-tags/${type}`,
        {
          params,
        }
      )
      .then((res) => {
        const tagsByDate: {
          [key: string]: { [key: string]: string | number } & { date: string };
        } = {};

        res.data.data.topTags.forEach((tagGroup) => {
          const date = tagGroup.date;

          if (!tagsByDate[date]) {
            tagsByDate[date] = {
              // @ts-ignore
              date,
            };
          }

          // @ts-ignore
          tagGroup.tags.forEach((tag) => {
            const label = tag.label.toLowerCase();

            if (!tagsByDate[date][label]) {
              tagsByDate[date][label] = 0;
              tagsByDate[date]['percentage'] = 0;
            }
            tagsByDate[date][label] += tag.value;
            tagsByDate[date][`percentage`] += tag.percentage;
          });
        });

        return {
          ...res.data.data,
          topTags: Object.values(tagsByDate),
        };
      }),
  exportTagsToCsv: (companyId: number | undefined, payload: GetAiTagsParams) =>
    api.whatsApp.v1
      .post(`/analytics/${companyId}/ai-tags/export`, null, {
        responseType: 'blob',
        params: {
          ...payload,
        },
      })
      .then((res) => res.data),
  getAiTagsSuggested: (
    companyId: number | undefined,
    params: Pick<GetAiTagsParams, 'startDate' | 'endDate' | 'channel'> & {
      limit?: string;
      cursor?: string;
    }
  ) =>
    api.whatsApp.v1
      .get<{
        data: GetAiTagsPayloads['aiTags'];
        meta: {
          hasNextPage: boolean;
          endCursor: string;
        };
      }>(`/analytics/${companyId}/ai-tags-suggested`, {
        params,
      })
      .then((res) => res.data),
};

export type GetAiTagsParams = {
  startDate?: string;
  endDate?: string;
  type: TagType;
  channel: ChannelIntegrationValues;
};

export type GetAiTagsPayloads = {
  aiTags: {
    label: string;
    value: number;
  }[];
  averageAiSuggested: number | null;
  averageTotalGenerated: number | null;
  topTags: TopTag[];
  topTagsLabel: {
    color: string;
    key: string;
    type: CurveType;
  }[];
  totalAiGenerated: Overviews;
  totalGenerated: Overviews;
  totalUserDefinedGenerated: Overviews;
  type: TagType;
  endDate: string;
  startDate: string;
  startDateComparison: string;
  endDateComparison: string;
};

export type GetAiTagsUserDefinedParams = {
  startDate?: string;
  endDate?: string;
  type?: TagType;
  channel: ChannelIntegrationValues;
};

export type GetAiTagsUserDefinedPayloads = {
  average: number | null;
  currentlyActive: number | null;
  name: TagType;
  topTags: {
    date: string;
  } & TopTag[];
  total: Overviews;
  values: {
    label: string;
    value: number;
  }[];
  valuesBreakdown: {
    label: string;
    value: number;
  }[];
  topTagsLabel: {
    color: string;
    key: string;
    type: CurveType;
  }[];
};

export type TagType = 'all' | 'user-defined';

type TopTag = {
  [key: string]: number | string;
};

type Overviews = {
  before: number;
  description: 'UP' | 'DOWN';
  percentage: number;
  total: number;
};

export type TicketPerfomance = {
  id: Ticket['id'];
  tag: TicketTag;
  sources: Ticket['sources'];
  responseTime?: AnalyticAgents['responseTime'] | null;
  resolutionTime?: AnalyticAgents['resolutionTime'] | null;
  rating?: number | null;
  lastAgent?: {
    userId: number;
    rating: number;
    agentName: string;
    ratingReceived: boolean;
    status: 'Active' | 'Done';
  } | null;
  lastActivity: Date;
};

export type GetAnalyticAgentPerformanceParams = {
  startDate?: string;
  endDate?: string;
  channel?: ChannelIntegrationValues;
};
