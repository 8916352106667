import React, { ReactNode, useMemo, useState } from 'react';
import { ChevronLeft, ChevronUp } from 'react-feather';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { camelCase } from 'lodash-es';
import { twMerge as cx } from 'tailwind-merge';

import LinkOutIcon from '@/assets/icons/LinkOutIcon';
import { BBBTooltip } from '@/components/ui';
import colors from '@/constants/common/colors';

type EdgePlacementHorizontal = 'top' | 'bottom';
type EdgePlacementVertical = 'right' | 'left';

export type EdgeProps = {
  isHaveEdge?: boolean;
  edgePlacement?: `${EdgePlacementHorizontal}-${EdgePlacementVertical}`;
  edgeWidth?: number;
  edgeColor?: string;
};

export type IBBBCard = {
  id?: string;
  title?: string | React.ReactNode;
  titleClassName?: string;
  descClassName?: string;
  headerClassName?: string;
  containerTitleClassName?: string;
  desc?: string | React.ReactNode;
  rightTitleButton?: React.ReactNode;
  rightButton?: React.ReactNode;
  children?: React.ReactNode;
  onBack?: () => void;
  withCollapse?: boolean;
  titleLink?: string;
  titleLinkTooltip?: string;
  onClickTitle?: () => void;
  collapse?: boolean;
  footer?: ReactNode;
  footerClassName?: string;
  onFooterClick?: () => void;
  footerLink?: string;
} & EdgeProps &
  Omit<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    'title' | 'ref'
  >;

const oppositeDirections: {
  [k in
    | EdgePlacementHorizontal
    | EdgePlacementVertical]: k extends EdgePlacementHorizontal
    ? Exclude<EdgePlacementHorizontal, k>
    : Exclude<EdgePlacementVertical, k>;
} = {
  top: 'bottom',
  bottom: 'top',
  left: 'right',
  right: 'left',
};

const placement: (EdgePlacementHorizontal | EdgePlacementVertical)[] = [
  'top',
  'bottom',
  'left',
  'right',
];

const BBBCard = React.forwardRef<HTMLDivElement, IBBBCard>((props, ref) => {
  const {
    id,
    title,
    titleClassName,
    headerClassName,
    descClassName,
    containerTitleClassName,
    desc,
    rightTitleButton,
    rightButton,
    children,
    isHaveEdge,
    edgePlacement,
    edgeWidth = 12,
    edgeColor = '#ffd8c2',
    className,
    style,
    onBack,
    withCollapse,
    titleLink,
    titleLinkTooltip,
    onClickTitle,
    collapse = false,
    footer,
    footerClassName,
    onFooterClick,
    footerLink,
    ...rest
  } = props;
  const edges = useMemo<[EdgePlacementHorizontal, EdgePlacementVertical]>(
    () =>
      (edgePlacement?.split('-') as [
        EdgePlacementHorizontal,
        EdgePlacementVertical
      ]) || [],
    [edgePlacement]
  );

  const arrowShapeClasses = useMemo(
    () =>
      Object.fromEntries(
        placement.map((place) => [
          camelCase(`border-${place}`),
          `${edges.includes(place) ? `0` : `${edgeWidth}px`} solid ${
            oppositeDirections[edges[1]] === place
              ? `${edgeColor}`
              : 'transparent'
          }`,
        ])
      ),
    [edgeColor, edgeWidth, edges]
  );

  const arrowPositionClasses = {
    [edges[0]]: '0',
    [oppositeDirections[edges[1]]]: '100%',
  };

  const Footer =
    typeof footer !== 'undefined' ? (footerLink ? Link : 'div') : undefined;

  return (
    <div
      id={id}
      className={cx(
        `bg-white relative rounded-2xl  border border-grayColor2`,
        typeof footer !== 'undefined' ? 'p-0 md:p-0' : 'p-3 md:p-5',
        className
      )}
      style={{
        ...(isHaveEdge
          ? { [camelCase(`border-${edgePlacement}-radius`)]: 0 }
          : {}),
        ...style,
      }}
      ref={ref}
      {...rest}
    >
      {isHaveEdge && (
        <div
          className="absolute w-0 h-0"
          style={
            isHaveEdge
              ? {
                  ...arrowPositionClasses,
                  ...arrowShapeClasses,
                }
              : {}
          }
        />
      )}
      {typeof footer !== 'undefined' ? (
        <div className={cx('p-3 md:p-5')}>
          <Body {...props}>{children}</Body>
        </div>
      ) : (
        <Body {...props}>{children}</Body>
      )}
      {typeof footer !== 'undefined' && (
        //@ts-ignore
        <Footer
          className={cx('px-5 py-4 border-t block', footerClassName)}
          onClick={onFooterClick}
          {...(footerLink && {
            to: footerLink,
          })}
        >
          {footer}
        </Footer>
      )}
    </div>
  );
});

BBBCard.displayName = 'BBBCard';

export default BBBCard;

export const BBBCardWithMotion = motion(BBBCard);

function Body({
  title,
  collapse,
  desc,
  children,
  containerTitleClassName,
  onBack,
  titleClassName,
  onClickTitle,
  headerClassName,
  titleLink,
  titleLinkTooltip,
  rightButton,
  rightTitleButton,
  withCollapse,
  descClassName,
}: IBBBCard) {
  const [localCollapse, setLocalCollapse] = useState(collapse);

  return (
    <>
      {(title || desc) && (
        <div
          className={cx(
            children && (localCollapse ? 'mb-0' : 'mb-4'),
            containerTitleClassName
          )}
        >
          <div
            className={cx(`flex items-center align-middle`, headerClassName)}
          >
            <div className="grow">
              <div className="flex items-center gap-1 align-middle">
                {typeof onBack !== 'undefined' && (
                  <ChevronLeft onClick={onBack} />
                )}
                <div
                  className={cx(
                    'text-xl text-neutral-70 grow flex items-center gap-2',
                    titleClassName,
                    typeof onClickTitle !== 'undefined' &&
                      'hover:underline cursor-pointer'
                  )}
                  onClick={onClickTitle}
                >
                  {title}
                  {titleLink && (
                    <Link
                      to={titleLink}
                      target="_blank"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {titleLinkTooltip ? (
                        <BBBTooltip
                          show
                          content={titleLinkTooltip}
                          tooltipClassName="w-auto"
                        >
                          <LinkOutIcon size={14} color={colors.neutral['70']} />
                        </BBBTooltip>
                      ) : (
                        <LinkOutIcon size={14} color={colors.neutral['70']} />
                      )}
                    </Link>
                  )}
                </div>
                {rightTitleButton && (
                  <div className="ml-4">{rightTitleButton}</div>
                )}
              </div>
              {desc && (
                <div
                  className={cx('lg:mt-2 mt-1 text-neutral-40', descClassName)}
                >
                  {desc}
                </div>
              )}
            </div>
            {rightButton && <div className="ml-4">{rightButton}</div>}
            {withCollapse && (
              <ChevronUp
                onClick={() => setLocalCollapse((prev) => !prev)}
                className={cx(
                  'transition-transform cursor-pointer',
                  localCollapse && 'rotate-180'
                )}
              />
            )}
          </div>
        </div>
      )}
      {!!children && (
        <>
          {withCollapse ? (
            <motion.div
              animate={{
                height: localCollapse ? '0px' : 'auto',
                opacity: localCollapse ? 0 : 1,
              }}
              transition={{ type: 'tween' }}
            >
              {children}
            </motion.div>
          ) : (
            children
          )}
        </>
      )}
    </>
  );
}
