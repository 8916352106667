function CircleX({
  width = 19,
  height = 19,
}: {
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.875 11.875L7.125 7.125M11.875 7.125L7.125 11.875"
        stroke="#262627"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M9.49992 17.4163C13.8722 17.4163 17.4166 13.8719 17.4166 9.49967C17.4166 5.12742 13.8722 1.58301 9.49992 1.58301C5.12766 1.58301 1.58325 5.12742 1.58325 9.49967C1.58325 13.8719 5.12766 17.4163 9.49992 17.4163Z"
        stroke="#262627"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default CircleX;
