import { useMemo, useRef, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import useConfirmationModal from 'hooks/common/useConfirmationModal';
import useResponsive from 'hooks/common/useResponsive';
import useColumnDefault from './hooks/sorting/useColumn';
import useColumnMobile from './hooks/sorting/useColumnMobile';
import AutomaticUpdates from './AutomaticUpdates';

import { Model } from '@/api/services/bitAi/v2-new/model';
import FilterIcon2 from '@/assets/icons/FilterIcon2';
import PlusIcon from '@/assets/icons/PlusIcon';
import {
  BBBBottomSheet,
  BBBCard,
  BBBCheckbox,
  BBBSelect,
  BBBTooltip,
  SingleOnValueChangeCallback,
  SingleOnValueChangeParam,
} from '@/components/ui';
import BBBLimitAlert from '@/components/ui/BBBLimitAlert/BBBLimitAlert';
import UpgradeText from '@/components/ui/BBBLimitAlert/UpgradeText';
import BBBTableV2 from '@/components/ui/BBBTableV2/BBBTableV2';
import useSortingModels from '@/hooks/bitAi/models/useSortingModels';
import useBulkDeleteCollections from '@/hooks/bitAi/sorting/useBulkDeleteCollections';
import { useCollections } from '@/hooks/bitAi/v2/sorting';
import useOutsideAlerter from '@/hooks/common/useOutsideAlerterv2';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import useWithCRMPricing from '@/hooks/pricing/useWithCRMPricing';
import { cn } from '@/utils/styles';

export default function Sortings() {
  const filterRef = useRef<HTMLDivElement>(null);
  const isMobile = useResponsive('sm');

  const [filters, setFilters] = useState<{
    hideUnmanaged: boolean;
    model: Model | null;
  }>({
    hideUnmanaged: false,
    model: null,
  });
  const [showFilter, setShowFilter] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<string | undefined>();
  const [cursor, setCursor] = useState<{
    after?: string;
    before?: string;
  }>();

  const { data, isLoading } = useCollections({
    limit: pageSize,
    ...(search && { search }),
    ...cursor,
    hideUnmanaged: filters.hideUnmanaged,
    modelId: filters.model ? [filters.model.id] : [],
  });

  const collectionsData = data?.data;

  useOutsideAlerter(filterRef, () => setShowFilter(false));

  const toggleConfirmation = useConfirmationModal();
  const { mutate: deleteCollections } = useBulkDeleteCollections();

  const activeFiltersIntoNumber = Object.values(
    filters.model ? filters : { ...filters, model: undefined }
  ).filter(Boolean).length;

  const columnsDefault = useColumnDefault();
  const columnsMobile = useColumnMobile();

  return (
    <>
      <AutomaticUpdates className="mb-6" />
      <PricingAlert />
      <BBBTableV2
        data={collectionsData || []}
        headers={isMobile ? columnsMobile : columnsDefault}
        loadingBody={isLoading}
        headerClassName="p-3"
        isSearchable
        isPaginate
        pagination={{
          hasNext: data?.meta.hasNextPage,
          hasPrev: data?.meta.hasPreviousPage,
          onNext: () => setCursor({ after: data?.meta.endCursor }),
          onPrev: () => setCursor({ before: data?.meta.startCursor }),
        }}
        isAsyncSort
        onClickSortHeader={(key, sort) => {
          setSortBy(key);
          setSortDirection(sort);
        }}
        searchValue={search}
        searchPlaceholder="Search collection"
        isShowLimit
        limitValue={pageSize}
        onLimitChange={(val) => setPageSize(val!)}
        dataId="id"
        isFilterable
        renderFilterSection={() => (
          <>
            <div className="relative">
              <div
                className="relative flex lg:justify-center justify-between items-center px-4 cursor-pointer py-2 rounded-lg outline outline-[1px] outline-neutral-30"
                onClick={() => setShowFilter(!showFilter)}
              >
                {isMobile ? (
                  <>
                    <p className="text-primary-main">Filter</p>
                    <ChevronDown />
                  </>
                ) : (
                  <FilterIcon2 />
                )}
                {!!activeFiltersIntoNumber && (
                  <div className="absolute -top-3 -right-3 z-20 bg-secondary-main rounded-full w-6 flex items-center justify-center text-white">
                    {activeFiltersIntoNumber}
                  </div>
                )}
              </div>
              {!isMobile && showFilter && (
                <motion.div
                  ref={filterRef}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  transition={{ duration: 0.2 }}
                  className="min-w-[25rem] absolute top-6 right-0 z-10"
                >
                  <FilterModal
                    filters={filters}
                    onChangeFilters={setFilters}
                    className="w-full bg-white shadow-md"
                  />
                </motion.div>
              )}
            </div>
            {isMobile && (
              <>
                <FilterMobile
                  show={showFilter}
                  onHide={() => setShowFilter(false)}
                  filters={filters}
                  onChangeFilters={setFilters}
                />
                <p className="text-primary-main font-semibold">
                  Showing {collectionsData?.length} result
                </p>
                <div className="w-full h-[1px] bg-neutral-30"></div>
              </>
            )}
          </>
        )}
        withoutHeader={isMobile}
        onChangeSearch={(value) => {
          setSearch(value);
          setCursor(undefined);
        }}
        {...(!isMobile
          ? {
              isSelectable: true,
              onActionChange: (event, row) => {
                if (event === 'delete') {
                  toggleConfirmation({
                    title: 'Delete model?',
                    description: `Once deleted you're not able to recover it`,
                    onAccept: (hide) => {
                      deleteCollections({ collectionId: row });
                      hide();
                    },
                    deleteModal: true,
                    submitText: 'Yes',
                    cancelText: 'Cancel',
                  });
                }
              },
            }
          : {
              isSelectable: false,
            })}
        linkDestination={(row) =>
          row.sorting?.model
            ? `/bitai/sorting/${encodeURIComponent(row.id)}`
            : undefined
        }
        optionNumbers={[10, 25, 50]}
      />
    </>
  );
}

function PricingAlert() {
  const { data: pricingFeatureData, limit } = useWithCRMPricing(
    'BITAI',
    'managed_collections'
  );
  const { data: pricingData } = usePricingByApp('BITAI');

  if (!pricingData || !pricingFeatureData || pricingData.pricingName === 'pro1')
    return null;

  return (
    <BBBLimitAlert
      usage={pricingFeatureData.usage}
      appType="BITAI"
      module={pricingFeatureData.pricingFeature.label}
      limit={limit}
      currentPlan={{
        label: pricingData.pricingModule.label,
        name: pricingData.pricingName,
      }}
      className="mb-4 rounded-lg"
      customReachingTresholdLabel={
        <>
          {limit - pricingFeatureData.usage} active managed collections left{' '}
          <UpgradeText asModal appType="BITAI" /> for more.
        </>
      }
      customExceedTresholdLabel={
        <>
          On a free plan, you can only have {limit} managed collections.{' '}
          <UpgradeText asModal appType="BITAI" /> for more.
        </>
      }
      customTip="Collections are managed collections that are actively used in the sorting process."
      customTresholdFn={() => 0}
    />
  );
}

type FilterParams = {
  filters: {
    hideUnmanaged: boolean;
    model: Model | null;
  };
  onChangeFilters: (filters: {
    hideUnmanaged: boolean;
    model: Model | null;
  }) => void;
  className?: string;
};

const FilterModal = ({ className, ...props }: FilterParams) => {
  return (
    <BBBCard className={cn(`relative`, className)}>
      <Filters {...props} />
    </BBBCard>
  );
};

const FilterMobile = ({
  show,
  onHide,
  ...props
}: FilterParams & {
  show: boolean;
  onHide: () => void;
}) => {
  return (
    <BBBBottomSheet show={show} title={'Filter'} onClose={onHide}>
      <Filters {...props} />
    </BBBBottomSheet>
  );
};

const Filters = ({ filters, onChangeFilters }: FilterParams) => {
  return (
    <div className="flex flex-col gap-4">
      <p className="font-bold text-primary-main">Filter by</p>
      <BBBCheckbox
        label="Hide Unmanaged Collection"
        checked={filters.hideUnmanaged}
        onValueChange={(checked) =>
          onChangeFilters({ ...filters, hideUnmanaged: checked })
        }
      />
      <ModelOptions
        label="Model"
        value={filters.model}
        onValueChange={(value) =>
          onChangeFilters({ ...filters, model: value! })
        }
        disablePricingCheck
      />
      <p
        className="text-neutral-50 underline cursor-pointer"
        onClick={() =>
          onChangeFilters({
            hideUnmanaged: false,
            model: null,
          })
        }
      >
        Reset filter
      </p>
    </div>
  );
};

export function ModelOptions({
  label,
  value,
  onValueChange,
  disablePricingCheck,
  disabled,
}: {
  label?: string;
  value: SingleOnValueChangeParam<Model>;
  onValueChange: SingleOnValueChangeCallback<Model>;
  disablePricingCheck?: boolean;
  disabled?: boolean;
}) {
  const { data: pricingData } = usePricingByApp('BITAI');

  const pricingName = pricingData?.pricingName || 'free';

  const { data: _data } = useSortingModels();

  const options = useMemo(() => {
    const data = _data || [];

    const groupedData = data.map((item) => ({
      ...item,
      parentValue: item.type,
      ...(!disablePricingCheck && {
        disabled: item.type === 'ADVANCE' && pricingName === 'free',
      }),
    }));

    const customModelLength = data.filter(
      (item) => item.type === 'CUSTOM'
    ).length;

    if (!customModelLength) {
      groupedData.push({
        id: '-1',
        //@ts-ignore
        name: (
          <Link to={'/bitai/model'} className="flex items-center gap-2">
            <PlusIcon size={12} /> Add custom model
          </Link>
        ),
        parentValue: 'CUSTOM',
      });
    }

    const uniqueTypes = ['BASIC', 'CUSTOM', 'ADVANCE'];

    uniqueTypes.forEach((type) => {
      //@ts-ignore
      groupedData.push({
        id: type,
        name: type,
        ...(!disablePricingCheck && {
          disabled: type === 'ADVANCE' && pricingName === 'free',
        }),
      });
    });

    return groupedData;
  }, [_data, disablePricingCheck, pricingName]);

  const confirm = useConfirmationModal();

  const Select = (
    <BBBSelect
      options={options}
      optionLabel="name"
      optionValue="id"
      isGrouped
      //@ts-ignore
      optionGroupKey="parentValue"
      label={label}
      placeholder="Select model"
      value={value}
      //@ts-ignore
      onValueChange={(val) => {
        if (!disablePricingCheck && val?.type === 'ADVANCE') {
          confirm({
            title: 'Proceed to bitAI Pricing',
            description:
              "Are you sure want to visit the bitAI Pricing page? You'll see our latest plans and pricing details.",
            submitText: 'Yes',
            submitLink: '/pricing?tab=bitai',
            onAccept: (hide) => hide(),
          });
        } else {
          onValueChange(val);
        }
      }}
      onClick={(e) => {
        e.preventDefault();
      }}
      enableToggleOption
      //@ts-ignore
      optionDisabled="disabled"
      bypassDisableClickHandler
      isDisabled={disabled}
    />
  );

  if (disabled) {
    return (
      <BBBTooltip content="You've reached your Managed Collections limit" show>
        {Select}
      </BBBTooltip>
    );
  }

  return <>{Select}</>;
}
