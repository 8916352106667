import { Link } from '@/components/ui';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import useResponsive from '@/hooks/common/useResponsive';
import { AppType } from '@/types/systemLogin';
import { mapAppTypeToAppName } from '@/utils/app';

type Props = {
  appType: AppType;
  label?: string;
  asModal?: boolean;
};

function UpgradeText({ appType, label = 'Upgrade', asModal }: Props) {
  const isMobile = useResponsive('sm');

  const confirm = useConfirmationModal();

  return asModal ? (
    <span
      onClick={() =>
        confirm({
          title: `Procees to ${mapAppTypeToAppName[appType]} Pricing`,
          description: `Are you sure want to visit the ${mapAppTypeToAppName[appType]} Pricing page? You'll see our latest plans and pricing details.`,
          onAccept: (hide) => {
            hide();
          },
          submitText: 'Yes',
          submitLink: `/pricing?tab=${appType.toLowerCase()}`,
        })
      }
      className="cursor-pointer"
    >
      <span className="text-info-main underline font-bold">{label}</span>
    </span>
  ) : isMobile ? (
    <span className="font-bold">{label}</span>
  ) : (
    <Link to={`/pricing?tab=${appType.toLowerCase()}`}>
      <span className="text-info-main underline font-bold">{label}</span>
    </Link>
  );
}

export default UpgradeText;
