import { useEffect, useState } from 'react';
import { createId } from '@paralleldrive/cuid2';
import imageCompression, { Options } from 'browser-image-compression';
import api from 'config/api';
import {
  addMedia,
  initUploadMedia,
  updateMediaCompressionProgress,
  updateMediaUploadProgress,
} from 'stores/bitCRM';
import { v4 } from 'uuid';
import { sanitizeFileName } from 'utils/common/file';
import { useActiveCompany } from '../rtk/selector';
import { useAppDispatch } from '../rtk/store';

const compressionOptions: Options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

export type UploadOption = { original?: boolean; fileId?: string };

export const useUploadFile = () => {
  const activeCompany = useActiveCompany();
  const [loading, setLoading] = useState<Record<string, boolean>>();
  const [progress, setProgress] = useState<Record<string, number>>();
  const [compressionProgress, setCompressionProgress] =
    useState<Record<string, number>>();

  async function uploadFile(
    file: File,
    options?: UploadOption
  ): Promise<string> {
    const fileId = options?.fileId || createId();

    setLoading((prev) => ({ ...prev, [fileId]: true }));

    const formData = new FormData();

    let _file = new File([file], sanitizeFileName(file.name), {
      type: file.type,
    });

    if (file.type.includes('image') && !options?.original) {
      const compressedFile = await imageCompression(file, {
        ...compressionOptions,
        onProgress: (progress) => {
          setCompressionProgress((prev) => ({ ...prev, [fileId]: progress }));
        },
      });

      _file = new File([compressedFile], sanitizeFileName(file.name), {
        type: file.type,
      });
    }

    formData.append('image', _file);

    try {
      const {
        data: { urlData },
      } = await api.systemLogin.post<{ urlData: string }>(
        `/media/${activeCompany}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const total = progressEvent.total;
            const current = progressEvent.loaded;
            const percentCompleted = Math.floor((current / total) * 100);

            setProgress((prev) => ({ ...prev, [fileId]: percentCompleted }));
          },
        }
      );

      return urlData;
    } finally {
      setLoading((prev) => ({ ...prev, [fileId]: false }));

      setProgress((prev) => {
        if (prev) {
          delete prev[fileId];

          return prev;
        }

        return;
      });
    }
  }

  return {
    uploadFile,
    loading,
    progress,
    compressionProgress,
  };
};

// scoped only for livechat attachment modal use case
export const useUploadFiles = () => {
  const { uploadFile, progress, compressionProgress } = useUploadFile();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (progress) {
      dispatch(updateMediaUploadProgress(progress));
    }
  }, [dispatch, progress]);

  useEffect(() => {
    if (compressionProgress) {
      dispatch(updateMediaCompressionProgress(compressionProgress));
    }
  }, [dispatch, compressionProgress]);

  return async (
    files: File[],
    options?: {
      defaultSelectAdded?: boolean;
      retainSelectedMedia?: boolean;
    }
  ) => {
    const _files = Array.from(files);

    const fileWithIds = _files.map(() => v4());

    dispatch(initUploadMedia(fileWithIds));

    const fileData = await Promise.all(
      _files.map(async (_file, index) => {
        const id = fileWithIds[index];

        const value = await uploadFile(_file, { fileId: id });

        return {
          value,
          id,
          type: _file.type,
          meta: _file,
          loading: false,
        };
      })
    );

    dispatch(
      addMedia({
        mediaUrl: fileData,
        ...options,
      })
    );
  };
};
