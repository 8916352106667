import { ChevronLeft, ChevronRight } from 'react-feather';
import { twMerge as cx } from 'tailwind-merge';

type BBBPaginationNumber = number;

export type BBBPaginationProps =
  | {
      limit: number;
      maxShown?: number;
      total: number;
      page: BBBPaginationNumber;
      onChange?: (val: BBBPaginationNumber) => void;
    }
  | {
      onPrev?: () => void;
      onNext?: () => void;
      hasNext?: boolean;
      hasPrev?: boolean;
    };

function BBBSelectSize(props: BBBPaginationProps) {
  const isNumberPagination = 'total' in props;

  const handleChangePage = (page: BBBPaginationNumber) =>
    isNumberPagination && props.onChange?.(page);

  const currentPage = isNumberPagination && (props.page || 0);
  const lastPage =
    (isNumberPagination && Math.ceil(props.total / props.limit)) || 1;

  const pageList = (current: number, last: number) => {
    const arrayPage = [];
    for (let i = current; i <= last; i += 1) {
      arrayPage.push(i);
    }
    return arrayPage;
  };

  const getRange = () => {
    if (isNumberPagination) {
      let initial = 1;

      let incremental = 1;
      let prev = (props.page as number) || 0;
      let last = (props.page as number) || 0;

      while (
        initial <
        (Math.ceil(props.total / props.limit) < (props.maxShown ?? 0)
          ? Math.ceil(props.total / props.limit)
          : props.maxShown ?? 0)
      ) {
        if (incremental % 2 !== 0) {
          if (prev > 1) {
            prev -= 1;
            initial += 1;
          }
        } else if (last < lastPage) {
          last += 1;
          initial += 1;
        }
        incremental += 1;
      }
      return {
        prev,
        last,
      };
    }
  };

  const handleClickPrev = () =>
    isNumberPagination
      ? currentPage !== 1 && handleChangePage((currentPage as number) - 1)
      : props.onPrev?.();

  const handleClickNext = () =>
    isNumberPagination
      ? handleChangePage((currentPage as number) + 1)
      : props.onNext?.();

  if (isNumberPagination && props.total <= 0) return null;

  return (
    <div className="flex gap-4 items-center">
      {!isNumberPagination && !props.hasPrev ? null : (
        <button
          className={cx(
            `text-primary-main`,
            currentPage === 1 ? 'opacity-25 pointer-events-none' : ''
          )}
          onClick={handleClickPrev}
          disabled={
            isNumberPagination ? !currentPage || currentPage <= 0 : false
          }
        >
          <ChevronLeft width={24} height={24} />
        </button>
      )}
      {isNumberPagination &&
        pageList(getRange()!.prev, getRange()!.last).map((p) => (
          <div
            className={cx(
              'px-4 py-2',
              currentPage === p
                ? 'bg-neutral-20 rounded-full pointer-events-none'
                : 'cursor-pointer'
            )}
            key={p}
            onClick={() => handleChangePage(p)}
          >
            {p}
          </div>
        ))}
      {!isNumberPagination && !props.hasNext ? null : (
        <button
          onClick={handleClickNext}
          className={cx(
            `text-primary-main`,
            currentPage === lastPage ? 'opacity-25 pointer-events-none' : ''
          )}
          disabled={isNumberPagination ? currentPage === lastPage : false}
        >
          <ChevronRight width={24} height={24} />
        </button>
      )}
    </div>
  );
}

export default BBBSelectSize;
