import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/config/api';
import { useAppSelector } from '@/hooks/rtk/store';
import { toast } from '@/utils/common/toast';

const usePauseCampaign = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const queryClient = useQueryClient();
  return useMutation(
    (payload: string) =>
      api.bitCRM.v2.post(`/campaign-new/action/stop`, {
        companyId: activeCompany,
        id: payload,
      }),
    {
      onSuccess: () => {
        toast.success('Your campaign has been paused');
        queryClient.invalidateQueries(['bitcrm-campaigns']);
      },
    }
  );
};

export default usePauseCampaign;
