import { Heart, Share2, Star } from 'react-feather';
import { twMerge as cx } from 'tailwind-merge';

import { BBBButton } from '@/components/ui';
import { env } from '@/config/env';
import useBrandingQuery from '@/hooks/bitApp/design/useBranding';

const previewProduct = {
  image: `${env.REACT_APP_CDN_URL}/bitbybit/static/bitApp/product-live-preview.png`,
  title: 'Leather Sling Bag',
  stars: 5,
  reviews: 10,
  price: 30.88,
  variants: ['Jet black', 'Navy Blue', 'Maroon'],
  description: `
Elevate your everyday style with our luxurious leather bag, handcrafted for timeless elegance. Available in classic black for a touch of power, or versatile navy blue for modern sophistication, this bag seamlessly complements any outfit. Supple, genuine leather offers a smooth feel and ensures lasting durability, making it a perfect companion for every adventure.`,
} as const;

function ProductPage() {
  const { data } = useBrandingQuery();

  return (
    <div className="flex flex-col h-full">
      <img
        src={previewProduct?.image}
        className="w-full h-80 object-cover"
        alt="preview-product-image"
        loading="lazy"
      />
      <div className="grow relative flex flex-col gap-4 py-2.5 px-4">
        <div className="flex flex-col gap-2.5 text-primary-main">
          <p className="font-bold text-lg">{previewProduct?.title}</p>
          <div className="flex gap-2.5 items-center">
            <div className="flex items-center gap-0.5">
              {Array.from({ length: previewProduct?.stars }).map((_, i) => (
                <Star fill="#000000" stroke="transparent" size={12} key={i} />
              ))}
            </div>
            <p className="text-xs">{previewProduct?.reviews} Reviews</p>
          </div>
          <p className="text-xl font-bold">
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(previewProduct?.price)}
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-primary-main text-xs">Variants</p>
          <div className="flex gap-2.5 items-center">
            {previewProduct?.variants.map((variant, idx) => (
              <div
                key={variant}
                className={cx(
                  'border px-3 py-2 rounded-lg text-xs',
                  idx === 0
                    ? 'bg-primary-main text-white border-transparent'
                    : 'bg-transparent text-primary-main border-primary-main'
                )}
              >
                {variant}
              </div>
            ))}
          </div>
        </div>
        <div className="w-full h-[1px]" />
        <p className="text-xs text-primary-main font-bold">Description</p>
        <p className="text-xs text-primary-main text-justify">
          {previewProduct.description}
        </p>
      </div>
      <div className=" bg-white shadow-[0px_-2px_4px_0px_#0000001A] py-1 px-2 gap-2 md:py-4 md:px-6 flex items-center md:gap-5">
        <Share2 className="w-4 h-4 md:w-8 md:h-8" />
        <Heart className="w-4 h-4 md:w-8 md:h-8" />
        <BBBButton
          text="Add to cart"
          className="w-full whitespace-normal text-[8px] md:text-base pointer-events-none"
          backgroundColor={data?.primaryColor || undefined}
        />
      </div>
    </div>
  );
}

export default ProductPage;
