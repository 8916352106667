import { createSlice } from '@reduxjs/toolkit';

import { Company, Profile, User } from '@/types/systemLogin';
import {
  UserCompanyWithAssociation,
  UserWithAssociation,
} from '@/types/systemLogin/association';
import { removeUserSession } from '@/utils/auth';
import { _localStorage } from '@/utils/common/localStorage';

export type UserStore = Pick<
  User,
  'email' | 'id' | 'accent' | 'userPhoneNumber' | 'isVerified'
> & {
  profile: Pick<Profile, 'firstName' | 'lastName' | 'profilePicture'> | null;
};

type AuthStore = {
  loadingChangeCompany: boolean;
  company: null | Company;
  activeCompany?: number;
  user: null | UserStore;
  accessToken: null | string;
  userCompany: null | UserCompanyWithAssociation;
};

const initialState: AuthStore = {
  loadingChangeCompany: false,
  activeCompany: undefined,
  user: null,
  accessToken: _localStorage.getItem('accessToken'),
  company: null,
  userCompany: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoadingChangeCompany: (state, { payload }: { payload: boolean }) => {
      state.loadingChangeCompany = payload;
    },
    setActiveCompany: (state, { payload }: { payload: number | undefined }) => {
      state.activeCompany = payload;
    },
    setCompany: (state, { payload }: { payload: Company | null }) => {
      state.company = payload;
    },
    setUserCompany: (
      state,
      { payload }: { payload: UserCompanyWithAssociation | null }
    ) => {
      state.userCompany = payload;
    },
    updateCompany: (state, { payload }: { payload: Company }) => {
      state.userCompany = state.userCompany
        ? { ...state.userCompany, company: payload }
        : null;
      state.company = payload;
    },
    updateUser: (state, { payload }: { payload: UserWithAssociation }) => {
      state.userCompany = state.userCompany
        ? { ...state.userCompany, user: payload }
        : null;
      state.user = payload;
    },
    setUser: (state, { payload }: { payload: UserStore }) => {
      state.user = payload;
    },
    setAccessToken: (state, { payload }: { payload: string }) => {
      state.accessToken = payload;
    },
    logout: (state) => {
      state.accessToken = null;
      state.activeCompany = undefined;
      state.user = null;
      removeUserSession();
    },
  },
});

export const {
  setActiveCompany,
  setCompany,
  setUser,
  logout,
  setAccessToken,
  setLoadingChangeCompany,
  setUserCompany,
  updateCompany,
  updateUser,
} = authSlice.actions;

export default authSlice.reducer;
