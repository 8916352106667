import { X } from 'react-feather';
import { twMerge as cx } from 'tailwind-merge';

export type BBBTagProps = {
  text?: string | React.ReactNode;
  textClassName?: string;
  onDelete?: (e: React.MouseEvent<SVGElement, MouseEvent>) => void;
  icon?: React.ReactNode;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

function BBBTag({
  text,
  onDelete,
  icon,
  textClassName,
  className,
  onClick,
}: BBBTagProps) {
  return (
    <div
      className={cx(
        'bg-neutral-20 rounded-lg px-2 py-1 flex items-center gap-1  text-neutral-60',
        className
      )}
      onClick={onClick}
    >
      {icon}
      <div
        className={cx('text-primary-main line-clamp-1', textClassName)}
        onClick={onClick}
      >
        {text}
      </div>
      {onDelete && (
        <>
          <X size={14} className="cursor-pointer" onClick={onDelete} />
        </>
      )}
    </div>
  );
}

export default BBBTag;
