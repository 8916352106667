import { Control, Controller } from 'react-hook-form';
import { AISettingsForm } from './AiSettings';

import { BBBCard, BBBPrimarySwitch } from '@/components/ui';

export default function CoPilot({
  control,
}: {
  control: Control<AISettingsForm>;
}) {
  return (
    <BBBCard
      title="Co-pilot"
      desc="Let AI help your agent to answer your customers with reply suggestion"
      rightButton={
        <Controller
          control={control}
          name="copilot"
          render={({ field }) => (
            <BBBPrimarySwitch checked={field.value} onChange={field.onChange} />
          )}
        />
      }
      className="mb-cardBottom"
    />
  );
}
