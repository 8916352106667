import { useEffect } from 'react';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import { integrationsMeta, KnownIntegration } from 'constants/integrations';
import useStore from '../../../store';
import { TriggerData } from '../../../types';
import { Source } from './ActionModal';

import { BBBModal, BBBSelect } from '@/components/ui';
import { BBBTextInput } from '@/components/ui';
import { companyWithJubelioIntegration } from '@/constants/bitCRM/automation-new';
import { useJubelioChannels } from '@/hooks/bitCRM/automation/automation-new';
import { useActiveCompany } from '@/hooks/rtk/selector';
import {
  optionsPageLists,
  optionsPageListsHash,
} from '@/pages/BitChat/WaWidget/components/HideWidget';
import ShopifyCollectionListsOptions, {
  ShopifyCollectionForm,
} from '@/pages/BitCRM/components/Shopify/CollectionListOptions';
import ShopifyProductListsOptions, {
  ShopifyProductForm,
} from '@/pages/BitCRM/components/Shopify/ProductListOptions';

type TriggerForm = {
  source: TriggerData['source'] | null;
  trigger: { label: string; event: string } | null;
  jubelioChannel: { label: string; value: number } | null;
  visitedPage: { label: string; value: string } | null;
  visitedCollection: ShopifyCollectionForm | null;
  visitedProduct: ShopifyProductForm | null;
  visitedXProducts: string | null;
  visitedXCollections: string | null;
};

const defaultValues: DefaultValues<TriggerForm> = {
  source: null,
  trigger: null,
  jubelioChannel: null,
  visitedPage: null,
  visitedCollection: null,
  visitedXProducts: '',
  visitedXCollections: '',
  visitedProduct: null,
};

const shopifyTriggerOptions = [
  { label: 'Order Fulfilled', event: 'orders/fulfilled' },
  { label: 'Order Created', event: 'orders/create' },
  { label: 'Order Paid', event: 'orders/paid' },
  { label: 'Order Created (unpaid)', event: 'orders/unpaid' },
  { label: 'Abandoned Checkout', event: 'carts/create' },
];

const jubelioTriggerOptions = [
  {
    label: 'Order Created (Unpaid)',
    event: 'order/create',
  },
  {
    label: 'Order Paid',
    event: 'order/paid',
  },
  {
    label: 'Order Shipped',
    event: 'order/fulfilled',
  },
  {
    label: 'Order Completed',
    event: 'order/completed',
  },
];

const webActivityOptions = [
  { label: 'Visited a page', event: 'visit-page' },
  { label: 'Visited collections', event: 'visit-collections' },
  { label: 'Visited product', event: 'visit-product' },
  { label: 'Add item to cart', event: 'add-to-cart' },
  // { label: 'Add item to wishlist', event: 'add-to-wishlist' },
  { label: 'Visited X collections', event: 'visited-x-collections' },
  { label: 'Visited X product', event: 'visited-x-products' },
];

const bitloginOptions = [
  { label: 'Login', event: 'login' },
  { label: 'Sign up', event: 'signup' },
];

const stampedLoyaltyOptions = [
  { label: 'Earn rewards', event: 'loyalty-rewards-earn' },
  { label: 'Reward used', event: 'loyalty-rewards-used' },
];

export const triggerOptions: Partial<
  Record<KnownIntegration, { label: string; event: string }[]>
> = {
  shopify: shopifyTriggerOptions,
  jubelio: jubelioTriggerOptions,
  web_activity: webActivityOptions,
  bitlogin: bitloginOptions,
  stamped_loyalty: stampedLoyaltyOptions,
};

export default function TriggerModal({
  onSave,
  onClose,
  nodeId,
}: {
  onSave: (val: TriggerData) => void;
  onClose: () => void;
  nodeId?: string;
}) {
  const activeCompany = useActiveCompany();

  const { handleSubmit, control, reset, watch } = useForm<TriggerForm>({
    defaultValues,
  });

  const shownIntegrations: KnownIntegration[] = [
    'shopify',
    'bitlogin',
    ...(companyWithJubelioIntegration.includes(activeCompany)
      ? (['jubelio'] as const)
      : []),
    'stamped_loyalty',
    'web_activity',
  ];

  const nodeData = useStore(
    (s) =>
      s.nodes.find((node) => node.id === nodeId)?.data as
        | TriggerData
        | undefined
  );
  const onChangeTriggerModalState = useStore(
    (s) => s.onChangeTriggerModalState
  );

  useEffect(() => {
    if (nodeData) {
      reset({
        source: nodeData.source,
        trigger:
          !!nodeData.triggerName && !!nodeData.triggerValue
            ? { label: nodeData.triggerName, event: nodeData.triggerValue }
            : undefined,
        jubelioChannel:
          !!nodeData.jubelioChannelLabel && !!nodeData.jubelioChannelValue
            ? {
                label: nodeData.jubelioChannelLabel,
                value: nodeData.jubelioChannelValue,
              }
            : undefined,
        visitedPage: nodeData.visitedPage
          ? optionsPageListsHash[nodeData.visitedPage]
          : null,
        visitedCollection: nodeData.visitedCollection,
        visitedProduct: nodeData.visitedProduct,
        visitedXCollections: nodeData.visitedXCollections
          ? nodeData.visitedXCollections.toString()
          : '',
        visitedXProducts: nodeData.visitedXProducts
          ? nodeData.visitedXProducts.toString()
          : '',
      });
    }
  }, [nodeData, reset]);

  const source = watch('source');
  const trigger = watch('trigger');

  const { data: jubelioChannelsData } = useJubelioChannels({
    enabled: source === 'jubelio',
  });

  return (
    <BBBModal
      show
      title="Trigger"
      footer
      submitText="Save"
      cancelText="Discard"
      onHide={() => onChangeTriggerModalState(null)}
      handleSave={() => {
        handleSubmit(
          ({
            source,
            trigger,
            jubelioChannel,
            visitedPage,
            visitedCollection,
            visitedProduct,
            visitedXCollections,
            visitedXProducts,
          }) => {
            onSave({
              source: source!,
              triggerName: trigger?.label || null,
              triggerValue: trigger?.event || null,
              jubelioChannelLabel: jubelioChannel?.label || null,
              jubelioChannelValue: jubelioChannel?.value || null,
              visitedPage: visitedPage?.value || null,
              visitedCollection: visitedCollection
                ? {
                    id: visitedCollection.id,
                    title: visitedCollection.title,
                    image: visitedCollection.image,
                  }
                : null,
              visitedProduct: visitedProduct
                ? {
                    id: visitedProduct.id,
                    title: visitedProduct.title,
                    featuredImage: visitedProduct.featuredImage,
                  }
                : null,
              visitedXCollections: visitedXCollections
                ? parseInt(visitedXCollections, 10)
                : null,
              visitedXProducts: visitedXProducts
                ? parseInt(visitedXProducts, 10)
                : null,
            });
            onClose();
          }
        )();
      }}
      bodyClassName="px-2"
    >
      {!source ? (
        <Controller
          control={control}
          name="source"
          render={({ field: { onChange } }) => (
            <>
              {shownIntegrations.map((integration) => {
                const meta = integrationsMeta[integration];

                const Icon = meta.icon;

                return (
                  <Source
                    key={integration}
                    onClick={() => {
                      onChange(integration);
                    }}
                    Icon={Icon}
                    title={meta.title}
                  />
                );
              })}
            </>
          )}
        />
      ) : (
        <>
          <Source
            title={integrationsMeta[source].title}
            Icon={integrationsMeta[source].icon}
            withChange
            onChange={() => {
              reset(defaultValues);
            }}
            className="mb-5"
          />
          <div className="mx-3">
            {source === 'jubelio' && (
              <Controller
                control={control}
                name="jubelioChannel"
                render={({ field }) => (
                  <BBBSelect
                    options={jubelioChannelsData}
                    value={field.value}
                    onValueChange={field.onChange}
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Select store channel"
                    label="Jubelio store channel"
                    containerClassName="mb-5"
                  />
                )}
              />
            )}
            <Controller
              control={control}
              name="trigger"
              render={({ field }) => {
                return (
                  <BBBSelect
                    options={triggerOptions[source]}
                    optionLabel="label"
                    optionValue="event"
                    value={field.value}
                    onValueChange={field.onChange}
                    label="Trigger"
                    placeholder="Select trigger"
                    containerClassName="mb-5"
                  />
                );
              }}
            />
            {trigger?.event === 'visit-page' && (
              <Controller
                control={control}
                name="visitedPage"
                render={({ field }) => {
                  return (
                    <BBBSelect
                      label="Has visited"
                      placeholder="Select page"
                      options={optionsPageLists}
                      optionLabel="label"
                      optionValue="value"
                      value={field.value}
                      onValueChange={field.onChange}
                    />
                  );
                }}
              />
            )}
            {trigger?.event === 'visit-collections' && (
              <Controller
                control={control}
                name="visitedCollection"
                render={({ field }) => (
                  <ShopifyCollectionListsOptions
                    label="Collections"
                    value={field.value}
                    onChange={field.onChange}
                    className="grow"
                  />
                )}
              />
            )}
            {trigger?.event === 'visit-product' && (
              <Controller
                control={control}
                name="visitedProduct"
                render={({ field }) => (
                  <ShopifyProductListsOptions
                    label="Products"
                    value={field.value}
                    onChange={field.onChange}
                    className="grow"
                  />
                )}
              />
            )}
            {trigger?.event === 'visited-x-collections' && (
              <BBBTextInput
                isHookForm
                control={control}
                controlName="visitedXCollections"
                label="Number of visited collections"
                placeholder="Input a number"
              />
            )}
            {trigger?.event === 'visited-x-products' && (
              <BBBTextInput
                isHookForm
                control={control}
                controlName="visitedXProducts"
                label="Number of visited product"
                placeholder="Input a number"
              />
            )}
          </div>
        </>
      )}
    </BBBModal>
  );
}
