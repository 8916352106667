import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import { useActiveCompany } from '@/hooks/rtk/selector';
import { toast } from '@/utils/common/toast';

const useUpdateOos = () => {
  const activeCompany = useActiveCompany();

  const client = useQueryClient();

  return useMutation(
    ({
      data: { collectionId, oos },
    }: {
      data: { collectionId: string; oos: boolean };
    }) =>
      services.bitAi.v2New.sorting.updateOos(
        activeCompany,
        encodeURIComponent(collectionId),
        {
          oos,
        }
      ),
    {
      onSuccess: () => {
        toast.success(
          'Sorting request acknowledged. Please allow a few minutes for the changes to be reflected live on your store.'
        );
        client.invalidateQueries([
          `bitai-sorting-v2-collections`,
          activeCompany,
        ]);
      },
    }
  );
};

export default useUpdateOos;
