import { useMemo, useState } from 'react';
import { Info, Link2, ShoppingBag, Type, Youtube } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router';
import { motion } from 'framer-motion';
import { startCase } from 'lodash-es';
import queryString from 'query-string';
import { twMerge as cx } from 'tailwind-merge';

import LayoutIcon from '@/assets/icons/LayoutIcon';
import LinkOutIcon from '@/assets/icons/LinkOutIcon';
import WhatsAppSectionIcon from '@/assets/icons/WhatsAppSectionIcon';
import {
  BBBButton,
  BBBCard,
  BBBOverviewBox,
  BBBPieChart,
  BBBSpinner,
  BBBTooltip,
} from '@/components/ui';
import BBBComposedChartMultiTab from '@/components/ui/Analytics/BBBComposedChart/BBBComposedChartMultiTab';
import BBBTableV2 from '@/components/ui/BBBTableV2/BBBTableV2';
import { HeadersTypes } from '@/components/ui/BBBTableV2/BBBTableV2.type';
import {
  previewChartClicksViewsCTR,
  previewCTR,
  previewDailyViewsData,
  previewEngagementRate,
  previewLinkClickData,
  previewPageViewData,
  previewShareData,
  previewSubsribersInformation,
  previewTopLinks,
  previewTopLocations,
  previewTrafficSource,
} from '@/constants/analytics/bitLink';
import colors from '@/constants/common/colors';
import {
  useClicksViewsCTR,
  useCTR,
  useDownloadSubscribers,
  useEngagementRate,
  useLinkClicks,
  usePageShared,
  usePageView,
  useSubscribers,
  useTopLinks,
  useTopLocations,
  useTrafficSource,
} from '@/hooks/bitLink/analytics';
import useLinkById from '@/hooks/bitLink/links/useLinkById';
import useResponsive from '@/hooks/common/useResponsive';
import { Linklists } from '@/types/bitLink/v2';
import { Customer } from '@/types/customers';
import { formatDate2 } from '@/utils/common/date';

function BitLinkAnalytics({
  className,
  startDate,
  endDate,
  isPreview,
  loading,
}: {
  className?: string;
  startDate?: string;
  endDate?: string;
  isPreview?: boolean;
  loading?: boolean;
}) {
  const isMobile = useResponsive('sm');

  const {
    data: pageViewData,
    isInitialLoading: loadingPageView,
    isError: errorPageView,
  } = usePageView(
    {
      startDate,
      endDate,
    },
    {
      enabled: !loading && !isPreview,
    }
  );

  const {
    data: pageSharedData,
    isInitialLoading: loadingPageShared,
    isError: errorPageShared,
  } = usePageShared(
    {
      startDate,
      endDate,
    },
    {
      enabled: !loading && !isPreview,
    }
  );

  const {
    data: engagementRateData,
    isInitialLoading: loadingBounceRate,
    isError: errorBounceRate,
  } = useEngagementRate(
    {
      startDate,
      endDate,
    },
    {
      enabled: !loading && !isPreview,
    }
  );

  const {
    data: CTRData,
    isInitialLoading: loadingCTR,
    isError: errorCTR,
  } = useCTR(
    {
      startDate,
      endDate,
    },
    {
      enabled: !loading && !isPreview,
    }
  );

  const {
    data: linkClicksData,
    isInitialLoading: loadingLinkClicks,
    isError: errorLinkClicks,
  } = useLinkClicks(
    {
      startDate,
      endDate,
    },
    {
      enabled: !loading && !isPreview,
    }
  );

  const {
    data: clickViewsCTRData,
    isInitialLoading: loadingClicksViewsCTR,
    isError: errorClicksViewsCTR,
  } = useClicksViewsCTR(
    {
      startDate,
      endDate,
    },
    {
      enabled: !loading && !isPreview,
    }
  );

  const {
    data: subscribersData,
    isInitialLoading: loadingSubscribers,
    isError: errorSubscribers,
  } = useSubscribers(
    {
      startDate,
      endDate,
    },
    {
      enabled: !loading && !isPreview,
    }
  );

  const {
    data: trafficSourceData,
    isInitialLoading: loadingTrafficSource,
    isError: errorTrafficSource,
  } = useTrafficSource(
    {
      startDate,
      endDate,
    },
    {
      enabled: !loading && !isPreview,
    }
  );

  const {
    data: topLocationsData,
    isInitialLoading: loadingTopLocations,
    isError: errorTopLocations,
  } = useTopLocations(
    {
      startDate,
      endDate,
    },
    {
      enabled: !loading && !isPreview,
    }
  );

  const {
    data: topLinksData,
    isInitialLoading: loadingTopLinks,
    isError: errorTopLinks,
  } = useTopLinks(
    {
      startDate,
      endDate,
    },
    {
      enabled: !loading && !isPreview,
    }
  );

  const reOrderTrafficSource = trafficSourceData?.sort(
    (a, b) => b.value - a.value
  );
  const hasTrafficSource = reOrderTrafficSource?.some((item) => item.value > 0);

  const _queryParams = queryString.stringify({ startDate, endDate });

  const queryParams = _queryParams ? '?' + _queryParams : '';

  const isLoadingData =
    loading ||
    loadingPageView ||
    loadingLinkClicks ||
    loadingPageShared ||
    loadingBounceRate ||
    loadingSubscribers ||
    loadingTrafficSource ||
    loadingClicksViewsCTR ||
    loadingTopLocations ||
    loadingTopLinks ||
    loadingCTR;

  const isError =
    errorPageView ||
    errorLinkClicks ||
    errorPageShared ||
    errorBounceRate ||
    errorSubscribers ||
    errorTrafficSource ||
    errorClicksViewsCTR ||
    errorTopLocations ||
    errorTopLinks ||
    errorCTR;

  return (
    <div className="grid grid-cols-6 lg:grid-cols-12 gap-3">
      {isLoadingData ? (
        <div className={cx('col-span-6 lg:col-span-12', className)}>
          <Loader />
        </div>
      ) : isError ? null : (
        <>
          <div className={cx('col-span-2 lg:col-span-3', className)}>
            <BBBOverviewBox
              tooltip="Your bitLink page views"
              title="Page views"
              value={
                isPreview
                  ? previewPageViewData.current
                  : pageViewData?.current || 0
              }
            />
          </div>
          <div className={cx('col-span-2 lg:col-span-3', className)}>
            <BBBOverviewBox
              tooltip="Your average bitLink page daily views"
              title="Daily views"
              value={
                isPreview
                  ? previewDailyViewsData.current
                  : pageViewData?.average
                  ? pageViewData?.average?.toFixed()
                  : 0
              }
            />
          </div>
          <div className={cx('col-span-2 lg:col-span-3', className)}>
            <BBBOverviewBox
              tooltip="How many your bitLink page shared by your customers"
              title="Share"
              value={
                isPreview
                  ? previewShareData.current
                  : pageSharedData?.current || 0
              }
            />
          </div>
          <div className={cx('col-span-3', className)}>
            <BBBOverviewBox
              tooltip="Tracks the amount of visitors that subscribed to your link"
              title="Subscribers"
              value={
                isPreview
                  ? previewSubsribersInformation?.subscribers.length
                  : subscribersData?.meta?.total || 0
              }
            />
          </div>
          <div className={cx('col-span-3 lg:col-span-4', className)}>
            <BBBOverviewBox
              title="Clicks"
              value={
                isPreview
                  ? previewLinkClickData.current
                  : linkClicksData?.current || 0
              }
              tooltip="Total click of links on your bitLink page"
            />
          </div>
          <div className={cx('col-span-3 lg:col-span-4', className)}>
            <BBBOverviewBox
              title="CTR"
              tooltip="Percentage of your customers who open your bitLink page and click any link"
              tooltipClassName="w-full"
              value={
                isPreview ? previewCTR.current : CTRData ? `${CTRData}%` : '0%'
              }
            />
          </div>
          <div className={cx('col-span-3 lg:col-span-4', className)}>
            <BBBOverviewBox
              title="Engagement Rate"
              tooltip="Percentage of viewers who took action"
              tooltipClassName="w-full"
              value={
                isPreview
                  ? previewEngagementRate.current
                  : engagementRateData
                  ? `${engagementRateData}%`
                  : '0%'
              }
            />
          </div>
          {!isMobile && (
            <div className={cx('col-span-6', className)}>
              {hasTrafficSource ? (
                <BBBPieChart
                  title="Social media clicks"
                  tooltip="Tracks social media icon clicks in your bitLink"
                  data={isPreview ? previewTrafficSource : reOrderTrafficSource}
                  dataKey="value"
                  renderLegend={(data, colors) => (
                    <div className="flex flex-col gap-2">
                      {data?.map((entry, index) => (
                        <div
                          key={`item-${index}`}
                          className="flex items-center gap-2"
                        >
                          <div
                            className="w-5 h-5 rounded-md"
                            style={{
                              backgroundColor: colors[index % colors.length],
                            }}
                          />
                          <p className="text-primary-main">
                            {/* @ts-ignore */}
                            {entry.value} ({entry?.payload?.percentage}%)
                          </p>
                        </div>
                      ))}
                    </div>
                  )}
                  tooltipProps={{
                    isCustomTooltip: true,
                    customContent: (payload) => (
                      <>
                        {payload?.[0]?.name} -{' '}
                        {`${payload?.[0]?.payload?.value} (${payload?.[0]?.payload?.percentage}%)`}
                      </>
                    ),
                  }}
                />
              ) : (
                <BBBCard
                  title={
                    <div className="flex gap-1 items-center w-fit">
                      <p className="text-base font-normal whitespace-nowrap">
                        Traffic source
                      </p>
                      <BBBTooltip
                        show
                        content="Tracks where your bitLink visitors are coming from"
                      >
                        <Info size={14} color="#9E9E9E" />
                      </BBBTooltip>
                    </div>
                  }
                >
                  <Empty />
                </BBBCard>
              )}
            </div>
          )}
          <div className={cx('col-span-6', className)}>
            <BBBComposedChartMultiTab
              lineDataKeyBar={(activeTab) =>
                activeTab.value === 'clicks-views' ? 'views' : 'views'
              }
              xAxisDataKey={() => (isPreview ? 'label' : 'date')}
              options={[
                {
                  label: 'Clicks & Views',
                  value: 'clicks-views',
                },
                {
                  label: 'Clicks, Views, & CTR',
                  value: 'clicks-views-ctr',
                },
              ]}
              data={() =>
                isPreview ? previewChartClicksViewsCTR : clickViewsCTRData
              }
              isHasGraph
              lineDataKeyGraph={(activeTab) =>
                activeTab.value === 'clicks-views' ? 'clicks' : 'CTR'
              }
              isHasAdditionalBar={(activeTab) =>
                activeTab.value === 'clicks-views-ctr' ? true : false
              }
              lineDataKeyAdditionalBar={(activeTab) =>
                activeTab.value === 'clicks-views-ctr' ? 'clicks' : 'views'
              }
              tooltipProps={{
                isCustomTooltip: true,
                customContent: (payload) => (
                  <div className="flex flex-col gap-1">
                    {payload?.map((item, idx) =>
                      item.name === 'CTR' ? (
                        <p key={idx} className="text-white">
                          {item.payload.CTR}% CTR
                        </p>
                      ) : (
                        <p key={idx} className="text-white">
                          {item.value} {item.name}
                        </p>
                      )
                    )}
                  </div>
                ),
              }}
            />
          </div>
          <div className={cx('col-span-6', className)}>
            <TopLinks
              date={queryParams}
              isPreview={isPreview}
              data={topLinksData}
            />
          </div>
          <div className={cx('col-span-6', className)}>
            <TopLocations
              data={isPreview ? previewTopLocations : topLocationsData}
            />
          </div>
          {!isMobile && (
            <div className={cx('col-span-6', className)}>
              <Subscribers
                startDate={startDate}
                endDate={endDate}
                isPreview={isPreview}
                loading={loading}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

function TopLinks({
  data,
  date,
  isPreview,
}: {
  data: ReturnType<typeof useTopLinks>['data'];
  date: string;
  isPreview?: boolean;
}) {
  return (
    <BBBCard
      title={
        <div className="flex gap-1 items-center w-fit">
          <p className="text-base font-normal whitespace-nowrap">
            Top 5 performing links
          </p>
          <BBBTooltip
            show
            content="Tracks your top 5 active links with the most clicks. Inactive links will not be tracked"
          >
            <Info size={14} color="#9E9E9E" />
          </BBBTooltip>
        </div>
      }
    >
      {isPreview ? (
        <div className="flex flex-col gap-2.5">
          {previewTopLinks?.map((item) => (
            <PreviewTopLinks
              key={item.id}
              linkId={item.id}
              total={item.total}
              date={date}
              previewData={item}
              isPreview={isPreview}
            />
          ))}
        </div>
      ) : !data?.length ? (
        <Empty />
      ) : (
        <div className="flex flex-col gap-2.5">
          {data?.map((item) => (
            <PreviewTopLinks
              key={item.link_id}
              linkId={item.link_id}
              total={item.total}
              date={date}
              isPreview={isPreview}
            />
          ))}
        </div>
      )}
    </BBBCard>
  );
}

function PreviewTopLinks({
  linkId,
  total,
  date,
  isPreview,
  previewData,
}: {
  previewData?: typeof previewTopLinks[0];
  isPreview?: boolean;
  linkId: string;
  total: number;
  date: string;
}) {
  const history = useHistory();
  const [hoverShop, setHoverShop] = useState<string[]>([]);
  const { data, isInitialLoading } = useLinkById(Number(linkId), {
    enabled: !isPreview,
  });

  const hasAnalyticLogs =
    (previewData?.type === 'shop' || data?.type === 'shop') &&
    hoverShop.includes(linkId);

  if (!data?.active && !isPreview) {
    return null;
  }

  if (isInitialLoading) {
    return (
      <div className="w-full flex items-center justify-between border-b border-neutral-30 last:border-transparent gap-4">
        <div className="self-center">
          <Skeleton width={30} height={30} />
        </div>
        <div className="grow w-full flex flex-col gap-1">
          <Skeleton width={20} height={5} />
          <Skeleton width={80} height={5} />
        </div>
        <Skeleton width={20} height={10} />
      </div>
    );
  }

  return (
    <div
      className="flex items-center pb-2.5 border-b border-neutral-30 last:border-transparent gap-4"
      onMouseEnter={() => setHoverShop((prev) => [...prev, linkId])}
      onMouseLeave={() =>
        setHoverShop((prev) => prev.filter((item) => item !== linkId))
      }
      onClick={() =>
        hasAnalyticLogs && history.push(`/analytics/bitlink/${linkId}${date}`)
      }
    >
      <div className="self-center flex-none">
        <Logo
          type={isPreview ? previewData?.type : data?.type}
          media={isPreview ? previewData?.media : data?.media}
        />
      </div>
      <div className="grow flex flex-col gap-1">
        <p className="uppercase text-secondary-main text-sm">
          {isPreview ? previewData?.type : data?.type}
        </p>
        {previewData?.type === 'shop' || data?.type === 'shop' ? (
          <BBBTooltip
            show={
              data?.textPrimary && data?.textPrimary?.length > 50 ? true : false
            }
            content={isPreview ? previewData?.title : data?.textPrimary}
          >
            <p className="text-primary-main">
              {isPreview
                ? previewData?.title
                : data?.textPrimary && data?.textPrimary?.length > 50
                ? data?.textPrimary?.slice(0, 50) + '...'
                : data?.textPrimary}
            </p>
          </BBBTooltip>
        ) : data?.type === 'image' ? (
          <BBBTooltip
            show={
              (data?.textPrimary ? data?.textPrimary : data?.textSecondary) &&
              ((data?.textPrimary
                ? data?.textPrimary?.length
                : data?.textSecondary?.length) ?? 0) > 50
                ? true
                : false
            }
            content={isPreview ? previewData?.title : data?.textSecondary}
            className="cursor-default"
          >
            <div
              className={cx(
                'text-primary-main',
                hasAnalyticLogs && 'underline'
              )}
            >
              <Text
                data={data}
                isPreview={isPreview}
                previewData={previewData}
              />
            </div>
          </BBBTooltip>
        ) : data?.type === 'youtube' ? (
          <BBBTooltip
            show={
              data?.textSecondary
                ? data?.textSecondary?.length > 50
                  ? true
                  : false
                : data?.textPrimary
                ? data?.textPrimary?.length > 50
                  ? true
                  : false
                : false
            }
            content={
              isPreview
                ? previewData?.title
                : data?.textSecondary
                ? data?.textSecondary
                : data?.textPrimary
            }
            className="cursor-default"
          >
            <p
              className={cx(
                'text-primary-main',
                hasAnalyticLogs && 'underline'
              )}
            >
              {isPreview
                ? previewData?.title
                : data?.textSecondary
                ? data?.textSecondary?.length > 50
                  ? data?.textSecondary?.slice(0, 50) + '...'
                  : data?.textSecondary
                : data?.textPrimary
                ? data?.textPrimary?.length > 50
                  ? data?.textPrimary?.slice(0, 50) + '...'
                  : data?.textPrimary
                : '-'}
            </p>
          </BBBTooltip>
        ) : (
          <BBBTooltip
            show={
              data?.textPrimary && data?.textPrimary?.length > 50 ? true : false
            }
            content={isPreview ? previewData?.title : data?.textPrimary}
            className="cursor-default"
          >
            <div className="text-primary-main">
              <Text
                data={data}
                isPreview={isPreview}
                previewData={previewData}
              />
            </div>
          </BBBTooltip>
        )}
      </div>
      <div
        className={cx(
          'self-center',
          hasAnalyticLogs ? 'flex gap-1 items-center' : 'inline'
        )}
      >
        <p
          className={cx(
            'text-primary-main font-medium',
            hasAnalyticLogs && 'underline'
          )}
        >
          {isPreview ? previewData?.total : total}
        </p>
        {hasAnalyticLogs && (
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3 }}
          >
            <LinkOutIcon size={11} color={colors.neutral['40']} />
          </motion.div>
        )}
      </div>
    </div>
  );
}

function Text({
  data,
  isPreview,
  previewData,
}: {
  data: Linklists | undefined;
  isPreview?: boolean;
  previewData?: { title?: string; media?: string };
}) {
  const text = data?.textPrimary || data?.textSecondary || '';

  return (
    <span className="line-clamp-1 break-all">
      {isPreview ? previewData?.title : text}
    </span>
  );
}

function Logo({
  type,
  media,
}: {
  type: string | null | undefined;
  media: string | null | undefined;
}) {
  return (
    <>
      {type === 'image' && (
        <div>
          <img
            alt=""
            src={media || undefined}
            className="w-[1.875rem] h-[1.875rem] !max-w-none object-cover rounded-sm"
            loading="lazy"
          />
        </div>
      )}
      {type === 'shop' && (
        <ShoppingBag className="w-[1.875rem] h-[1.875rem] p-1" />
      )}
      {type === 'divider' && (
        <LayoutIcon className="w-[1.875rem] h-[1.875rem] p-1" />
      )}
      {type === 'youtube' && (
        <Youtube className="w-[1.875rem] h-[1.875rem] p-1" />
      )}
      {type === 'link' &&
        (media ? (
          <img
            src={media || undefined}
            alt="link"
            className="w-[1.875rem] h-[1.875rem] !max-w-none object-cover rounded-sm"
            loading="lazy"
          />
        ) : (
          <Link2 className="p-1" />
        ))}
      {type === 'text' && <Type className="w-[1.875rem] h-[1.875rem] p-1" />}
      {type === 'whatsapp' && (
        <WhatsAppSectionIcon className="w-[1.875rem] h-[1.875rem] p-1" />
      )}
    </>
  );
}

function TopLocations({
  data,
}: {
  data: ReturnType<typeof useTopLocations>['data'];
}) {
  return (
    <BBBCard
      title={
        <div className="flex gap-1 items-center w-fit">
          <p className="text-base font-normal whitespace-nowrap">
            Top 5 locations
          </p>
          <BBBTooltip
            show
            content="Understand where your bitLink visitors are located around the globe"
          >
            <Info size={14} color="#9E9E9E" />
          </BBBTooltip>
        </div>
      }
    >
      <div className="flex flex-col gap-4">
        {data?.locations?.length === 0 ? (
          <div className="h-full flex flex-col gap-1 items-center justify-center">
            <div className="text-primary-main font-medium">
              No sessions in this date range
            </div>
            <div className="text-neutral-50 text-sm">
              Try selecting a different date range
            </div>
          </div>
        ) : (
          <>
            {data?.locations?.map((item) => (
              <div
                key={item.country}
                className="w-full h-7 flex justify-between items-center"
              >
                <div className="flex items-center gap-2.5">
                  <img
                    alt="United States"
                    src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${item.iso.toUpperCase()}.svg`}
                    className="w-[21px] border border-neutral-60"
                  />
                  <p className="text-primary-main">{startCase(item.country)}</p>
                </div>
                <p className="text-primary-main font-medium">{item.total}</p>
              </div>
            ))}
            {!!data?.others && (
              <div className="w-full h-7 flex justify-between items-center">
                <p className="text-primary-main">Others</p>
                <p className="text-primary-main font-medium">{data.others}</p>
              </div>
            )}
            {!!data?.unknown && (
              <div className="w-full h-7 flex justify-between items-center">
                <p className="text-primary-main">Unknown</p>
                <p className="text-primary-main font-medium">{data.unknown}</p>
              </div>
            )}
          </>
        )}
      </div>
    </BBBCard>
  );
}

function Subscribers({
  startDate,
  endDate,
  isPreview,
  loading,
}: {
  startDate?: string;
  endDate?: string;
  isPreview?: boolean;
  loading?: boolean;
}) {
  const [cursor, setCursor] = useState<{
    after?: string;
    before?: string;
  }>();
  const [pageSize, setPageSize] = useState(25);
  const [search, setSearch] = useState('');

  const { data: subscribersData, isInitialLoading: loadingSubscribers } =
    useSubscribers(
      {
        startDate,
        endDate,
        search,
        limit: pageSize,
        ...cursor,
      },
      {
        enabled: !loading && !isPreview,
      }
    );

  const { mutate, isLoading } = useDownloadSubscribers();

  const columns = useMemo<HeadersTypes<Partial<Customer>>>(() => {
    return [
      {
        accesor: 'firstName',
        isSortable: true,
        renderHeader: () => 'Full Name',
        render: (row) => (
          <p className="text-primary-main">
            {row.firstName} {row.lastName}
          </p>
        ),
      },
      {
        accesor: 'companyId',
        isSortable: true,
        renderHeader: () => 'Subscribed by',
        render: (row) => (
          <p className="text-primary-main">
            {row.email && row.phoneNumber
              ? 'Email, WhatsApp'
              : row.email
              ? 'Email'
              : row.phoneNumber
              ? 'WhatsApp'
              : '-'}
          </p>
        ),
      },
      {
        accesor: 'email',
        isSortable: true,
        renderHeader: () => 'Email',
        render: (row) => (
          <p className="text-primary-main">{row.email || '-'}</p>
        ),
      },
      {
        accesor: 'phoneNumber',
        isSortable: true,
        renderHeader: () => 'WhatsApp',
        render: (row) => (
          <p className="text-primary-main">{row.phoneNumber || '-'}</p>
        ),
      },
      {
        accesor: 'createdAt',
        isSortable: true,
        renderHeader: () => 'Time subscribed',
        render: (row) => (
          <p className="text-primary-main">
            {formatDate2(row.createdAt || '-')}
          </p>
        ),
      },
    ];
  }, []);

  return (
    <div className="flex flex-col gap-4 mt-4">
      <p className="text-primary-main text-xl">Subscribers information</p>

      <BBBTableV2
        data={
          isPreview
            ? previewSubsribersInformation.subscribers.filter((item) =>
                search
                  ? (item.firstName || item.lastName)
                      ?.toLowerCase()
                      .includes(search.toLowerCase())
                  : item
              )
            : subscribersData?.subscribers
        }
        dataId="id"
        headers={columns}
        isSearchable
        loadingBody={loadingSubscribers}
        searchPlaceholder="Search"
        searchValue={search}
        onChangeSearch={setSearch}
        isFilterable
        renderFilterSection={() => (
          <BBBButton
            text="Download CSV"
            variant="secondary"
            iconPosition="right"
            onClick={() =>
              mutate({
                startDate,
                endDate,
                search,
                limit: pageSize,
              })
            }
            loadingState={isLoading}
            size="sm"
          />
        )}
        isPaginate
        pagination={{
          hasNext: subscribersData?.meta.hasNextPage,
          hasPrev: subscribersData?.meta.hasPreviousPage,
          onNext: () => setCursor({ after: subscribersData?.meta.endCursor }),
          onPrev: () =>
            setCursor({ before: subscribersData?.meta.startCursor }),
        }}
        isShowLimit
        limitValue={pageSize}
        onLimitChange={(page) => {
          setPageSize(page!);
          setCursor(undefined);
        }}
      />
    </div>
  );
}

function Loader({ className }: { className?: string }) {
  return (
    <div
      className={cx(
        'flex flex-col items-center justify-center gap-1',
        className
      )}
    >
      <BBBSpinner height={18} />
      <p className="text-neutral-60">Loading more data</p>
    </div>
  );
}

function Empty() {
  return (
    <div className="min-h-[20rem] h-full flex flex-col gap-1 items-center justify-center">
      <div className="text-primary-main font-medium">
        No sessions in this date range
      </div>
      <div className="text-neutral-50 text-sm">
        Try selecting a different date range
      </div>
    </div>
  );
}

export default BitLinkAnalytics;
