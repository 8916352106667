import React, { useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { twMerge as cx } from 'tailwind-merge';

import BBBThumbnail from '@/assets/icons/BBBThumbnail';
import { BBBButton } from '@/components/ui';
import { UploadOption } from '@/hooks/common/upload';
import { useUploadFile } from '@/hooks/common/upload';
import useCropImageModal from '@/hooks/common/useCropImageModal';
import { CropImageModal } from '@/stores/common';
import { toast } from '@/utils/common/toast';

export type BBBImageUploadTypes = {
  label?: string | React.ReactNode;
  info?: string | React.ReactNode;
  className?: string;

  containerClassName?: string;
  accept?: string;
  imageUrl?: string | null;
  onChangeImage?: (val: string | null | undefined) => void;
  customPlaceholder?: React.ReactNode;
  imageClassName?: string;
  imageStyles?: React.CSSProperties;
  crop?: Omit<CropImageModal, 'handleDownload' | 'fileName' | 'file'>;
  errors?: string;
  buttonText?: string;
  shape?: 'square' | 'circle' | 'block';
  width?: number | string;
  height?: number | string;
  thumbnailClassName?: string;
  requiredLabel?: boolean;
  acceptedDimensions?: {
    width: number;
    height: number;
  };
  uploadOptions?: UploadOption;
};

function BBBImageUpload({
  label,
  info,
  className,
  containerClassName,
  imageUrl,
  shape = 'circle',
  height = shape === 'circle' ? '3.5rem' : shape === 'block' ? '5rem' : '4rem',
  width = shape === 'circle'
    ? '3.5rem'
    : shape === 'block'
    ? '9.375rem'
    : '4rem',
  thumbnailClassName,
  accept = 'image/*',
  customPlaceholder,
  imageStyles,
  imageClassName,
  errors,
  onChangeImage = () => ({}),
  crop,
  buttonText = 'Upload image',
  requiredLabel,
  acceptedDimensions,
  uploadOptions,
}: BBBImageUploadTypes) {
  const { uploadFile } = useUploadFile();

  const toggleCropImageModal = useCropImageModal();

  const [loadingUpload, setLoadingUpload] = useState(false);

  const handleUploadManualImg = async (file: File) => {
    setLoadingUpload(true);

    try {
      const data = await uploadFile(file, uploadOptions);

      onChangeImage(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingUpload(false);
    }
  };

  const onFileChange = async (e: File[]) => {
    const file = e?.[0];
    if (file) {
      if (crop) {
        toggleCropImageModal({
          handleDownload: onChangeImage,
          file,
          ...crop,
          ...uploadOptions,
        });
      } else {
        if (acceptedDimensions) {
          const reader = new FileReader();
          const image = new Image();

          reader.addEventListener('load', () => {
            const imgSrc = reader.result as string;
            image.src = imgSrc;
            image.onload = async function validateImage() {
              if (
                !(
                  image.width === acceptedDimensions.width &&
                  image.height === acceptedDimensions.height
                )
              ) {
                toast.error(
                  `Invalid dimensions provided. Expected: ${acceptedDimensions.width} x ${acceptedDimensions.height}`
                );
                return;
              }

              handleUploadManualImg(file);
            };
          });

          reader.readAsDataURL(file);
        } else {
          handleUploadManualImg(file);
        }
      }
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    onDrop: onFileChange,
    accept: Object.fromEntries(
      accept.split(',').map((data) => [data, []])
    ) as Accept,
  });

  return (
    <>
      <div {...getRootProps()} className={containerClassName}>
        <input {...getInputProps()} />
        {label && (
          <div className="mb-2">
            {label}
            {requiredLabel && <span className="text-danger-main">*</span>}
          </div>
        )}
        <div className={cx('flex flex-row items-center gap-6', className)}>
          {loadingUpload ? (
            <div
              style={{ width, height }}
              className={cx(
                'bg-neutral-30 rounded-lg',
                shape === 'circle' ? 'rounded-full' : 'rounded-lg'
              )}
            />
          ) : (
            <>
              {imageUrl ? (
                <img
                  src={imageUrl}
                  className={cx(
                    `object-cover`,
                    shape === 'circle' ? 'rounded-full' : 'rounded-lg',
                    imageClassName
                  )}
                  style={{
                    width,
                    height,
                    ...imageStyles,
                  }}
                  alt="upload-comp"
                />
              ) : customPlaceholder ? (
                <div className="cursor-pointer" onClick={open}>
                  {customPlaceholder}
                </div>
              ) : (
                <BBBThumbnail
                  onClick={open}
                  width={width}
                  height={height}
                  style={imageStyles}
                  hasBorder={shape === 'circle'}
                  shape={shape}
                  className={cx('cursor-pointer', thumbnailClassName)}
                />
              )}
            </>
          )}
          {info}
          {!imageUrl ? (
            <BBBButton
              text={buttonText}
              disabled={loadingUpload}
              onClick={open}
            />
          ) : (
            <div className="flex gap-4">
              <BBBButton
                variant="secondary"
                text="Remove"
                onClick={() => onChangeImage(null)}
                disabled={loadingUpload || !imageUrl}
              />
              <BBBButton
                text="Replace"
                disabled={loadingUpload}
                onClick={open}
              />
            </div>
          )}
        </div>
        {errors && <div className="text-red-500">{errors}</div>}
      </div>
    </>
  );
}

export default BBBImageUpload;
