import { useMutation, useQueryClient } from '@tanstack/react-query';

import services from '@/api/services';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { addItem } from '@/stores/bitApp';
import { ModalState } from '@/stores/bitApp/types';

const useAddSection = () => {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const client = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation(
    ({
      draggableId,
      destination,
    }: {
      draggableId: keyof ModalState;
      destination: number;
    }) =>
      services.bitApp.section.addSection(activeCompany!, {
        active: true,
        position: destination,
        type: draggableId,
      }),
    {
      onSuccess: ({ data: { data: sectionData } }, { draggableId }) => {
        client.invalidateQueries(['sections', activeCompany]);
        dispatch(
          addItem({
            type: draggableId,
            id: sectionData.id,
          })
        );
      },
    }
  );
};

export default useAddSection;
